import React from 'react';
import { Card, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  section: {
    '&:not(:last-child)': {
      marginBottom: 30,
    },
  },
  text: {
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  textListItem: {
    fontSize: 16,
    '&:not(:last-child)': {
      marginBottom: 5,
    },
  },
});

const AccessibilityDeclarationView = () => {
  const classes = useStyles();

  return (
    <Card elevation={3} style={{ padding: 16 }}>
      <Box component="section" className={classes.section}>
        <Typography variant="h4" component="h1" className={classes.text}>
          Deklaracja dostępności serwisu CST2021
        </Typography>
        <Typography component="p" className={classes.text}>
          Deklaracja dostępności serwisu CST2021 (Centralnego Systemu Teleinformatycznego 2021).
        </Typography>
        <Typography component="p" className={classes.text}>
          Ministerstwo Funduszy i Polityki Regionalnej (dalej Ministerstwo) zapewnia dostępność serwisu CST2021 zgodnie
          z przepisami ustawy z 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych
          podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do serwisu&nbsp;
          <a href="https://cst2021.gov.pl" target="_blank" rel="noopener noreferrer nofollow">
            CST2021
          </a>
          &nbsp;należącego do Ministerstwa Funduszy i Polityki Regionalnej.
        </Typography>
        <Typography component="p" className={classes.text}>
          Data publikacji strony internetowej: 2021-10-01
        </Typography>
        <Typography component="p" className={classes.text}>
          Data ostatniego przeglądu deklaracji: 2024-03-28
        </Typography>
        <Typography component="p" className={classes.text}>
          Data ostatniej dużej aktualizacji: 2024-12-12
        </Typography>
        <Typography component="p" className={classes.text}>
          Serwis CST2021 jest zgodny z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i
          aplikacji mobilnych podmiotów publicznych.
        </Typography>
        <Typography component="p" className={classes.text}>
          Oświadczenie sporządzono dnia 2021-10-01. Deklarację sporządzono na podstawie samooceny przeprowadzonej przez
          podmiot publiczny.
        </Typography>
        <Typography component="p" className={classes.text}>
          Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.
        </Typography>
      </Box>

      <Box component="section" className={classes.section}>
        <Typography variant="h5" component="h2" className={classes.text}>
          Informacje zwrotne i dane kontaktowe
        </Typography>
        <Typography component="p" className={classes.text}>
          W przypadku problemów z dostępnością serwisu CST2021 prosimy o kontakt z Departamentem Koordynacji Wdrażania
          Funduszy Unii Europejskiej, e-mail:&nbsp;
          <a href="mailto:SekretariatDKF@mfipr.gov.pl">SekretariatDKF@mfipr.gov.pl</a>, tel. +48 222737900. W ten sposób
          można również składać wnioski o udostępnienie informacji niedostępnej i składać żądania zapewnienia
          dostępności.
        </Typography>
        <Typography component="p" className={classes.text}>
          Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji
          mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego
          sposobu dostępu, np. przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez
          audiodeskrypcji, itp.
        </Typography>
        <Typography component="p" className={classes.text}>
          Żądanie powinno zawierać:
        </Typography>
        <ul>
          <li className={classes.textListItem}>dane osoby zgłaszającej żądanie,</li>
          <li className={classes.textListItem}>
            wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz
          </li>
          <li className={classes.textListItem}>sposób kontaktu.</li>
        </ul>
        <Typography component="p" className={classes.text}>
          Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna
          także określić dogodny dla niej sposób przedstawienia tej informacji.
        </Typography>
        <Typography component="p" className={classes.text}>
          Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, Ministerstwo zaproponuje alternatywny sposób dostępu
          do informacji.
        </Typography>
        <Typography component="p" className={classes.text}>
          W przypadku, gdy Ministerstwo odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu
          dostępu do informacji, wnoszący żądanie może złożyć skargę w sprawie zapewniana dostępności cyfrowej strony
          internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej.
        </Typography>
        <Typography component="p" className={classes.text}>
          Po wyczerpaniu wszystkich możliwości skargę można przesłać także do&nbsp;
          <a
            href="https://bip.brpo.gov.pl/pl/content/zlozenie-wniosku-do-rzecznika-praw-obywatelskich"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Rzecznika Praw Obywatelskich
          </a>
          .
        </Typography>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography variant="h5" component="h2" className={classes.text}>
          Dostępność architektoniczna
        </Typography>
        <Typography component="p" className={classes.text}>
          Budynek Ministerstwa Funduszy i Polityki Regionalnej znajdujący się w Warszawie przy ul. Wspólnej 2/4 został
          przystosowany do użytkowania przez osoby z niepełnosprawnościami poprzez:
        </Typography>
        <ul>
          <li className={classes.textListItem}>stosowne oznakowanie,</li>
          <li className={classes.textListItem}>likwidację barier architektonicznych,</li>
          <li className={classes.textListItem}>likwidację barier komunikacyjnych,</li>
          <li className={classes.textListItem}>
            umożliwienie dostępu do pomieszczeń urzędu dla osób z niepełnosprawnością,
          </li>
          <li className={classes.textListItem}>zastosowanie ergonomicznych rozwiązań.</li>
        </ul>
        <Typography component="p">W szczególności zrealizowano następujące działania:</Typography>
        <ol>
          <li className={classes.textListItem}>
            Do obsługi osób z niepełnosprawnością oznakowano drzwi wejściowe do budynku Ministerstwa od ul. Wspólnej.
          </li>
          <li className={classes.textListItem}>
            Przed budynkiem Ministerstwa oznakowano kolorem niebieskim 4 miejsca parkingowe dla osób z
            niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            W celu obsługi osób z niepełnosprawnością wykonano nową ladę w oknie podawczym kancelarii z odcinkowym
            obniżeniem blatu.
          </li>
          <li className={classes.textListItem}>
            Zastosowano kontrastowe elementy architektury poprzez wyróżnienie recepcji oraz dziennika podawczego.
          </li>
          <li className={classes.textListItem}>
            Oznaczono (oklejono) paskami antypoślizgowymi posadzkę dojścia do recepcji oraz do toalety dla osób z
            niepełnosprawnościami.
          </li>
          <li className={classes.textListItem}>Wykonano wyróżnienie początku i końca wszystkich biegów schodowych.</li>
          <li className={classes.textListItem}>
            Wykonano podjazd wraz z poręczami w celu umożliwienia dostępu do budynku Ministerstwa osób z
            niepełnosprawnością poruszających się na wózkach inwalidzkich.
          </li>
          <li className={classes.textListItem}>
            Przy wejściu numer II od ul. Wspólnej zainstalowano drzwi otwierane automatycznie umożliwiające bezkolizyjne
            wejście do budynku.
          </li>
          <li className={classes.textListItem}>
            Zakupiono schodołaz umożliwiający osobom z niepełnosprawnością korzystanie z dużej sali konferencyjnej nr
            5114B na V piętrze.
          </li>
          <li className={classes.textListItem}>
            Zamontowano platformę dla osób z niepełnosprawnością na V piętrze budynku.
          </li>
          <li className={classes.textListItem}>
            Dla osób z niepełnosprawnościami została przystosowana winda D1 z siedziskiem.
          </li>
          <li className={classes.textListItem}>
            Wykonano w windzie D1 dodatkową sygnalizację dźwiękową z pełnymi komunikatami głosowymi określającymi
            kierunek jazdy windy, otwarcie/zamknięcie drzwi, kondygnację, na której zatrzymała się winda.
          </li>
          <li className={classes.textListItem}>
            Dla obsługi osób z niepełnosprawnością zamontowano trzy platformy umożliwiające pokonanie schodów.
          </li>
          <li className={classes.textListItem}>
            Na sali konferencyjnej im. Grażyny Gęsickiej oraz w recepcji obsługującej klientów zainstalowano w podłodze
            pętle indukcyjne umożliwiające wzmocnienie dźwięku dla osób z aparatami słuchowymi.
          </li>
          <li className={classes.textListItem}>
            Na sali konferencyjnej im. Grażyny Gęsickiej zakupiono rampę przenośną umożliwiającą prelegentowi z
            niepełnosprawnością dostanie się na podest z mównicą.
          </li>
          <li className={classes.textListItem}>
            Zakupiono dodatkową, mobilną pętlę indukcyjną wzmacniającą dźwięk na salach konferencyjnych.
          </li>
          <li className={classes.textListItem}>
            W ramach modernizacji budynku Ministerstwa dostosowano 8 łazienek na potrzeby osób z niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            Wykonano instalacje alarmowe we wszystkich toaletach dla osób z niepełnosprawnością.
          </li>
          <li className={classes.textListItem}>
            W łazience na IV piętrze zainstalowano nasadkę toaletową z podłokietnikami umożliwiającą osobom z
            niepełnosprawnością korzystanie z WC.
          </li>
          <li className={classes.textListItem}>
            Wykonano dodatkowe poręcze na klatkach schodowych oraz trzy poręcze na zewnątrz budynku zgodnie z przepisami
            pożarowymi.
          </li>
          <li className={classes.textListItem}>
            Dokupiono fotele do ewakuacji osób z niepełnosprawnościami oraz zemdlonych (w sumie mamy 25 foteli).
          </li>
        </ol>
      </Box>
      <Box component="section" className={classes.section}>
        <Typography variant="h5" component="h2" className={classes.text}>
          Dostępność dla osób niesłyszących lub słabosłyszących
        </Typography>
        <Typography component="p" className={classes.text}>
          Aby skutecznie komunikować się z naszym urzędem, osoby niesłyszące lub słabo słyszące mogą:
        </Typography>
        <ul>
          <li className={classes.textListItem}>
            skorzystać z usługi&nbsp;
            <a
              href="https://tlumacz.migam.org/ministerstwo_funduszy_i_polityki_regionalnej"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Tłumacz Migam
            </a>
            , która umożliwia połączenia wideo z tłumaczem języka migowego z poziomu przeglądarki internetowej,
            aplikacji mobilnej oraz dowolnego urządzenia z kamerą. Usługa ta jest dostępna w dni robocze w godzinach
            pracy urzędu 8:15-16:15,
          </li>
          <li className={classes.textListItem}>
            napisać pismo/złożyć wniosek na adres: Ministerstwo Funduszy i Polityki Regionalnej, ul. Wspólna 2/4, 00-926
            Warszawa,
          </li>
          <li className={classes.textListItem}>
            wysłać e-maila na adres: <a href="mailto:kancelaria@mfipr.gov.pl">kancelaria@mfipr.gov.pl</a>,
          </li>
          <li className={classes.textListItem}>
            skontaktować się telefonicznie przy pomocy osoby trzeciej na numer telefonu: 22 250 01 30,
          </li>
          <li className={classes.textListItem}>
            skontaktować się osobiście zgłaszając się w siedzibie urzędu w godzinach urzędowania 8:15-16:15,
          </li>
          <li className={classes.textListItem}>wysłać wiadomość SMS/MMS na numer telefonu: 662 252 227,</li>
          <li className={classes.textListItem}>
            wysłać faks na numer telefonu: 22 273 87 32. W treści faksu prosimy o podanie nazwy komórki organizacyjnej
            (biura, departamentu), której sprawa dotyczy.
          </li>
        </ul>
        <Typography component="p" className={classes.text}>
          W kontakcie z naszym urzędem osoba słabo słysząca może także skorzystać z systemu pętli indukcyjnej na
          recepcji głównej i w sali konferencyjnej im. Grażyny Gęsickiej.
        </Typography>
        <Typography component="p" className={classes.text}>
          W Ministerstwie wyznaczono&nbsp;
          <a
            href="https://www.gov.pl/web/fundusze-regiony/koordynator-dostepnosci"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            koordynatora ds. dostępności
          </a>
          .
        </Typography>
      </Box>
    </Card>
  );
};

export default AccessibilityDeclarationView;
