import React from 'react';
import { DatePicker, DateTimePicker as MuiDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import styled from 'styled-components';
import plLocale from 'date-fns/locale/pl';
import format from 'date-fns/format';
import SvgIcon from '@material-ui/core/SvgIcon';
import { skanerTheme } from 'utils/skanerTheme';

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;
const StyledDateTimePicker = styled(MuiDateTimePicker)`
  width: 100%;
`;
const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const StyledIconWrapper = styled.div`
  margin-top: auto;
  margin-right: 10px;
`;

const DatePickerIcon = () => {
  return (
    <StyledIconWrapper>
      <SvgIcon style={{ color: skanerTheme.palette.primary }} aria-hidden="true" focusable="false">
        <path
          fill="currentColor"
          d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"
        />
      </SvgIcon>
    </StyledIconWrapper>
  );
};

const DateTimePicker = ({ withTime, label, name, onChange, value }) => {
  return withTime ? (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <StyledWrapper>
        <DatePickerIcon />
        <StyledDateTimePicker
          variant="inline"
          ampm={false}
          id={name}
          name={name}
          label={label}
          format="yyyy-MM-dd HH:mm:ss"
          value={value}
          onChange={date => onChange(format(date, 'yyyy-MM-dd HH:mm:ss'))}
        />
      </StyledWrapper>
    </MuiPickersUtilsProvider>
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <StyledWrapper>
        <DatePickerIcon />
        <StyledDatePicker
          variant="inline"
          id={name}
          name={name}
          label={label}
          format="yyyy-MM-dd"
          value={value}
          onChange={date => {
            onChange(format(date, 'yyyy-MM-dd'));
          }}
        />
      </StyledWrapper>
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePicker;
