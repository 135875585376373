import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Card, CardHeader, Chip } from '@material-ui/core';
import styled from 'styled-components';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import GraphRelations from 'components/wpio/GraphRelations/GraphRelations';
import { columnViewPxBoundary, LAYOUT, TYPE } from 'components/wpio/EntitiesPersonsConsts';
import { getTypeByLocation } from 'components/wpio/EntitiesPersonsUtils';
import EntitiesDetailsPanel from 'components/wpio/EntitiesPersonsDetails/EntitiesDetailsPanel';
import PersonsDetailsPanel from 'components/wpio/EntitiesPersonsDetails/PersonsDetailsPanel';
import EntitiesContext from 'contexts/wpio/EntitiesContext';
import PersonsContext from 'contexts/wpio/PersonsContext';
import EntitiesPersonsExportPdf from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsExportPdf';
import EntitiesDivergenceOfSourcesSection from 'components/wpio/EntitiesPersonsDetails/EntitiesDivergenceOfSourcesSection';
import EntitiesPersonsTitle from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsTitle';
import {
  getNameForPersons,
  GraphDetailsTogglerButton,
  ScrollableActionsWrapper,
} from 'components/wpio/EntitiesPersonsShared';
import EntitiesNamesHistory from 'components/wpio/EntitiesPersonsDetails/EntitiesNamesHistory';
import { useWindowSize } from 'hooks/useWindowSize';
import { skanerTheme } from 'utils/skanerTheme';
import { isDataTruthy, getComputedStyleByProperty } from 'utils/utils';

const StyledHeaderCard = styled(Card)`
  background-color: ${skanerTheme.palette.primary};
`;
const StyledChip = styled(Chip)`
  background-color: ${skanerTheme.palette.white};
  margin-left: 55px;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const StyledContent = styled.div`
  display: flex;
  height: 100%;
  > div {
    transition: all 0.1s;
    :first-child {
      width: ${({ layout }) =>
        (layout && ((layout === LAYOUT.ONLY_GRAPH && '0%') || (layout === LAYOUT.ONLY_DETAILS && '100%'))) || '50%'};
    }
    :last-child {
      width: ${({ layout }) =>
        (layout && ((layout === LAYOUT.ONLY_GRAPH && '100%') || (layout === LAYOUT.ONLY_DETAILS && '0%'))) || '50%'};
    }
  }

  @media (max-width: ${columnViewPxBoundary}px) {
    flex-flow: column;
    > div {
      :first-child {
        width: 100%;
      }
      :last-child {
        width: 100%;
      }
    }
  }
`;
const StyledDetailsPanelWrapper = styled.div`
  padding: ${({ layout }) => (layout && layout === LAYOUT.ONLY_DETAILS && '0') || '16px'};
  overflow-y: auto;
  position: relative;
  @media (max-width: 1400px) {
    padding: 16px;
  }
`;
const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EntitiesPersonsDetails = () => {
  const [layout, setLayout] = useState(LAYOUT.BOTH);

  const contentRef = useRef(null);

  const location = useLocation();

  const type = getTypeByLocation(location);

  const windowSize = useWindowSize();

  const {
    id,
    details: { error, result, isLoading },
  } = useContext(type === TYPE.ENTITY ? EntitiesContext : PersonsContext);

  const onChangeLayout = type => {
    if (layout === LAYOUT.BOTH && type === LAYOUT.ONLY_DETAILS) setLayout(LAYOUT.ONLY_DETAILS);
    else if (layout === LAYOUT.BOTH && type === LAYOUT.ONLY_GRAPH) setLayout(LAYOUT.ONLY_GRAPH);
    else setLayout(LAYOUT.BOTH);
  };

  useEffect(() => {
    if (contentRef.current !== null) {
      const calculatedHeight = `${windowSize.height - contentRef.current.getBoundingClientRect().top - 30}px`;
      if (windowSize.height < 768) {
        contentRef.current.style.minHeight = calculatedHeight;
        contentRef.current.style.height = '';
      } else {
        contentRef.current.style.minHeight = '';
        contentRef.current.style.height = calculatedHeight;
      }
    }
  }, [windowSize, contentRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <ErrorHandler module="wpio" error={error} />;

  if (!result || isLoading) return <Loader />;

  return (
    <>
      <Grid item xs={12}>
        <Sticky>
          <StyledHeaderCard id="wpio-entity-person-details-header" elevation={3}>
            <CardHeader
              title={
                <>
                  <StyledTitleWrapper>
                    <EntitiesPersonsTitle
                      type={type}
                      title={type === TYPE.ENTITY ? result.nazwa : getNameForPersons(result)}
                    />
                    {isDataTruthy(result?.nazwy_historyczne) && (
                      <EntitiesNamesHistory history={result.nazwy_historyczne} />
                    )}
                  </StyledTitleWrapper>
                  {result.forma_prawna && <StyledChip label={result.forma_prawna} />}
                </>
              }
              titleTypographyProps={{ variant: 'h6' }}
              style={{ flexWrap: 'wrap' }}
              action={
                <div
                  style={{
                    marginTop: type === TYPE.ENTITY ? 15 : 5,
                    marginRight: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <EntitiesPersonsExportPdf id={id} type={type} />
                  {type === TYPE.ENTITY && <EntitiesDivergenceOfSourcesSection />}
                </div>
              }
            />
          </StyledHeaderCard>
        </Sticky>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3}>
          <div id="sticky-drawer"></div>
          <StyledContent ref={contentRef} layout={layout}>
            <div style={{ minHeight: 300 }}>
              <GraphRelations onChangeLayout={onChangeLayout} layout={layout} />
            </div>
            <StyledDetailsPanelWrapper id="wpio-entity-person-details" layout={layout}>
              <ScrollableActionsWrapper location="right" boundaryElement="#wpio-entity-person-details-header">
                {layout !== LAYOUT.ONLY_DETAILS && windowSize.width > columnViewPxBoundary && (
                  <GraphDetailsTogglerButton
                    onClick={() => onChangeLayout(LAYOUT.ONLY_DETAILS)}
                    ariaLabel="zwiń detale"
                    direction="right"
                  />
                )}
              </ScrollableActionsWrapper>
              {type === TYPE.ENTITY ? <EntitiesDetailsPanel /> : <PersonsDetailsPanel />}
            </StyledDetailsPanelWrapper>
          </StyledContent>
        </Card>
      </Grid>
    </>
  );
};

const Sticky = ({ children }) => {
  const isSticky = useRef(false);
  const stickyRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      if (!stickyRef.current) return;
      if (window.scrollY > 0) {
        setStyle(false);
        isSticky.current = true;
      } else {
        setStyle(true);
        isSticky.current = false;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [stickyRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSticky.current) setStyle(false);
  }, [windowSize]);

  const setStyle = (reset = false) => {
    let drawerHeight;
    let navbarHeight;
    let top;

    if (!reset) {
      drawerHeight = document.querySelector('#drawer').getBoundingClientRect().height;
      navbarHeight = document.querySelector('#nav-bar').getBoundingClientRect().height;
      top = drawerHeight + navbarHeight;
    }

    document.querySelector('#sticky-drawer').style.height =
      (!reset && `${stickyRef.current.parentElement.offsetHeight}px`) || '';

    stickyRef.current.style.width =
      (!reset &&
        `${
          stickyRef.current.parentElement.offsetWidth -
          parseFloat(getComputedStyleByProperty(stickyRef.current.parentElement, 'padding-left')) -
          parseFloat(getComputedStyleByProperty(stickyRef.current.parentElement, 'padding-right'))
        }px`) ||
      '';
    stickyRef.current.style.position = (!reset && 'fixed') || '';
    stickyRef.current.style.zIndex = (!reset && '2') || '';
    stickyRef.current.style.top = (!reset && `${top}px`) || '';
    stickyRef.current.style.paddingTop = (!reset && `${20}px`) || '';
    stickyRef.current.style.background = (!reset && `white`) || '';
    stickyRef.current.style.boxShadow =
      (!reset &&
        `0px 3px 1px -2px rgb(255 255 255), 0px 2px 2px 0px rgb(255 255 255), 0px 1px 5px 0px rgb(255 255 255)`) ||
      '';
  };

  return <div ref={stickyRef}>{children}</div>;
};

export default EntitiesPersonsDetails;
