import React from 'react';
import styled from 'styled-components';
import logo from './logos/logo_cst.png';

const StyledWrapper = styled.div`
  width: 100%;
  img {
    width: 200px;
    margin: 8px auto;
    display: block;
  }
`;

const LogoCst = () => {
  return (
    <StyledWrapper>
      <img src={logo} alt="logo CST2021" />
    </StyledWrapper>
  );
};

export default LogoCst;
