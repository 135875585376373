import { http } from '../http';

const getPersons = async (filters, zrodloDanych, rozmiarStrony, numerStrony, request) => {
  const response = await http.get(`/api/wpio/v1/osoby`, {
    cancelToken: request.token,
    params: {
      ...filters,
      zrodloDanych,
      rozmiarStrony,
      numerStrony,
    },
  });
  return response.data;
};

const getEuFunding = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/dofinansowaniaUe`, {
    cancelToken: request.token,
  });
  return response.data;
};

const getGrantee = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/daneGrantobiorcy`, { cancelToken: request.token });
  return response.data;
};

const getPublicProcurement = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/zamowieniaPubliczne`, { cancelToken: request.token });
  return response.data;
};

const getAuthorizedPersons = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/osobyUprawnione`, { cancelToken: request.token });
  return response.data;
};

const getProjectParticipants = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/uczestnikProjektu`, { cancelToken: request.token });
  return response.data;
};

const getProjectPersonnel = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/personelProjektu`, { cancelToken: request.token });
  return response.data;
};

const getGraph = async (id, level = 1) => {
  const response = await http.get(`/api/wpio/v1/osoby/${id}/graf?rzad=${level}`);
  return response.data;
};

export default {
  getPersons,
  getEuFunding,
  getGrantee,
  getPublicProcurement,
  getAuthorizedPersons,
  getProjectParticipants,
  getProjectPersonnel,
  getGraph,
};
