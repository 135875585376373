import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import WrapperTemplate from 'templates/WrapperTemplate';
import WpioTemplate from 'templates/WpioTemplate';
import SkanerTemplate from 'templates/SkanerTemplate';
import SamplerTemplate from 'templates/SamplerTemplate';
import Error403View from 'views/shared/Error403View';
import Error404View from 'views/shared/Error404View';
import Error500View from 'views/shared/Error500View';
import LoginView, { loginAllTabsEventListener } from 'views/skaner/LoginView';
import HomeView from 'views/skaner/HomeView';
import ActivateAccountView from 'views/skaner/User/ActivateAccountView';
import SetNewPasswordView from 'views/skaner/User/SetNewPasswordView';
import ResetPasswordView from 'views/skaner/User/ResetPasswordView';
import UsersView from 'views/skaner/UsersView';
import RouterPrompt from 'components/shared/RouterPrompt';
import PrivateRoute from 'components/shared/PrivateRoute';
import EditData from 'components/skaner/User/EditData';
import UserPanelView from 'components/skaner/User/UserPanel';
import AddUserView from 'components/skaner/Users/AddUser';
import EditUser from 'components/skaner/Users/EditUser';
import ChangeUserPassword from 'components/skaner/Users/ChangeUserPassword';
import EditPassword from 'components/skaner/User/EditPassword';
import Statute from 'components/skaner/Statute/Statute';
import StatuteVersions from 'components/skaner/Statute/StatuteVersions';
import AddStatute from 'components/skaner/Statute/AddStatute';
import PreviewStatute from 'components/skaner/Statute/PreviewStatute';
import AcceptStatute from 'components/skaner/Statute/AcceptStatute';
import AccessibilityDeclaration from 'components/skaner/AccessibilityDeclaration/AccessibilityDeclaration';
import { SkanerProvider } from 'contexts/shared/SkanerContext';
import { UserProvider } from 'contexts/shared/UserContext';
import { skanerRoutes } from 'routes/skanerRoutes';
import { skanerTheme } from 'utils/skanerTheme';
import { roles } from 'utils/roles';

const theme = createTheme({
  palette: {
    primary: {
      main: skanerTheme.palette.primary,
    },
    secondary: {
      main: skanerTheme.palette.secondary,
    },
    background: {
      default: skanerTheme.palette.white,
    },
    text: {
      primary: skanerTheme.palette.black,
    },
    error: {
      main: skanerTheme.palette.secondaryRed,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: skanerTheme.palette.black,
        '&.Mui-disabled': {
          color: skanerTheme.palette.gray,
        },
      },
    },
    MuiInputBase: {
      input: { color: skanerTheme.palette.primary },
    },
    MuiChip: {
      root: { backgroundColor: skanerTheme.palette.chip },
    },
    MuiInputLabel: {
      formControl: { top: 'initial', bottom: '32px' },
      shrink: { top: 0, bottom: 'initial' },
    },
    MuiCheckbox: {
      root: { color: skanerTheme.palette.primary },
    },
    MuiListItem: {
      root: {
        color: skanerTheme.palette.primary,
        backgroundColor: 'transparent',
        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        borderRadius: 10,
      },
      current: {
        fontWeight: '500 !important',
        color: skanerTheme.palette.black,
      },
    },
    MuiPaginationItem: {
      root: {
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
      },
      ellipsis: {
        boxShadow: 'none',
      },
    },
    MuiLink: {
      underlineHover: {
        textDecoration: 'underline',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #cccccc',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#cccccc',
      },
    },
  },
});

const useGlobalStyles = makeStyles(() => ({
  '@global': {
    '.on-hover': {
      '&:hover': {
        background: skanerTheme.palette.hoover,
      },
    },
    '.center-text': {
      textAlign: 'center',
    },
    '.bold': {
      fontWeight: '500 !important',
    },
    '.table-head-row': {
      background: skanerTheme.palette.hoover,
    },
    '.card-header-btn': {
      marginTop: '5px !important',
    },
    '.MuiTypography-colorTextSecondary': {
      color: `${skanerTheme.palette.black} !important`,
    },
    '.MuiFormLabel-root.Mui-error:not(.MuiInputLabel-shrink)': {
      top: 'initial',
      bottom: '52px',
    },
    '.MuiSelect-select .MuiChip-root': {
      height: 'initial',
    },
    '#SvgjsSvg1001': {
      display: 'none',
    },
  },
}));

const App = () => {
  useGlobalStyles();
  useEffect(() => loginAllTabsEventListener(), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SkanerProvider>
        <UserProvider>
          <Router getUserConfirmation={(message, callback) => RouterPrompt(message, callback)}>
            <WrapperTemplate>
              <Switch>
                <PrivateRoute requiredRole={[roles.ADMIN]} path={skanerRoutes.modules.sampler}>
                  <SamplerTemplate />
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.USER_WPIO]} path={skanerRoutes.modules.wpio}>
                  <WpioTemplate />
                </PrivateRoute>
                <Route exact path={skanerRoutes.home}>
                  <SkanerTemplate>
                    <HomeView />
                  </SkanerTemplate>
                </Route>
                <Route requiredRole={[roles.USER_WPIO]} exact path={skanerRoutes.statute.index}>
                  <SkanerTemplate>
                    <Statute />
                  </SkanerTemplate>
                </Route>
                <Route requiredRole={[roles.ADMIN]} exact path={skanerRoutes.statute.versions}>
                  <SkanerTemplate>
                    <StatuteVersions />
                  </SkanerTemplate>
                </Route>
                <Route requiredRole={[roles.ADMIN]} exact path={skanerRoutes.statute.add}>
                  <SkanerTemplate>
                    <AddStatute />
                  </SkanerTemplate>
                </Route>
                <Route requiredRole={[roles.ADMIN]} path={skanerRoutes.statute.preview}>
                  <SkanerTemplate>
                    <PreviewStatute />
                  </SkanerTemplate>
                </Route>
                <Route requiredRole={[roles.USER_WPIO]} path={skanerRoutes.statute.accept}>
                  <SkanerTemplate>
                    <AcceptStatute />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.accessibilityDeclaration}>
                  <SkanerTemplate>
                    <AccessibilityDeclaration />
                  </SkanerTemplate>
                </Route>
                <PrivateRoute requiredRole={[roles.ADMIN]} path={skanerRoutes.users.edit}>
                  <SkanerTemplate>
                    <EditUser />
                  </SkanerTemplate>
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.ADMIN]} path={skanerRoutes.users.editPassword}>
                  <SkanerTemplate>
                    <ChangeUserPassword />
                  </SkanerTemplate>
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.ADMIN]} path={skanerRoutes.users.add}>
                  <SkanerTemplate>
                    <AddUserView />
                  </SkanerTemplate>
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.ADMIN]} path={skanerRoutes.users.index}>
                  <SkanerTemplate>
                    <UsersView />
                  </SkanerTemplate>
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.USER_WPIO, roles.ADMIN]} path={skanerRoutes.user.editData}>
                  <SkanerTemplate>
                    <EditData />
                  </SkanerTemplate>
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.USER_WPIO, roles.ADMIN]} path={skanerRoutes.user.editPassword}>
                  <SkanerTemplate>
                    <EditPassword />
                  </SkanerTemplate>
                </PrivateRoute>
                <PrivateRoute requiredRole={[roles.USER_WPIO, roles.ADMIN]} path={skanerRoutes.user.userPanel}>
                  <SkanerTemplate>
                    <UserPanelView />
                  </SkanerTemplate>
                </PrivateRoute>
                <Route path={skanerRoutes.login}>
                  <SkanerTemplate>
                    <LoginView />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.resetPassword}>
                  <SkanerTemplate>
                    <ResetPasswordView />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.activateAccount}>
                  <SkanerTemplate>
                    <ActivateAccountView />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.setNewPassword}>
                  <SkanerTemplate>
                    <SetNewPasswordView />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.errors[403]}>
                  <SkanerTemplate>
                    <Error403View />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.errors[404]}>
                  <SkanerTemplate>
                    <Error404View />
                  </SkanerTemplate>
                </Route>
                <Route path={skanerRoutes.errors[500]}>
                  <SkanerTemplate>
                    <Error500View />
                  </SkanerTemplate>
                </Route>
                <Route path="*">
                  <SkanerTemplate>
                    <Error404View />
                  </SkanerTemplate>
                </Route>
              </Switch>
            </WrapperTemplate>
          </Router>
        </UserProvider>
      </SkanerProvider>
    </ThemeProvider>
  );
};

export default App;
