import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Card, CardHeader, CardContent, CardActions, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lock from '@material-ui/icons/Lock';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorHandler from 'components/shared/ErrorHandler';
import { Dialog } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import CircularLoader from 'components/shared/CircularLoader';
import SkanerContext from 'contexts/shared/SkanerContext';
import UserContext from 'contexts/shared/UserContext';
import UserService from 'services/shared/UserService';
import AccountService from 'services/shared/AccountService';
import { skanerRoutes } from 'routes/skanerRoutes';
import { skanerTheme } from 'utils/skanerTheme';

const NewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(10, 'Hasło jest za krótkie - musi mieć min. 10 znaków.')
    .required('To pole musi być uzupełnione.'),
  confirmNewPassword: Yup.string()
    .required('To pole musi być uzupełnione.')
    .min(10, 'Hasło jest za krótkie - musi mieć min. 10 znaków.')
    .oneOf([Yup.ref('newPassword')], 'Hasła nie pasują do siebie.'),
});

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 370,
    width: '100%',
  },
  container: {
    justifyContent: 'center',
  },
  cardHedaer: {
    background: skanerTheme.palette.primary,
    color: skanerTheme.palette.white,
  },
  cardConfirmation: {
    background: skanerTheme.palette.green,
    color: skanerTheme.palette.white,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  error: {
    justifyContent: 'flex-start',
    color: skanerTheme.palette.red,
  },
  infoText: {
    marginTop: '0px',
  },
  gridItemInputWrapper: {
    width: 'calc(100% - 40px)',
    '& > div': {
      width: '100%',
    },
  },
  passwordInfo: {
    marginTop: '5px',
    padding: '10px',
  },
}));

const newPasswordLogoutChannel = new BroadcastChannel('newPasswordLogoutChannel');

export const logoutAfterPasswordChangeAllTabsEventListener = () => {
  newPasswordLogoutChannel.onmessage = () => {
    AccountService.logout();
    localStorage.setItem('resetUser', true);
    window.location.href = window.location.origin;
    newPasswordLogoutChannel.close();
  };
};

const SetNewPasswordView = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
    title: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { appVersion } = useContext(SkanerContext);
  const { setUser } = useContext(UserContext);

  const history = useHistory();

  const { resetCode } = useParams();

  const classes = useStyles();

  const logoutAllTabsPostMessage = () => {
    newPasswordLogoutChannel.postMessage({
      loginMessage: 'Logout all tabs after password change',
    });
    logoutAfterPasswordChangeAllTabsEventListener();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    onSubmit: async ({ newPassword }, { resetForm }) => {
      try {
        setIsLoading(true);
        await UserService.setNewPassword({
          password: newPassword,
          resetCode: resetCode,
        });
        setIsLoading(false);
        setShowConfirmation(true);
        resetForm();
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Wymogi wobec hasła nie zostały spełnione.',
          });
        } else if (error.response && error.response.status === 404) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Link aktywacyjny jest niepoprawny. Skontaktuj się z administratorem.',
          });
        } else if (error.response && error.response.status === 409) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Link aktywacyjny został wykorzystany.',
          });
        } else {
          return <ErrorHandler error={error} />;
        }
      }
    },
    validationSchema: NewPasswordSchema,
  });

  return (
    <Grid container spacing={3} className={classes.container}>
      <Card elevation={3} className={classes.card}>
        <CardHeader
          title="Wprowadź nowe hasło"
          titleTypographyProps={{ variant: 'h6' }}
          className={classes.cardHedaer}
        />
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <CardContent>
            <div className={classes.margin}>
              <Grid container spacing={1} alignItems="flex-end" className={classes.gridFormRow}>
                <Grid item>
                  <Lock color="primary" titleAccess="hasło" aria-label="hasło" />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="newPassword"
                    label="Nowe hasło"
                    name="newPassword"
                    type="password"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.newPassword && formik.touched.newPassword && formik.errors.newPassword}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    fullWidth
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.gridFormRow}>
                <Grid item>
                  <Lock color="primary" titleAccess="hasło" aria-label="hasło" />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="confirmNewPassword"
                    label="Powtórz nowe hasło"
                    name="confirmNewPassword"
                    onBlur={formik.handleBlur}
                    type="password"
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    helperText={
                      formik.errors.confirmNewPassword &&
                      formik.touched.confirmNewPassword &&
                      formik.errors.confirmNewPassword
                    }
                    error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                    fullWidth
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button type="submit" variant="contained" color="primary" style={{ outline: 'none' }}>
              {isLoading ? <CircularLoader /> : 'Zapisz'}
            </Button>
          </CardActions>
          <div className={classes.passwordInfo}>
            <span>
              <strong>Wymogi wobec haseł: </strong>
              <br />
              - Minimalna liczba znaków: 10 <br />
              - Maksymalna liczba znaków: 32 <br />
              - Musi różnić się od 3 poprzednich <br />
              - Przynajmniej jedna wielka oraz jedna mała litera
              <br />
              - Przynajmniej jedna cyfra <br />
              - Przynajmniej jeden znak specjalny <br /> - Nie może zawierać ciągu min. 3 liter z adresu mailowego lub
              imienia i nazwiska
            </span>
          </div>
        </form>
        <Dialog
          isOpen={showConfirmation}
          handleClose={async () => {
            await AccountService.logout();
            setUser(null);
            logoutAllTabsPostMessage();
            history.push(skanerRoutes.login);
          }}
          close
          title="Sukces"
          text="Hasło zostało zresetowane. Naciśnij OK, aby przejść do ekranu logowania."
        />
        <Dialog
          isOpen={errorModalOptions.isOpen}
          handleClose={() => setErrorModalOptions({ isOpen: false, message: null })}
          error
          title={errorModalOptions.title}
          text={errorModalOptions.message}
        />
      </Card>
      <div style={{ position: 'absolute', bottom: 10, right: 20 }}>v.{appVersion}</div>
    </Grid>
  );
};

export default SetNewPasswordView;
