import React from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Link } from '@material-ui/core';
import {
  Divider,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
  TableContainer,
} from 'components/wpio/EntitiesPersonsShared';
import { shouldShowDivider } from 'components/wpio/EntitiesPersonsUtils';
import { isDataTruthy } from 'utils/utils';

const EntitiesBasicInfo = ({ data: { dane_krs, dane_ceidg, dane_regon } }) => {
  return (
    <>
      {isDataTruthy(dane_krs) && (
        <>
          <SubSectionTitle>Rejestr KRS</SubSectionTitle>
          {dane_krs.data_wpisu_do_rp && (
            <LabelValue label="Data wpisu do Rejestru Przedsiębiorców" value={dane_krs.data_wpisu_do_rp} />
          )}
          {dane_krs.data_wykreslenia_z_rp && (
            <LabelValue label="Data wykreślenia z Rejestru Przedsiębiorców" value={dane_krs.data_wykreslenia_z_rp} />
          )}
          {dane_krs.data_wznowienia_dzialalnosci && (
            <LabelValue label="Data wznowienia działalności" value={dane_krs.data_wznowienia_dzialalnosci} />
          )}
          {dane_krs.data_zawieszenia_dzialalnosci && (
            <LabelValue label="Data zawieszenia działalności" value={dane_krs.data_zawieszenia_dzialalnosci} />
          )}
          {dane_krs.data_wpisu_do_rs && (
            <LabelValue label="Data wpisu do Rejestru Stowarzyszeń" value={dane_krs.data_wpisu_do_rs} />
          )}
          {dane_krs.data_wykreslenia_z_rs && (
            <LabelValue label="Data wykreślenia z Rejestru Stowarzyszeń" value={dane_krs.data_wykreslenia_z_rs} />
          )}
          {dane_krs.data_uprawomocnienia_wykreslenia_z_krs && (
            <LabelValue
              label="Data uprawomocnienia wykreślenia z KRS"
              value={dane_krs.data_uprawomocnienia_wykreslenia_z_krs}
            />
          )}
          <LabelValueRow>
            {dane_krs.adres_email && (
              <LabelValue
                label="Adres e-mail"
                value={<Link href={`mailto:${dane_krs.adres_email}`}>{dane_krs.adres_email}</Link>}
              />
            )}
            {dane_krs.adres_www && (
              <LabelValue
                label="Adres WWW"
                value={
                  <Link href={'//' + dane_krs.adres_www} target="_blank" rel="noopener noreferrer nofollow">
                    {dane_krs.adres_www}
                  </Link>
                }
              />
            )}
          </LabelValueRow>
          {dane_krs.dzialalnosc_z_innymi_podmiotami && (
            <LabelValue label="Udział w spółkach cywilnych" value={dane_krs.dzialalnosc_z_innymi_podmiotami} />
          )}
          {shouldShowDivider([dane_ceidg, dane_regon]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_ceidg) && (
        <>
          <SubSectionTitle>Rejestr CEIDG</SubSectionTitle>
          {dane_ceidg.data_rozpoczecia && <LabelValue label="Data rozpoczęcia" value={dane_ceidg.data_rozpoczecia} />}
          {dane_ceidg.data_zakonczenia && <LabelValue label="Data zaprzestania" value={dane_ceidg.data_zakonczenia} />}
          {dane_ceidg.data_wykreslenia && <LabelValue label="Data wykreślenia" value={dane_ceidg.data_wykreslenia} />}
          {dane_ceidg.data_zawieszenia && <LabelValue label="Data zawieszenia" value={dane_ceidg.data_zawieszenia} />}
          {dane_ceidg.data_wznowienia && <LabelValue label="Data wznowienia" value={dane_ceidg.data_wznowienia} />}
          <LabelValueRow>
            {dane_ceidg.email && <LabelValue label="Adres e-mail" value={dane_ceidg.email} />}
            {dane_ceidg.www && <LabelValue label="Adres WWW" value={dane_ceidg.www} />}
            {dane_ceidg.telefon && <LabelValue label="Numer telefonu" value={dane_ceidg.telefon} />}
          </LabelValueRow>
          {isDataTruthy(dane_ceidg?.spolki_cywilne) && (
            <>
              <SubSectionTitle>Udział w spółkach cywilnych</SubSectionTitle>
              <TableContainer component="div">
                <Table size="small" aria-label="tabela udziałów w spółkach cywilnych">
                  <TableHead>
                    <TableRow>
                      <TableCell>Identyfikator</TableCell>
                      <TableCell>REGON</TableCell>
                      <TableCell>Data zawieszenia</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dane_ceidg.spolki_cywilne.map(({ nr_nip, nr_regon, data_zawieszenia }, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {nr_nip}
                        </TableCell>
                        <TableCell>{nr_regon}</TableCell>
                        <TableCell>{data_zawieszenia}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {shouldShowDivider([dane_regon]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_regon) && (
        <>
          <SubSectionTitle>Rejestr REGON</SubSectionTitle>
          {dane_regon.forma_prawna && <LabelValue label="Forma prawna" value={dane_regon.forma_prawna} />}
          {dane_regon.data_wpis_do_regon && <LabelValue label="Data wpisu" value={dane_regon.data_wpis_do_regon} />}
          {dane_regon.data_skreslenie_z_regon && (
            <LabelValue label="Data wykreślenia" value={dane_regon.data_skreslenie_z_regon} />
          )}
        </>
      )}
    </>
  );
};

export default EntitiesBasicInfo;
