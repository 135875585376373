import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import UsersList from 'components/skaner/Users/UsersList';
import UsersFilters from 'components/skaner/Users/UsersFilters';

const UsersView = () => {
  const [filters, setFilters] = useState(null);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <UsersFilters setFilters={setFilters} />
      </Grid>
      <Grid item xs={12}>
        <UsersList filters={filters} />
      </Grid>
    </Grid>
  );
};

export default UsersView;
