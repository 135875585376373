import React from 'react';
import ReactDOM from 'react-dom';
import { Dialog } from './Modal';

const RouterPrompt = (message, callback) => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const close = callbackState => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  const { text, confirmText, cancelText } = JSON.parse(message);

  ReactDOM.render(
    <Dialog
      isOpen={true}
      handleClose={() => close(false)}
      handleConfirm={() => close(true)}
      disableBackdropClick
      disableEscapeKeyDown
      title="Ostrzeżenie"
      text={text}
      custom={{
        confirmText: confirmText,
        closeText: cancelText,
      }}
    />,
    container
  );
};

export default RouterPrompt;
