import React, { createContext, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { useApi } from 'hooks/useApi';
import EntitiesService from 'services/wpio/EntitiesService';
import { errorMessage } from 'components/wpio/EntitiesPersonsConsts';

const dataInitialState = {
  id: null,
  details: {
    adres: '',
    dane_ceidg: null,
    dane_krs: null,
    dane_regon: {
      forma_prawna: '',
      data_wpis_do_regon: '',
      data_skreslenie_z_regon: '',
    },
    dane_srhd_bk: null,
    dane_srhd_sl: null,
    dane_vat: null,
    forma_prawna: '',
    nazwa: '',
    nazwy_historyczne: null,
    nr_krs: '',
    nr_nip: '',
    nr_regon: '',
  },
  sections: {
    relations: null,
    finances: null,
    units: null,
    transformation: null,
    object: null,
    euFunding: null,
    grantee: null,
    publicProcurement: null,
  },
  divergenceOfSources: {
    dane_ksi: null,
    dane_sl: null,
    dane_cst: null,
    dane_bk14: null,
    dane_bk21: null,
    dane_krs: null,
    dane_ceidg: null,
    dane_crbr: null,
    dane_vat: null,
    dane_regon: null,
  },
  graph: {
    graphId: '',
    graphData: null,
  },
};

const EntitiesContext = createContext();

const EntitiesContextProvider = ({ children }) => {
  const [data, setData] = useState(dataInitialState);
  const [allSectionsIsLoading, setAllSectionsIsLoading] = useState(false);
  const [graphImage, setGraphImage] = useState(null);

  const requestCancellationToken = useRef(null);

  const {
    params: { id },
  } = useRouteMatch();
  const { result = {}, isLoading, error, loaded } = useApi(`/api/wpio/v1/podmioty/${id}/szczegoly`);

  const fetchAllSections = () => {
    setAllSectionsIsLoading(true);
    clearAllSections();

    const request = axios.CancelToken.source();
    requestCancellationToken.current = request;

    if (loaded) {
      ['euFunding', 'grantee', 'publicProcurement'].forEach(req => {
        EntitiesService[`get${req.charAt(0).toUpperCase() + req.slice(1)}`](
          result.identyfikator_podmiotu,
          request.token
        )
          .then(res =>
            setData(prev => ({
              ...prev,
              sections: {
                ...prev.sections,
                [req]: res || {},
              },
            }))
          )
          .catch(error => handleError(error, req));
      });

      setData(prev => ({
        ...prev,
        id: result?.identyfikator_podmiotu || null,
        details: {
          adres: result.dane_identyfikacyjne?.adres || '',
          dane_ceidg: result?.dane_ceidg || null,
          dane_krs: result?.dane_krs || null,
          dane_regon:
            {
              forma_prawna: result?.dane_regon?.forma_prawna,
              data_wpis_do_regon: result?.dane_regon?.data_wpis_do_regon,
              data_skreslenie_z_regon: result?.dane_regon?.data_skreslenie_z_regon,
            } || null,
          dane_srhd_bk: result?.dane_srhd_bk || null,
          dane_srhd_sl: result?.dane_srhd_sl || null,
          dane_vat: result?.dane_vat || null,
          forma_prawna: result?.dane_identyfikacyjne?.forma_prawna || '',
          nazwa: result?.dane_identyfikacyjne?.nazwa || '',
          nazwy_historyczne: result?.nazwy_historyczne || null,
          nr_krs: result?.dane_identyfikacyjne?.nr_krs || '',
          nr_nip: result?.dane_identyfikacyjne?.nr_nip || '',
          nr_regon: result?.dane_identyfikacyjne?.nr_regon || '',
        },
        sections: {
          ...prev.sections,
          relations:
            {
              ...result?.powiazania,
              pelnomocnicy: result?.dane_ceidg?.pelnomocnicy,
              prokurenci_ceidg: result?.dane_ceidg?.prokurenci,
              zarzadca_sukcesyjny: result?.dane_ceidg?.zarzadca_sukcesyjny,
            } || {},
          finances: result?.finanse || {},
          units: result?.oddzialy || {},
          transformation: result?.przeksztalcenia || [],
          object: result?.przedmiot_dzialanosci || {},
        },
        divergenceOfSources: result?.rozbieznosc_zrodel || {},
        graph: {
          graphId: result?.id_podmiot_graf || '',
          graphData: result?.graf || [],
        },
      }));
    } else if (error) {
      setData(prev => ({
        ...prev,
        sections: {
          relations: { error: true },
          finances: { error: true },
          units: { error: true },
          transformation: { error: true },
          object: { error: true },
        },
      }));
    }
  };

  const handleError = (error, key) => {
    if (axios.isCancel(error)) return;
    setData(prev => ({
      ...prev,
      sections: {
        ...prev.sections,
        [key]: { error: !!error?.response?.data?.detail ? error.response.data.detail : errorMessage },
      },
    }));
  };

  const cancelNotFinishedRequests = () => {
    if (requestCancellationToken.current) requestCancellationToken.current.cancel();
  };

  const clearAllSections = () => {
    cancelNotFinishedRequests();
    setData(dataInitialState);
  };

  useEffect(() => {
    return () => cancelNotFinishedRequests();
  }, []);

  useEffect(() => {
    if (loaded && id) fetchAllSections();
  }, [loaded, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.values(data.sections).every(section => !!section)) setAllSectionsIsLoading(false);
  }, [data.sections]);

  return (
    <EntitiesContext.Provider
      value={{
        id: data.id,
        details: { result: data.details, isLoading, error },
        sections: {
          isLoading: allSectionsIsLoading,
          relations: data.sections.relations,
          finances: data.sections.finances,
          units: data.sections.units,
          euFunding: { ...data.sections.euFunding, ...data.sections.grantee },
          publicProcurement: data.sections.publicProcurement,
          transformation: data.sections.transformation,
          object: data.sections.object,
        },
        divergenceOfSources: data.divergenceOfSources,
        graph: {
          graphId: data.graph.graphId,
          graphData: data.graph.graphData,
          graphImage,
          setGraphImage,
        },
      }}
    >
      {children}
    </EntitiesContext.Provider>
  );
};

export default EntitiesContext;

export { EntitiesContextProvider };
