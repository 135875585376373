import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@material-ui/core';
import { Button } from 'components/shared/Button';
import Pagination from 'components/shared/Pagination';
import ActionTableCell from 'components/shared/ActionTableCell';
import { SOURCES } from 'components/wpio/EntitiesPersonsList/EntitiesPersonsList';
import { getEntityPersonValue } from 'components/wpio/EntitiesPersonsUtils';
import { wpioRoutes } from 'routes/wpioRoutes';
import { getRoutePath } from 'utils/utils';

const KRS_TYPES = {
  RP: 'Rejestr Przedsiębiorców',
  RS: 'Rejestr Stowarzyszeń',
};

const EntitiesTable = ({ source, data, pageNumber, pageSize, applyPaging, limitedRowsPerPage }) => {
  return (
    <>
      <Table aria-label="Tabela podmiotów">
        <TableHead>
          <TableRow className="table-head-row">
            <TableCell>Identyfikator</TableCell>
            <TableCell>Nazwa</TableCell>
            <TableCell>Adres siedziby</TableCell>
            {source === SOURCES.KRS && <TableCell>Typ rejestru KRS</TableCell>}
            <ActionTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.lista_podmiotow &&
            [...data.lista_podmiotow]
              .sort((a, b) => (!a.nr_nip ? 1 : !b.nr_nip ? -1 : a.nr_nip.localeCompare(b.nr_nip)))
              .map(d => (
                <TableRow key={d.identyfikator_grupy_podmiotow}>
                  <TableCell component="th" scope="row">
                    {getEntityPersonValue(d.nr_nip)}
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={getRoutePath(wpioRoutes.entitiesPersons.entityDetails, d.identyfikator_grupy_podmiotow)}
                      target="_blank"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {getEntityPersonValue(d.nazwa)}
                    </Link>
                  </TableCell>
                  <TableCell>{getEntityPersonValue(d.adres)}</TableCell>
                  {source === SOURCES.KRS && <TableCell>{KRS_TYPES[d.typ_rejestru_krs]}</TableCell>}
                  <TableCell style={{ textAlign: 'right' }}>
                    <Button
                      component={RouterLink}
                      variant="contained"
                      to={getRoutePath(wpioRoutes.entitiesPersons.entityDetails, d.identyfikator_grupy_podmiotow)}
                      color="primary"
                      target="_blank"
                    >
                      Szczegóły
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Pagination
        count={data.liczba_podmiotow || 0}
        page={pageNumber}
        pageSize={pageSize}
        rowsPerPageOptions={limitedRowsPerPage ? [10, 25] : [10, 25, 50]}
        handleChangePage={page => {
          applyPaging({ pageSize, pageNumber: page });
        }}
        handleChangeRowsPerPage={event => {
          applyPaging({
            pageNumber: 1,
            pageSize: parseInt(event.target.value, 10),
          });
        }}
        isPreciseCount={Boolean(data.czy_liczba_dokladna)}
      />
    </>
  );
};

export default EntitiesTable;
