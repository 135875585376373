import React from 'react';
import { useHistory } from 'react-router-dom';
import ErrorBaseView from 'views/shared/ErrorBaseView';
import { Button } from 'components/shared/Button';

const Error500View = () => {
  const history = useHistory();

  return (
    <ErrorBaseView
      title="Błąd 500"
      message="Coś poszło nie tak. Skontaktuj się z administratorem."
      action={
        <Button
          style={{ marginTop: 20 }}
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => history.goBack()}
          title="Powrót do poprzedniej strony"
        >
          Powrót
        </Button>
      }
    />
  );
};

export default Error500View;
