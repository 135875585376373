import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Button } from 'components/shared/Button';
import ActionTableCell from 'components/shared/ActionTableCell';

const SamplersTable = ({ data, handleDownloadClick }) => {
  return (
    <Table aria-label="Tabela podmiotów">
      <TableHead>
        <TableRow className="table-head-row">
          <TableCell>Nazwa</TableCell>
          <TableCell>Status analizy</TableCell>
          <ActionTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(d => (
          <TableRow key={d.id_zlecenie}>
            <TableCell component="th" scope="row">
              {d.nazwa_pliku}
            </TableCell>
            <TableCell>{d.czy_gotowy ? 'Gotowe' : 'W trakcie'}</TableCell>
            <TableCell>
              <div style={{ display: 'flex', gap: 16 }}>
                <Button
                  target="_blank"
                  variant="contained"
                  onClick={() => handleDownloadClick(d.id_zlecenie)}
                  color="primary"
                  disabled={!d.czy_gotowy}
                >
                  Pobierz
                </Button>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SamplersTable;
