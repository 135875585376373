import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import TimerOutlined from '@material-ui/icons/TimerOutlined';
import clsx from 'clsx';
import { BroadcastChannel } from 'broadcast-channel';
import ErrorHandler from 'components/shared/ErrorHandler';
import UserContext from 'contexts/shared/UserContext';
import AccountService from 'services/shared/AccountService';
import LogoSkaner from 'components/skaner/Layout/LogoSkaner';
import SessionTimer from 'components/skaner/SessionTimer/SessionTimer';
import { skanerRoutes } from 'routes/skanerRoutes';
import { getRoutePath } from 'utils/utils';
import { skanerTheme } from 'utils/skanerTheme';

export const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: skanerTheme.palette.primary,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    height: 64,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  rightAppBarBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  timeSession: {
    marginTop: '5px',
    marginLeft: '5px',
    '& > span': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '&.drawer-is-open > span': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
}));

export const logoutChannel = new BroadcastChannel('logout');

export const logoutAllTabsEventListener = () => {
  logoutChannel.onmessage = () => {
    AccountService.logout();
    localStorage.setItem('resetUser', true);
    window.location.href = window.location.origin;
    logoutChannel.close();
  };
};

const TopBar = ({ moduleTitle, drawer = null }) => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  const { user, setUser, error, setIsStatuteAccepted } = useContext(UserContext);

  const history = useHistory();

  const location = useLocation();

  const classes = useStyles();

  const toggleDrawer = () => drawer && drawer.setIsOpen(!drawer.isOpen);

  const handleMenu = event => setUserMenuAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setUserMenuAnchorEl(null);

  const handleLogout = () => {
    logoutChannel.postMessage({ logoutMessage: 'Logout' });
    AccountService.logout();
    setUser(null);
    setIsStatuteAccepted(false);
    logoutAllTabsEventListener();
    history.push(skanerRoutes.home);
  };

  const handleLogin = () => {
    if (location && location.pathname !== skanerRoutes.login) history.push(skanerRoutes.login);
    handleCloseMenu();
  };

  if (error) return <ErrorHandler error={error} />;

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawer && drawer.isOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        {drawer && (
          <IconButton
            color="inherit"
            aria-label={drawer.isOpen ? 'Zamknij boczne menu' : 'Otwórz boczne menu'}
            onClick={toggleDrawer}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        )}
        <LogoSkaner />
        <Typography variant="h5" component="h1" noWrap className={classes.title}>
          {moduleTitle}
        </Typography>
        <div className={classes.rightAppBarBlock}>
          {user && user.isLoggedIn && (
            <Typography
              className={clsx(classes.timeSession, {
                'drawer-is-open': drawer && drawer.isOpen,
              })}
            >
              <span>Czas do końca sesji: </span>
              <SessionTimer />
            </Typography>
          )}
          {user && user.isLoggedIn && (
            <Tooltip title="Przedłuż sesję" arrow>
              <IconButton
                aria-label="przedłuż sesję"
                onClick={() => {
                  AccountService.resetSession();
                }}
                color="inherit"
              >
                <TimerOutlined />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            aria-label="otwórz menu użytkownika"
            aria-controls="menu-user-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-user-appbar"
            anchorEl={userMenuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                marginTop: '35px',
                width: '250px',
              },
            }}
            open={Boolean(userMenuAnchorEl)}
            onClose={handleCloseMenu}
            disableAutoFocusItem={true}
          >
            {user && user.firstName && user.lastName && (
              <div style={{ outline: 'none', padding: '8px 16px' }}>
                <span>
                  Zalogowany: {user.firstName} {user.lastName}
                </span>
              </div>
            )}
            {user && user.isLoggedIn ? (
              <MenuItem
                className="on-hover"
                onClick={() => history.push(getRoutePath(skanerRoutes.user.userPanel, user.id))}
              >
                <ListItemIcon>
                  <SvgIcon>
                    {user && user.isLoggedIn ? (
                      <path
                        fill="currentColor"
                        d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
                      />
                    ) : (
                      <path
                        fill="currentColor"
                        d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z"
                      />
                    )}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary="Panel użytkownika" />
              </MenuItem>
            ) : null}
            <MenuItem className="on-hover" onClick={() => (user && user.isLoggedIn ? handleLogout() : handleLogin())}>
              <ListItemIcon>
                <SvgIcon>
                  {user && user.isLoggedIn ? (
                    <path
                      fill="currentColor"
                      d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z"
                    />
                  ) : (
                    <path
                      fill="currentColor"
                      d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z"
                    />
                  )}
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary={user && user.isLoggedIn ? 'Wyloguj się' : 'Zaloguj się'} />
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
