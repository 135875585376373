import { useState, useEffect } from 'react';
import StatuteService from 'services/shared/StatuteService';

const useStatuteFile = uri => {
  const [pdf, setPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getStatutePdf = async statuteUri => {
    try {
      setIsLoading(true);
      const arrayBuffer = await StatuteService.getStatute(statuteUri);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(blob);
      setPdf(pdfUrl);
    } catch (error) {
      if (error.response) {
        setError({
          status: error.response.status,
          message: error.response.data,
        });
      } else {
        setError({ message: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getStatuteUri = async () => {
    try {
      setIsLoading(true);
      const { uri: currentStatuteUri } = await StatuteService.verifyStatute();
      getStatutePdf(currentStatuteUri);
    } catch (error) {
      if (error.response) {
        setError({
          status: error.response.status,
          message: error.response.data,
        });
      } else {
        setError({ message: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (uri) getStatutePdf(uri);
    else getStatuteUri();
  }, [uri]); // eslint-disable-line react-hooks/exhaustive-deps

  return { pdf, isLoading, error };
};

export default useStatuteFile;
