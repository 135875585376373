import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Card, CardHeader, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import ErrorHandler from 'components/shared/ErrorHandler';
import { Button, DangerButton } from 'components/shared/Button';
import DateTimePicker from 'components/shared/DateTimePicker';
import FileUploader from 'components/shared/FileUploader';
import CircularLoader from 'components/shared/CircularLoader';
import { Dialog } from 'components/shared/Modal';
import StatuteService from 'services/shared/StatuteService';

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    textAlign: 'right',
  },
}));

const AddStatute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
  });

  const history = useHistory();

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      nazwa_wersji: '',
      at_data_obowiazywania: null,
      fileName: '',
      dataUrl: '',
    },
    onSubmit: async values => {
      try {
        setIsLoading(true);
        await StatuteService.addStatute(values);
        setIsConfirmDialogOpen(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          setErrorModalOptions({
            isOpen: true,
            message: error.response.data.detail,
          });
        } else {
          return <ErrorHandler error={error} />;
        }
      }
    },
  });

  const getStatuteFile = ({ fileName, fileDataUrl }) => {
    formik.setFieldValue('fileName', fileName);
    formik.setFieldValue('dataUrl', fileDataUrl);
  };

  const isFormValid = Object.values(formik.values).every(value => Boolean(value));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={3}>
          <CardHeader title="Dodaj wersję regulaminu" titleTypographyProps={{ variant: 'h6' }} />
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6}>
                <TextField
                  id="nazwa_wersji"
                  label="Nazwa wersji regulaminu"
                  name="nazwa_wersji"
                  value={formik.values.nazwa_wersji}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6}>
                <DateTimePicker
                  label="Data obowiązywania"
                  name="at_data_obowiazywania"
                  value={formik.values.at_data_obowiazywania}
                  onChange={date => formik.setFieldValue('at_data_obowiazywania', new Date(date).toISOString())}
                  fullWidth
                  withTime={false}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6}>
                <FileUploader fileType="application/pdf" getFileData={getStatuteFile} />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button type="submit" variant="contained" color="primary" disabled={!isFormValid}>
                  {isLoading ? <CircularLoader /> : 'Dodaj'}
                </Button>
                <DangerButton style={{ marginLeft: 16 }} variant="outlined" onClick={() => history.goBack()}>
                  Anuluj
                </DangerButton>
              </Grid>
            </Grid>
          </form>
          <Dialog
            isOpen={isConfirmDialogOpen}
            title="Sukces"
            text="Nowa wersja regulaminu została dodana."
            close
            handleClose={() => window.location.reload()}
          />
          <Dialog
            isOpen={errorModalOptions.isOpen}
            title="Błąd"
            text={errorModalOptions.message}
            error
            handleClose={() => setErrorModalOptions({ isOpen: false, message: null })}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddStatute;
