import React, { useContext } from 'react';
import Loader from 'components/shared/Loader';
import EntitiesPersonsAccordion from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsAccordion';
import EntitiesPersonsEuFundingSection from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsEuFundingSection';
import EntitiesPersonsPublicProcurementSection from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsPublicProcurementSection';
import PersonsRelationsSection from 'components/wpio/EntitiesPersonsDetails/PersonsRelationsSection';
import PersonsAuthorizedPersonsSection from 'components/wpio/EntitiesPersonsDetails/PersonsAuthorizedPersonsSection';
import PersonsProjectParticipantsSection from 'components/wpio/EntitiesPersonsDetails/PersonsProjectParticipantsSection';
import PersonsProjectPersonnelSection from 'components/wpio/EntitiesPersonsDetails/PersonsProjectPersonnelSection';
import PersonsContext from 'contexts/wpio/PersonsContext';
import { isDataTruthy } from 'utils/utils';

const PersonsRestInformationSection = () => {
  const {
    sections: {
      isLoading,
      relations,
      euFunding,
      publicProcurement,
      authorizedPersons,
      projectParticipants,
      projectPersonnel,
    },
  } = useContext(PersonsContext);

  const accordionItems = [
    {
      panelId: 1,
      title: 'Powiązania',
      show: isDataTruthy(relations),
      content: <PersonsRelationsSection relations={relations} error={relations?.error} />,
    },
    {
      panelId: 2,
      title: 'Dofinansowania z UE',
      show: isDataTruthy(euFunding),
      content: <EntitiesPersonsEuFundingSection euFunding={euFunding} error={euFunding?.error} />,
    },
    {
      panelId: 3,
      title: 'Zamówienia publiczne',
      show: isDataTruthy(publicProcurement),
      content: (
        <EntitiesPersonsPublicProcurementSection
          publicProcurement={publicProcurement}
          error={publicProcurement?.error}
        />
      ),
    },
    {
      panelId: 4,
      title: 'Osoby uprawnione',
      show: isDataTruthy(authorizedPersons),
      content: (
        <PersonsAuthorizedPersonsSection authorizedPersons={authorizedPersons} error={authorizedPersons?.error} />
      ),
    },
    {
      panelId: 5,
      title: 'Uczestnicy projektów',
      show: isDataTruthy(projectParticipants),
      content: (
        <PersonsProjectParticipantsSection
          projectParticipants={projectParticipants}
          error={projectParticipants?.error}
        />
      ),
    },
    {
      panelId: 6,
      title: 'Personel projektu',
      show: isDataTruthy(projectPersonnel),
      content: <PersonsProjectPersonnelSection projectPersonnel={projectPersonnel} error={projectPersonnel?.error} />,
    },
  ];

  return (
    <>
      <EntitiesPersonsAccordion items={accordionItems} />
      {isLoading && <Loader />}
    </>
  );
};

export default PersonsRestInformationSection;
