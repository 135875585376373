import { http } from '../http';

const getEntityPdf = async (id, data) => {
  const response = await http.post(`/api/wpio/v1/pdf/podmiot_lub_osoba/${id}/generuj_podmiot`, data);
  return response.data.pdf_id;
};

const getPersonPdf = async (id, data) => {
  const response = await http.post(`/api/wpio/v1/pdf/podmiot_lub_osoba/${id}/generuj_osoba`, data);
  return response.data.pdf_id;
};

const getPdfStatus = async id => {
  const response = await http.get(`/api/wpio/v1/pdf/task/${id}/status`);
  return response.data;
};

const getPdfFile = async id => {
  const response = await http.get(`/api/wpio/v1/pdf/task/${id}/pobierz`, {
    responseType: 'arraybuffer',
  });
  return response.data;
};

export default {
  getEntityPdf,
  getPersonPdf,
  getPdfStatus,
  getPdfFile,
};
