import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Paper,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { skanerTheme } from 'utils/skanerTheme';

const useStyles = makeStyles(theme => ({
  navWrapper: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  nav: {
    display: 'none',
    padding: `0 24px`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  hamburgerNav: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  popoverPaper: {
    width: '100%',
    maxWidth: 'unset',
    left: '0 !important',
    borderRadius: 0,
  },
  item: {
    width: 'initial',
    marginRight: 30,
    color: skanerTheme.palette.black,
    fontWeight: 500,
    '& svg': {
      color: skanerTheme.palette.black,
    },
  },
  hamburgerItem: {
    marginRight: 0,
  },
  itemText: {
    '& > span': {
      fontWeight: 500,
    },
  },
  active: {
    color: skanerTheme.palette.primary,
    '& svg': {
      color: skanerTheme.palette.primary,
    },
  },
}));

const ModuleNavBar = ({ menu }) => {
  const [hamburgerMenuAnchorEl, setHamburgerMenuAnchorEl] = useState(null);

  const history = useHistory();

  const location = useLocation();

  const classes = useStyles();

  return (
    <div className={classes.navWrapper} id="nav-bar">
      <Paper elevation={3} style={{ borderRadius: 0 }}>
        <List component="nav" className={classes.nav}>
          {menu.map(
            el =>
              el.show && (
                <ListItem
                  key={el.title}
                  button
                  className={clsx(classes.item, {
                    [classes.active]:
                      location.pathname === el.path || el.activePaths.some(el => location.pathname.includes(el)),
                  })}
                  onClick={() => history.push(el.path)}
                >
                  <ListItemIcon>
                    <SvgIcon>{el.svgIcon}</SvgIcon>
                  </ListItemIcon>
                  <ListItemText primary={el.title} className={classes.itemText} />
                </ListItem>
              )
          )}
        </List>
        <nav className={classes.hamburgerNav}>
          <IconButton
            color="inherit"
            aria-controls="hamburger-menu"
            aria-label={hamburgerMenuAnchorEl ? 'Zamknij menu mobilne' : 'Otwórz menu mobilne'}
            onClick={event => {
              setHamburgerMenuAnchorEl(event.currentTarget);
            }}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="hamburger-menu"
            anchorEl={hamburgerMenuAnchorEl}
            keepMounted
            open={Boolean(hamburgerMenuAnchorEl)}
            onClose={() => {
              setHamburgerMenuAnchorEl(null);
            }}
            className={classes.hamburgerMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            PopoverClasses={{ paper: classes.popoverPaper }}
          >
            {menu.map(
              el =>
                el.show && (
                  <MenuItem
                    key={el.title}
                    button
                    className={clsx(classes.item, classes.hamburgerItem, {
                      [classes.active]:
                        location.pathname === el.path || el.activePaths.some(el => location.pathname.includes(el)),
                    })}
                    onClick={() => {
                      setHamburgerMenuAnchorEl(null);
                      history.push(el.path);
                    }}
                  >
                    <ListItemIcon>
                      <SvgIcon>{el.svgIcon}</SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={el.title} className={classes.itemText} />
                  </MenuItem>
                )
            )}
          </Menu>
        </nav>
      </Paper>
    </div>
  );
};

export default ModuleNavBar;
