import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { Divider, ErrorMessage, LabelValue, SubSectionTitle } from 'components/wpio/EntitiesPersonsShared';
import { isDataTruthy } from 'utils/utils';

const StyledSubSubTitle = styled(Typography)`
  margin: 16px 0;
  text-decoration: underline;
  font-weight: 500;
`;

const EntitiesTransformationSection = ({ transformation, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <>
      {isDataTruthy(transformation) &&
        transformation.map(({ okreslenie_okolicznosci, opis, podmioty_powstale, podmioty_przejmowane }, index) => (
          <div key={index}>
            <SubSectionTitle>{okreslenie_okolicznosci}</SubSectionTitle>
            <Typography variant="caption" display="block" gutterBottom>
              {opis}
            </Typography>
            {isDataTruthy(podmioty_powstale) && (
              <>
                <StyledSubSubTitle variant="subtitle1">Podmioty powstałe</StyledSubSubTitle>
                {podmioty_powstale.map(
                  (
                    {
                      nazwa,
                      kraj_i_nazwa_rejestru,
                      numer_w_rejestrze,
                      numer_w_rejestrze_z_krs,
                      nazwa_sadu_prowadzacego,
                      numer_regon,
                    },
                    pp_index
                  ) => (
                    <div key={pp_index} style={{ marginLeft: 32 }}>
                      {nazwa && <LabelValue label="Nazwa" value={nazwa} />}
                      {kraj_i_nazwa_rejestru && (
                        <LabelValue label="Kraj i nazwa rejestru" value={kraj_i_nazwa_rejestru} />
                      )}
                      {(numer_w_rejestrze || numer_w_rejestrze_z_krs) && (
                        <LabelValue label="Numer w rejestrze" value={numer_w_rejestrze || numer_w_rejestrze_z_krs} />
                      )}
                      {nazwa_sadu_prowadzacego && (
                        <LabelValue label="Nazwa sądu prowadzącego" value={nazwa_sadu_prowadzacego} />
                      )}
                      {numer_regon && <LabelValue label="REGON" value={numer_regon} />}
                      {pp_index !== podmioty_powstale.length - 1 && <Divider />}
                    </div>
                  )
                )}
              </>
            )}
            {isDataTruthy(podmioty_przejmowane) && (
              <>
                <StyledSubSubTitle variant="subtitle1" style={{ marginTop: 32 }}>
                  Podmioty przejmowane
                </StyledSubSubTitle>
                {podmioty_przejmowane.map(
                  (
                    {
                      nazwa,
                      kraj_i_nazwa_rejestru,
                      numer_w_rejestrze,
                      numer_w_rejestrze_z_krs,
                      nazwa_sadu_prowadzacego,
                      numer_regon,
                    },
                    pp_index
                  ) => (
                    <div key={pp_index} style={{ marginLeft: 32 }}>
                      {nazwa && <LabelValue label="Nazwa" value={nazwa} />}
                      {kraj_i_nazwa_rejestru && (
                        <LabelValue label="Kraj i nazwa rejestru" value={kraj_i_nazwa_rejestru} />
                      )}
                      {(numer_w_rejestrze || numer_w_rejestrze_z_krs) && (
                        <LabelValue label="Numer w rejestrze" value={numer_w_rejestrze || numer_w_rejestrze_z_krs} />
                      )}
                      {nazwa_sadu_prowadzacego && (
                        <LabelValue label="Nazwa sądu prowadzącego" value={nazwa_sadu_prowadzacego} />
                      )}
                      {numer_regon && <LabelValue label="REGON" value={numer_regon} />}
                      {pp_index !== podmioty_przejmowane.length - 1 && <Divider />}
                    </div>
                  )
                )}
              </>
            )}
            {index !== transformation.length - 1 && <Divider />}
          </div>
        ))}
    </>
  );
};

export default EntitiesTransformationSection;
