import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles(() => ({
  document: {
    textAlign: 'center',
  },
  page: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const PdfViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);

  const classes = useStyles();

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const getPages = () => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(<Page key={`page_${i}`} pageNumber={i} className={classes.page} />);
    }
    return pages;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          loading="Ładowanie dokumentu PDF"
          noData="Brak dokumentu PDF"
          error="Wystąpił błąd przy ładowaniu dokumentu PDF"
          className={classes.document}
        >
          {getPages()}
        </Document>
      </Grid>
    </Grid>
  );
};

export default PdfViewer;
