import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loader from 'components/shared/Loader';
import UserContext from 'contexts/shared/UserContext';
import { skanerRoutes } from 'routes/skanerRoutes';

const PrivateRoute = ({ children, requiredRole = null, ...rest }) => {
  const { user, isLoading } = useContext(UserContext);

  const userHasNoRequiredRoles = user && !user.roles.some(role => requiredRole.includes(role));

  if (!isLoading && requiredRole && userHasNoRequiredRoles) return <Redirect push to={skanerRoutes.errors[403]} />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoading ? (
          user && user.isLoggedIn ? (
            children
          ) : (
            <Redirect to={{ pathname: skanerRoutes.login, state: { from: location } }} />
          )
        ) : (
          <Loader />
        )
      }
    />
  );
};

export default PrivateRoute;
