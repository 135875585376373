export const roles = {
  ADMIN: 'Admin',
  USER_WPIO: 'UserWpio',
};

export const roleModulesMap = [
  {
    name: 'Weryfikacja podmiotów i osób',
    role: roles.USER_WPIO,
  },
];
