import React from 'react';
import { Redirect } from 'react-router-dom';
import { skanerRoutes } from 'routes/skanerRoutes';

const ErrorHandler = ({ error }) => {
  if (error && error.status) {
    switch (error.status) {
      case 403:
        return <Redirect push to={skanerRoutes.errors[403]} />;
      case 404:
        return <Redirect push to={skanerRoutes.errors[404]} />;
      default:
        return <Redirect push to={skanerRoutes.errors[500]} />;
    }
  }
  return <Redirect push to={skanerRoutes.errors[500]} />;
};

export default ErrorHandler;
