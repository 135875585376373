export const skanerTheme = {
  palette: {
    primary: '#0D47A1',
    secondary: '#777777',
    disabled: '#00000042',
    white: '#FFFFFF', // working area
    black: '#000000', // labels
    hoover: '#E8E8E8', // on hoover
    red: '#EB0000', // negative buttons
    secondaryRed: '#D11632', // error labels
    chip: '#DFDFDF',
    tab: '#E0E0E0',
    lightgray: '#F5F5F5',
    graphPerson: '#D4024E',
    gray: '#6D6D6D',
    green: '#30C230',
  },
};
