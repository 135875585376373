import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import SamplerListView from 'views/sampler/SamplerListView';
import TopBar from 'components/skaner/Layout/TopBar';
import DrawerHeader from 'components/skaner/Layout/DrawerHeader';
import ModuleContent from 'components/skaner/Layout/ModuleContent';

const SamplerTemplate = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <TopBar moduleTitle="Sampler" />
      <DrawerHeader />
      <ModuleContent>
        <Switch>
          <Route exact path={path}>
            <SamplerListView />
          </Route>
        </Switch>
      </ModuleContent>
    </>
  );
};

export default SamplerTemplate;
