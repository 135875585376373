import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  DialogActions,
  DialogContent,
  Tooltip,
  SvgIcon,
  IconButton,
} from '@material-ui/core';
import { Modal } from 'components/shared/Modal';
import { DangerButton } from 'components/shared/Button';
import { skanerTheme } from 'utils/skanerTheme';

const EntitiesNamesHistory = ({ history }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClick = () => setModalIsOpen(true);

  const modalHandleClose = () => setModalIsOpen(false);

  return (
    <div style={{ marginLeft: 10 }}>
      <Tooltip title="Historia zmian nazwy podmiotu">
        <IconButton type="button" onClick={handleClick}>
          <SvgIcon>
            <path
              fill={skanerTheme.palette.white}
              d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3"
            />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Modal isOpen={modalIsOpen} title="Historia zmian nazwy podmiotu" handleClose={modalHandleClose} maxWidth="md">
        <DialogContent style={{ padding: 0 }}>
          <TableContainer component={Card} elevation={3}>
            <div style={{ overflowY: 'auto' }}>
              <Table aria-label="tabela z historią nazwy podmiotu">
                <TableHead>
                  <TableRow className="table-head-row">
                    <TableCell>Nazwa</TableCell>
                    <TableCell style={{ minWidth: 150 }}>Data od</TableCell>
                    <TableCell style={{ minWidth: 150 }}>Data do</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map(({ nazwa, data_od, data_do }, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {nazwa}
                      </TableCell>
                      <TableCell>{data_od}</TableCell>
                      <TableCell>{(data_do === '9999-12-31' && 'obecnie') || data_do}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <DangerButton style={{ marginLeft: 16 }} variant="outlined" onClick={modalHandleClose}>
            Zamknij
          </DangerButton>
        </DialogActions>
      </Modal>
    </div>
  );
};

export default EntitiesNamesHistory;
