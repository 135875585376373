import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import { Button, DangerButton } from 'components/shared/Button';
import PdfViewer from 'components/shared/PdfViewer';
import SkanerContext from 'contexts/shared/SkanerContext';
import UserContext from 'contexts/shared/UserContext';
import useStatuteFile from 'hooks/useStatuteFile';
import AccountService from 'services/shared/AccountService';
import StatuteService from 'services/shared/StatuteService';
import { skanerRoutes } from 'routes/skanerRoutes';

const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
    minHeight: 'calc(100vh - 112px)',
  },
  buttonContainer: {
    position: 'fixed',
    right: 40,
    bottom: 40,
  },
  dangerButton: {
    marginLeft: 16,
  },
}));

const AcceptStatute = () => {
  const { statuteUri } = useContext(SkanerContext);
  const { setUser, setIsStatuteAccepted } = useContext(UserContext);

  const history = useHistory();

  const classes = useStyles();

  const { pdf, isLoading, error } = useStatuteFile(statuteUri);

  const acceptStatute = async () => {
    await StatuteService.acceptStatute();
    setIsStatuteAccepted(true);
    history.push(skanerRoutes.home);
  };

  const rejectStatute = () => {
    AccountService.logout();
    setUser(null);
    history.push(skanerRoutes.home);
  };

  if (error) return <ErrorHandler error={error} />;
  if (isLoading || !pdf) return <Loader />;

  return (
    <Card elevation={3} className={classes.card}>
      <CardHeader title="Regulamin" titleTypographyProps={{ variant: 'h6' }} />
      <PdfViewer pdf={pdf} />
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button type="submit" variant="contained" color="primary" disabled={!pdf && isLoading} onClick={acceptStatute}>
          Zaakceptuj
        </Button>
        <DangerButton className={classes.dangerButton} variant="outlined" onClick={rejectStatute}>
          Anuluj
        </DangerButton>
      </Grid>
    </Card>
  );
};

export default AcceptStatute;
