import React from 'react';
import styled from 'styled-components';
import logo from './logos/logo_fe_rp_ue.png';

const StyledImage = styled.img`
  width: 260px;
`;

const LogoBottom = () => {
  return (
    <StyledImage
      src={logo}
      alt="Zestawienie znaków: Fundusze Europejskie, Barwy Rzeczypospolitej Polskiej, Unia Europejska"
    />
  );
};

export default LogoBottom;
