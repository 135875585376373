import React from 'react';
import styled from 'styled-components';

const StyledLoaderWrapper = styled.div`
  margin: 100px auto;
  text-align: center;
  font-size: 16px;
`;

const StyledLoader = styled.div`
  width: 60px;
  height: 50px;
  margin: 10px auto;
  > div {
    background-color: #0d47a1;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 0 3px 0 0;
    animation: loader 1.2s infinite ease-in-out;
    :nth-child(2) {
      animation-delay: -1.1s;
    }
    :nth-child(3) {
      animation-delay: -1s;
    }
    :nth-child(4) {
      animation-delay: -0.9s;
    }
    :nth-child(5) {
      animation-delay: -0.8s;
    }
  }

  @keyframes loader {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1);
    }
  }
`;

const Loader = () => {
  return (
    <StyledLoaderWrapper>
      <StyledLoader>
        <div />
        <div />
        <div />
        <div />
        <div />
      </StyledLoader>
      <div>Wczytuję...</div>
    </StyledLoaderWrapper>
  );
};

export default Loader;
