import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { skanerRoutes } from 'routes/skanerRoutes';
import logo from './logos/logo_skaner.png';

const StyledWrapper = styled.div`
  display: flex;
  width: 175px;
  margin-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 62px;
  img {
    position: absolute;
    top: 4px;
  }
  > a {
    min-width: 145px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const LogoSkaner = ({ ...props }) => {
  return (
    <StyledWrapper {...props}>
      <Link to={skanerRoutes.home}>
        <img src={logo} alt="logo skaner" />
      </Link>
    </StyledWrapper>
  );
};

export default LogoSkaner;
