import React from 'react';
import { Link, Chip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
} from 'components/wpio/EntitiesPersonsShared';
import { StyledLabel, StyledLabelValue } from 'components/wpio/EntitiesPersonsShared';
import { shouldShowDivider } from 'components/wpio/EntitiesPersonsUtils';
import { wpioRoutes } from 'routes/wpioRoutes';
import { isDataTruthy, getRoutePath } from 'utils/utils';

const StyledChip = styled(Chip)`
  font-size: 10px;
  height: 24px;
  margin-left: 16px;
  margin-top: -5px;
`;

const EntitiesRelationsSection = ({ relations, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  Object.keys(relations).forEach(key => {
    if (isDataTruthy(relations[key]) && Array.isArray(relations[key])) {
      relations[key].forEach(relation => {
        if (relation.dane_sformatowane === null) relation.dane_sformatowane = {};
      });
    }
  });

  const {
    akcjonariusze,
    prokurenci,
    wspolnicy,
    organ_uprawniony_do_reprezentowania_spolki,
    organ_nadzoru,
    beneficjenci_rzeczywisci,
    wlasciciel,
    reprezentanci_zglaszajacy,
    pelnomocnicy,
    prokurenci_ceidg,
    zarzadca_sukcesyjny,
    komitet_zalozycielski,
    podmiot_ktory_utworzyl,
  } = relations;

  return (
    <>
      {isDataTruthy(organ_uprawniony_do_reprezentowania_spolki) && (
        <>
          <SubSectionTitle>Organ reprezentacji</SubSectionTitle>
          {organ_uprawniony_do_reprezentowania_spolki.map(
            (
              {
                identyfikator_grupy_podmiotow,
                dane_data_od,
                dane_data_do,
                rola,
                typ,
                dane_sformatowane: { nazwa, numer_krs, numer_regon },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {numer_krs && <LabelValue label="KRS" value={numer_krs} />}
                  {numer_regon && <LabelValue label="REGON" value={numer_regon} />}
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            organ_nadzoru,
            prokurenci,
            wspolnicy,
            akcjonariusze,
            beneficjenci_rzeczywisci,
            wlasciciel,
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(organ_nadzoru) && (
        <>
          <SubSectionTitle>Organ nadzoru</SubSectionTitle>
          {organ_nadzoru.map(
            (
              { identyfikator_grupy_podmiotow, dane_data_od, dane_data_do, rola, typ, dane_sformatowane: { nazwa } },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            prokurenci,
            wspolnicy,
            akcjonariusze,
            beneficjenci_rzeczywisci,
            wlasciciel,
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(prokurenci) && (
        <>
          <SubSectionTitle>Prokurenci</SubSectionTitle>
          {prokurenci.map(
            (
              {
                identyfikator_grupy_podmiotow,
                dane_data_od,
                dane_data_do,
                rola,
                typ,
                dane_sformatowane: { nazwa, rodzaj_prokury },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {rodzaj_prokury && <LabelValue label="Rodzaj prokury" value={rodzaj_prokury} />}
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            wspolnicy,
            akcjonariusze,
            beneficjenci_rzeczywisci,
            wlasciciel,
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(wspolnicy) && (
        <>
          <SubSectionTitle>Wspólnicy</SubSectionTitle>
          {wspolnicy.map(
            (
              {
                identyfikator_grupy_podmiotow,
                dane_data_od,
                dane_data_do,
                typ,
                rola,
                dane_sformatowane: {
                  nazwa,
                  nr_krs,
                  nr_regon,
                  posiadane_przez_wspolnika_udzialy,
                  czy_wspolnik_posiada_calosc_udzialow_spolki,
                },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {nr_krs && <LabelValue label="KRS" value={nr_krs} />}
                  {nr_regon && <LabelValue label="REGON" value={nr_regon} />}
                  {posiadane_przez_wspolnika_udzialy && (
                    <LabelValue label="Udziały" value={posiadane_przez_wspolnika_udzialy} />
                  )}
                  {czy_wspolnik_posiada_calosc_udzialow_spolki && (
                    <LabelValue label="Posiada całość udziałów" value={czy_wspolnik_posiada_calosc_udzialow_spolki} />
                  )}
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            akcjonariusze,
            beneficjenci_rzeczywisci,
            wlasciciel,
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(akcjonariusze) && (
        <>
          <SubSectionTitle>Akcjonariusze</SubSectionTitle>
          {akcjonariusze.map(
            (
              {
                identyfikator_grupy_podmiotow,
                dane_data_od,
                dane_data_do,
                typ,
                dane_sformatowane: { nazwa, numer_krs, czy_wspolnik_posiada_calosc_akcji_spolki },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <Link
                      component={RouterLink}
                      to={getRoutePath(
                        typ === 'Podmiot'
                          ? wpioRoutes.entitiesPersons.entityDetails
                          : wpioRoutes.entitiesPersons.personDetails,
                        identyfikator_grupy_podmiotow
                      )}
                      target="_blank"
                    >
                      {nazwa}
                    </Link>
                  }
                />
                <LabelValueRow>
                  {numer_krs && <LabelValue label="KRS" value={numer_krs} />}
                  {czy_wspolnik_posiada_calosc_akcji_spolki && (
                    <LabelValue label="Posiada całość akcji spółki" value={czy_wspolnik_posiada_calosc_akcji_spolki} />
                  )}
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            beneficjenci_rzeczywisci,
            wlasciciel,
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(beneficjenci_rzeczywisci) && (
        <>
          <SubSectionTitle>Beneficjenci rzeczywiści</SubSectionTitle>
          {beneficjenci_rzeczywisci.map(
            (
              {
                identyfikator_grupy_podmiotow,
                typ,
                rola,
                dane_sformatowane: {
                  nazwa,
                  nr_pesel,
                  kraj_zamieszkania,
                  obywatelstwo,
                  rodzaj_uprawnien_wlascicielskich,
                  rodzaje_uprzywilejowan,
                  kod_uprzywilejowania,
                  rodzaj_uprzywilejowania,
                  ilosc,
                  inne_uprawnienia,
                  uprawnienia_wlascicielskie_posrednie,
                  opis_uprzywilejowania,
                },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {nr_pesel && <LabelValue label="PESEL" value={nr_pesel} />}
                  {kraj_zamieszkania && <LabelValue label="Kraj zamieszkania" value={kraj_zamieszkania} />}
                  {obywatelstwo && <LabelValue label="Obywatelstwo" value={obywatelstwo} />}
                  {isDataTruthy(rodzaje_uprzywilejowan) && (
                    <StyledLabelValue>
                      <StyledLabel>Rodzaj uprzywilejowania</StyledLabel>
                      {rodzaje_uprzywilejowan.map((el, index) => (
                        <div key={index}>
                          {el.kod}, {el.opis}
                        </div>
                      ))}
                    </StyledLabelValue>
                  )}
                  {rodzaj_uprawnien_wlascicielskich && (
                    <LabelValue label="Rodzaj uprawnień właścicielskich" value={rodzaj_uprawnien_wlascicielskich} />
                  )}
                  {(kod_uprzywilejowania || rodzaj_uprzywilejowania) && (
                    <LabelValue
                      label="Rodzaj uprzywilejowania"
                      value={`${kod_uprzywilejowania}, ${rodzaj_uprzywilejowania} `}
                    />
                  )}
                  {ilosc && <LabelValue label="Wielkość udziału" value={ilosc} />}
                  {inne_uprawnienia && <LabelValue label="Inne uprawnienia" value={inne_uprawnienia} />}
                  {uprawnienia_wlascicielskie_posrednie && (
                    <LabelValue
                      style={{ flexBasis: '100%' }}
                      label="Uprawnienia właścicielskie pośrednie"
                      value={uprawnienia_wlascicielskie_posrednie}
                    />
                  )}
                  {opis_uprzywilejowania && (
                    <LabelValue label="Opis uprzywilejowania / Inne informacje" value={opis_uprzywilejowania} />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            wlasciciel,
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(wlasciciel) && (
        <>
          <SubSectionTitle>Właściciel</SubSectionTitle>
          {wlasciciel.map(
            (
              { identyfikator_grupy_podmiotow, typ, rola, dane_data_od, dane_data_do, dane_sformatowane: { nazwa } },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            reprezentanci_zglaszajacy,
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(reprezentanci_zglaszajacy) && (
        <>
          <SubSectionTitle>Reprezentanci/Zgłaszający</SubSectionTitle>
          {reprezentanci_zglaszajacy.map(
            (
              {
                identyfikator_grupy_podmiotow,
                typ,
                rola,
                dane_sformatowane: { nazwa, nr_pesel, kraj_zamieszkania, obywatelstwo, funkcje_zglaszajacego },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {nr_pesel && <LabelValue label="PESEL" value={nr_pesel} />}
                  {kraj_zamieszkania && <LabelValue label="Kraj zamieszkania" value={kraj_zamieszkania} />}
                  {obywatelstwo && <LabelValue label="Obywatelstwo" value={obywatelstwo} />}
                </LabelValueRow>
                <LabelValueRow>
                  {isDataTruthy(funkcje_zglaszajacego) && (
                    <LabelValue
                      label="Funkcje zgłaszającego"
                      value={funkcje_zglaszajacego.map(({ kod, opis }, index) => (
                        <div key={index}>
                          {kod}, {opis}
                        </div>
                      ))}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            pelnomocnicy,
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(pelnomocnicy) && (
        <>
          <SubSectionTitle>Pełnomocnicy</SubSectionTitle>
          {pelnomocnicy.map(
            (
              {
                data_pelnomocnictwa,
                imie,
                nazwisko,
                nazwa_firmy,
                nr_nip,
                nr_pesel,
                adres_korespondencyjny,
                email,
                www,
                zakres_pelnomocnictwa,
                zmiana_wpisu,
                wniosek_o_wykreslenie,
                wznowienie_dzialalnosci,
                zawieszenie_dzialalnosci,
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValueRow>
                  {data_pelnomocnictwa && <LabelValue label="Data pełnomocnictwa" value={data_pelnomocnictwa} />}
                  {imie && <LabelValue label="Imię" value={imie} />}
                  {nazwisko && <LabelValue label="Nazwisko" value={nazwisko} />}
                  {nazwa_firmy && <LabelValue label="Nazwa firmy" value={nazwa_firmy} />}
                  {nr_nip && <LabelValue label="NIP" value={nr_nip} />}
                  {nr_pesel && <LabelValue label="PESEL" value={nr_pesel} />}
                  {adres_korespondencyjny && (
                    <LabelValue label="Adres korespondencyjny" value={adres_korespondencyjny} />
                  )}
                  {email && <LabelValue label="Adres e-mail" value={email} />}
                  {www && (
                    <LabelValue
                      label="Adres WWW"
                      value={
                        <Link href={'//' + www} target="_blank" rel="noopener noreferrer nofollow">
                          {www}
                        </Link>
                      }
                    />
                  )}
                  {zakres_pelnomocnictwa && <LabelValue label="Zakres pełnomocnictwa" value={zakres_pelnomocnictwa} />}
                  {zmiana_wpisu != null && <LabelValue label="Zmiana wpisu" value={zmiana_wpisu ? 'TAK' : 'NIE'} />}
                  {wniosek_o_wykreslenie != null && (
                    <LabelValue label="Wniosek o wykreślenie" value={wniosek_o_wykreslenie ? 'TAK' : 'NIE'} />
                  )}
                  {wznowienie_dzialalnosci != null && (
                    <LabelValue label="Wznowienie działalności" value={wznowienie_dzialalnosci ? 'TAK' : 'NIE'} />
                  )}
                  {zawieszenie_dzialalnosci != null && (
                    <LabelValue label="Zawieszenie działalności" value={zawieszenie_dzialalnosci ? 'TAK' : 'NIE'} />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([
            prokurenci_ceidg,
            zarzadca_sukcesyjny,
            komitet_zalozycielski,
            podmiot_ktory_utworzyl,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(prokurenci_ceidg) && (
        <>
          <SubSectionTitle>Prokurenci</SubSectionTitle>
          {prokurenci_ceidg.map(
            (
              {
                data_prokury,
                imie,
                nazwisko,
                nazwa_firmy,
                nr_nip,
                nr_pesel,
                adres_korespondencyjny,
                email,
                www,
                zakres_prokury,
                zmiana_wpisu,
                wniosek_o_wykreslenie,
                wznowienie_dzialalnosci,
                zawieszenie_dzialalnosci,
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValueRow>
                  {data_prokury && <LabelValue label="Data prokury" value={data_prokury} />}
                  {imie && <LabelValue label="Imię" value={imie} />}
                  {nazwisko && <LabelValue label="Nazwisko" value={nazwisko} />}
                  {nazwa_firmy && <LabelValue label="Nazwa firmy" value={nazwa_firmy} />}
                  {nr_nip && <LabelValue label="NIP" value={nr_nip} />}
                  {nr_pesel && <LabelValue label="PESEL" value={nr_pesel} />}
                  {isDataTruthy(adres_korespondencyjny) && (
                    <LabelValue label="Adres korespondencyjny" value={adres_korespondencyjny} />
                  )}
                  {email && <LabelValue label="Adres e-mail" value={email} />}
                  {www && (
                    <LabelValue
                      label="Adres WWW"
                      value={
                        <Link href={'//' + www} target="_blank" rel="noopener noreferrer nofollow">
                          {www}
                        </Link>
                      }
                    />
                  )}
                  {zakres_prokury && <LabelValue label="Zakres prokury" value={zakres_prokury} />}
                  {zmiana_wpisu != null && <LabelValue label="Zmiana wpisu" value={zmiana_wpisu ? 'TAK' : 'NIE'} />}
                  {wniosek_o_wykreslenie != null && (
                    <LabelValue label="Wniosek o wykreślenie" value={wniosek_o_wykreslenie ? 'TAK' : 'NIE'} />
                  )}
                  {wznowienie_dzialalnosci != null && (
                    <LabelValue label="Wznowienie działalności" value={wznowienie_dzialalnosci ? 'TAK' : 'NIE'} />
                  )}
                  {zawieszenie_dzialalnosci != null && (
                    <LabelValue label="Zawieszenie działalności" value={zawieszenie_dzialalnosci ? 'TAK' : 'NIE'} />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([zarzadca_sukcesyjny, komitet_zalozycielski, podmiot_ktory_utworzyl]) && <Divider />}
        </>
      )}
      {isDataTruthy(zarzadca_sukcesyjny) && (
        <>
          <SubSectionTitle>Zarządca sukcesyjny</SubSectionTitle>
          <LabelValueRow>
            {zarzadca_sukcesyjny.data_ustanowienia && (
              <LabelValue label="Data ustanowienia" value={zarzadca_sukcesyjny.data_ustanowienia} />
            )}
            {zarzadca_sukcesyjny.imie && <LabelValue label="Imię" value={zarzadca_sukcesyjny.imie} />}
            {zarzadca_sukcesyjny.nazwisko && <LabelValue label="Nazwisko" value={zarzadca_sukcesyjny.nazwisko} />}
            {zarzadca_sukcesyjny.nr_nip && <LabelValue label="NIP" value={zarzadca_sukcesyjny.nr_nip} />}
            {zarzadca_sukcesyjny.nr_pesel && <LabelValue label="PESEL" value={zarzadca_sukcesyjny.nr_pesel} />}
          </LabelValueRow>
          {shouldShowDivider([komitet_zalozycielski, podmiot_ktory_utworzyl]) && <Divider />}
        </>
      )}
      {isDataTruthy(komitet_zalozycielski) && (
        <>
          <SubSectionTitle>Komitet założycielski</SubSectionTitle>
          {komitet_zalozycielski.map(
            (
              { identyfikator_grupy_podmiotow, typ, rola, dane_data_od, dane_data_do, dane_sformatowane: { nazwa } },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
          {shouldShowDivider([podmiot_ktory_utworzyl]) && <Divider />}
        </>
      )}
      {isDataTruthy(podmiot_ktory_utworzyl) && (
        <>
          <SubSectionTitle>Podmiot tworzący</SubSectionTitle>
          {podmiot_ktory_utworzyl.map(
            (
              {
                identyfikator_grupy_podmiotow,
                typ,
                rola,
                dane_data_od,
                dane_data_do,
                dane_sformatowane: { nazwa, nr_nip, nr_regon },
              },
              index
            ) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <LabelValue
                  label=""
                  value={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        component={RouterLink}
                        to={getRoutePath(
                          typ === 'Podmiot'
                            ? wpioRoutes.entitiesPersons.entityDetails
                            : wpioRoutes.entitiesPersons.personDetails,
                          identyfikator_grupy_podmiotow
                        )}
                        target="_blank"
                      >
                        {nazwa}
                      </Link>
                      <StyledChip label={rola} />
                    </div>
                  }
                />
                <LabelValueRow>
                  {nr_nip && <LabelValue label="NIP" value={nr_nip} />}
                  {nr_regon && <LabelValue label="REGON" value={nr_regon} />}
                  {dane_data_od && (
                    <LabelValue
                      label="Okres"
                      value={`${dane_data_od} – ${(dane_data_do !== '9999-12-31' && dane_data_do) || 'obecnie'}`}
                    />
                  )}
                </LabelValueRow>
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export default EntitiesRelationsSection;
