import React, { useContext, useState } from 'react';
import { Grid, TextField, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorHandler from 'components/shared/ErrorHandler';
import { Button } from 'components/shared/Button';
import { Dialog } from 'components/shared/Modal';
import CircularLoader from 'components/shared/CircularLoader';
import SkanerContext from 'contexts/shared/SkanerContext';
import UserService from 'services/shared/UserService';
import { skanerTheme } from 'utils/skanerTheme';

const ResetSchema = Yup.object().shape({
  email: Yup.string().email('To nie jest poprawny adres email.').required('Adres email musi być uzupełniony.'),
});

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 370,
    width: '100%',
  },
  container: {
    justifyContent: 'center',
  },
  cardHedaer: {
    background: skanerTheme.palette.primary,
    color: skanerTheme.palette.white,
  },
  cardConfirmation: {
    background: skanerTheme.palette.green,
    color: skanerTheme.palette.white,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  infoText: {
    marginTop: '0px',
  },
  gridItemInputWrapper: {
    width: 'calc(100% - 40px)',
    '& > div': {
      width: '100%',
    },
  },
}));

const ResetPasswordView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModalOptions, setShowConfirmModalOptions] = useState(false);
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
    title: null,
  });

  const { appVersion } = useContext(SkanerContext);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (email, { resetForm }) => {
      try {
        setIsLoading(true);
        await UserService.resetUserPassword(email);
        setShowConfirmModalOptions(true);
        setIsLoading(false);
        resetForm();
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 404) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: error.response.data.detail,
          });
        } else {
          return <ErrorHandler error={error} />;
        }
      }
    },
    validationSchema: ResetSchema,
  });

  return (
    <Grid container spacing={3} className={classes.container}>
      <Card elevation={3} className={classes.card}>
        <CardHeader
          title="Wprowadź swój email"
          titleTypographyProps={{ variant: 'h6' }}
          className={classes.cardHedaer}
        />
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <CardContent>
            <div className={classes.margin}>
              <p className={classes.infoText}>Wyślemy do Ciebie link do zmiany hasła.</p>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <AccountCircle color="primary" titleAccess="użytkownik" aria-label="użytkownik" />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="email"
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    fullWidth
                    autoFocus
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button type="submit" variant="contained" color="primary" style={{ outline: 'none' }}>
              {isLoading ? <CircularLoader /> : 'Resetuj'}
            </Button>
          </CardActions>
        </form>

        <Dialog
          isOpen={showConfirmModalOptions}
          handleClose={() => setShowConfirmModalOptions(false)}
          title="Sprawdź skrzynkę mailową"
          close
          text="Jeżeli podany adres mail istnieje w systemie - wysłano link resetujący hasło."
        />
        <Dialog
          isOpen={errorModalOptions.isOpen}
          handleClose={() => setErrorModalOptions({ isOpen: false, message: null })}
          error
          title={errorModalOptions.title}
          text={errorModalOptions.message}
        />
      </Card>
      <div style={{ position: 'absolute', bottom: 10, right: 20 }}>v.{appVersion}</div>
    </Grid>
  );
};

export default ResetPasswordView;
