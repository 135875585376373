import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import styled from 'styled-components';
import EntitiesContext from 'contexts/wpio/EntitiesContext';
import { SubSectionTitle, TableContainer } from 'components/wpio/EntitiesPersonsShared';
import { skanerTheme } from 'utils/skanerTheme';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
    '& .MuiTableCell-head': {
      fontWeight: 500,
      backgroundColor: skanerTheme.palette.lightgray,
      marginBottom: '16px',
    },
  },
});

const StyledDialogTitle = styled(DialogTitle)`
  background: ${skanerTheme.palette.primary};
  color: ${skanerTheme.palette.white};
`;

const EntitiesDivergenceOfSourcesSection = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { divergenceOfSources } = useContext(EntitiesContext);

  const { dane_ksi, dane_sl, dane_cst, dane_bk14, dane_bk21, dane_krs, dane_ceidg, dane_crbr, dane_vat, dane_regon } =
    divergenceOfSources;

  const classes = useStyles();

  return (
    <>
      <Tooltip title="Informacje identyfikacyjne podmiotu pochodzące ze źródeł SKANER">
        <Info
          style={{ marginTop: 5, marginLeft: 100, cursor: 'pointer', color: '#FFFFFF' }}
          fontSize="large"
          onClick={() => setIsDialogOpen(true)}
        />
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          <span style={{ marginRight: 10 }}>Informacje identyfikacyjne podmiotu pochodzące ze źródeł SKANER</span>
        </StyledDialogTitle>
        <DialogContent>
          <SubSectionTitle>Nazwa podmiotu:</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                  <TableCell rowSpan={2}>REGON</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>CST2021</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colSpan={4}>
                  <TableCell>{dane_ksi?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_sl?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_cst?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_bk14?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_bk21?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_krs?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_ceidg?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_crbr?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_vat?.nazwa || '-'}</TableCell>
                  <TableCell>{dane_regon?.nazwa || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Forma prawna:</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                  <TableCell rowSpan={2}>REGON</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>CST2021</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colSpan={4}>
                  <TableCell>{dane_ksi?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_sl?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_cst?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_bk14?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_bk21?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_krs?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_ceidg?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_crbr?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_vat?.forma_prawna || '-'}</TableCell>
                  <TableCell>{dane_regon?.forma_prawna || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Identyfikator:</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                  <TableCell rowSpan={2}>REGON</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>CST2021</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colSpan={4}>
                  <TableCell>{dane_ksi?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_sl?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_cst?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_bk14?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_bk21?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_krs?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_ceidg?.wlasciciel?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_crbr?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_vat?.nr_nip || '-'}</TableCell>
                  <TableCell>{dane_regon?.nr_nip || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Numer KRS:</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                  <TableCell rowSpan={2}>REGON</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>CST2021</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colSpan={4}>
                  <TableCell>{dane_ksi?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_sl?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_cst?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_bk14?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_bk21?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_krs?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_ceidg?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_crbr?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_vat?.nr_krs || '-'}</TableCell>
                  <TableCell>{dane_regon?.nr_krs || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Numer REGON:</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                  <TableCell rowSpan={2}>REGON</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>CST2021</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colSpan={4}>
                  <TableCell>{dane_ksi?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_sl?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_cst?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_bk14?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_bk21?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_krs?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_ceidg?.wlasciciel.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_crbr?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_vat?.nr_regon || '-'}</TableCell>
                  <TableCell>{dane_regon?.nr_regon || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <SubSectionTitle>Adres podmiotu:</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" className={classes.table} aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>CST</TableCell>
                  <TableCell rowSpan={2}>KRS</TableCell>
                  <TableCell rowSpan={2}>CEIDG</TableCell>
                  <TableCell rowSpan={2}>CRBR</TableCell>
                  <TableCell rowSpan={2}>VAT</TableCell>
                  <TableCell rowSpan={2}>REGON</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>KSI 07-13</TableCell>
                  <TableCell>SL2014</TableCell>
                  <TableCell>CST2021</TableCell>
                  <TableCell>BK14</TableCell>
                  <TableCell>BK21</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow colSpan={4}>
                  <TableCell>{dane_ksi?.adres || '-'}</TableCell>
                  <TableCell>{dane_sl?.adres || '-'}</TableCell>
                  <TableCell>{dane_cst?.adres || '-'}</TableCell>
                  <TableCell>{dane_bk14?.adres || '-'}</TableCell>
                  <TableCell>{dane_bk21?.adres || '-'}</TableCell>
                  <TableCell>{dane_krs?.adres || '-'}</TableCell>
                  <TableCell>{dane_ceidg?.adres || '-'}</TableCell>
                  <TableCell>{dane_crbr?.adres || '-'}</TableCell>
                  <TableCell>{dane_vat?.adres || '-'}</TableCell>
                  <TableCell>{dane_regon?.adres || '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EntitiesDivergenceOfSourcesSection;
