import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SessionExpWarningModal from 'components/skaner/SessionExpWarningModal/SessionExpWarningModal';
import SkanerContext from 'contexts/shared/SkanerContext';
import UserContext from 'contexts/shared/UserContext';
import AccountService from 'services/shared/AccountService';
import { useSessionTimer } from 'hooks/useSessionTimer';
import { skanerRoutes } from 'routes/skanerRoutes';

const SESSION_WARNING_CLOSED_KEY = 'session_warning_closed';

const SessionTimer = () => {
  const [remaining, setRemaining] = useState({});

  const intervalId = useRef(null);
  const sessionExpWarningModalIsVisible = useRef(false);

  const { userTimeSessionInMinutes } = useContext(SkanerContext);
  const { setUser } = useContext(UserContext);

  const history = useHistory();

  const sessionWarningExpChannel = new BroadcastChannel('sessionWarningExp');

  const timeout = userTimeSessionInMinutes * 60 * 1000;

  const { getRemainingTime, reset } = useSessionTimer({
    timeout,
    onAction: e => {
      if (sessionExpWarningModalIsVisible.current) {
        e.preventDefault();
      } else {
        ping();
      }
    },
    onComplete: () => {
      AccountService.logout();
      setUser(null);
      history.push(skanerRoutes.home);
    },
    events: ['click'],
  });

  const ping = () => {
    AccountService.ping().catch(error => {
      if (error.response && error.response.status === 401) setUser(null);
    });
  };

  const hideSessionWarningModalAllTabs = () => {
    sessionWarningExpChannel.postMessage({
      logoutMessage: 'Hide warning modal',
    });
    hideAllTabsSessionWarningExpModal();
  };

  const hideAllTabsSessionWarningExpModal = () => {
    sessionWarningExpChannel.onmessage = () => {
      sessionExpWarningModalIsVisible.current = false;
      localStorage.setItem(SESSION_WARNING_CLOSED_KEY, false);
      sessionWarningExpChannel.close();
    };
  };

  useEffect(() => hideAllTabsSessionWarningExpModal(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ping();
    setRemaining(getRemainingTime());

    intervalId.current = setInterval(() => {
      const { minutes, seconds } = getRemainingTime();
      setRemaining(getRemainingTime());
      if (minutes === 5 && seconds === 0) {
        sessionExpWarningModalIsVisible.current = true;
        localStorage.setItem(SESSION_WARNING_CLOSED_KEY, true);
      }

      if (localStorage.getItem(SESSION_WARNING_CLOSED_KEY) === true && minutes < 5) {
        sessionExpWarningModalIsVisible.current = true;
      }

      if (localStorage.getItem(SESSION_WARNING_CLOSED_KEY) === false) {
        sessionExpWarningModalIsVisible.current = false;
      }
    }, 300);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { minutes, seconds } = remaining;

  return (
    !!minutes &&
    !!seconds && (
      <>
        {minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}
        <SessionExpWarningModal
          isOpen={sessionExpWarningModalIsVisible.current}
          handleConfirm={() => reset()}
          handleClose={() => {
            sessionExpWarningModalIsVisible.current = false;
            localStorage.setItem(SESSION_WARNING_CLOSED_KEY, false);
            hideSessionWarningModalAllTabs();
          }}
        />
      </>
    )
  );
};

export default SessionTimer;
