import React, { useState, useEffect } from 'react';
import { Grid, Card, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { Button, DangerButton } from 'components/shared/Button';
import EntitiesPersonsFiltersInputs from './EntitiesPersonsFiltersInputs';
import EntitiesPersonsAdvancedFiltersInputs from './EntitiesPersonsAdvancedFiltersInputs';
import { TYPE } from 'components/wpio/EntitiesPersonsConsts';

const useStyles = makeStyles(() => ({
  gridContainer: {
    margin: 0,
  },
  accordionContainer: {
    margin: '16px 0',
  },
  buttonContainer: {
    textAlign: 'right',
  },
  dangerButton: {
    marginLeft: 16,
  },
}));

const EntitiesPersonsFilters = ({ setFilters, type = TYPE.ENTITY }) => {
  const [isAdvancedFilterVisible, setIsAdvancedFilterVisible] = useState(false);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      nazwa: '',
      nr_nip: '',
      nr_krs: '',
      nr_regon: '',
      numer_zagraniczny: '',
      imie: '',
      nazwisko: '',
      nr_pesel: '',
      wpisy_aktualne: true,
      wpisy_historyczne: true,
      wojewodztwo: '',
      powiat: '',
      gmina: '',
      miejscowosc: '',
      ulica: '',
      numer_nieruchomosci: '',
      numer_lokalu: '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      const params = Object.entries(values).reduce((total, [key, value]) => {
        if (
          (typeof value === 'string' && value) ||
          (typeof value === 'boolean' && type === TYPE.ENTITY && isAdvancedFilterVisible)
        ) {
          total[key] = value;
        }
        return total;
      }, {});
      const isValid = Object.values(params).some(param => typeof param === 'string');
      setFilters(isValid ? params : {});
    },
  });

  useEffect(() => {
    formik.handleReset();
    setFilters({});
    setIsAdvancedFilterVisible(false);
  }, [type]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card elevation={3}>
          <CardHeader
            title={`Wyszukaj ${type === TYPE.ENTITY ? 'podmioty' : 'osoby'}`}
            titleTypographyProps={{ variant: 'h6' }}
          />
          <form onSubmit={formik.handleSubmit}>
            <Grid container item xs={12} spacing={4} className={classes.gridContainer}>
              <EntitiesPersonsFiltersInputs formik={formik} type={type} />
              <Grid item xs={12} className={classes.accordionContainer}>
                <EntitiesPersonsAdvancedFiltersInputs
                  type={type}
                  formik={formik}
                  isAdvancedFilterVisible={isAdvancedFilterVisible}
                  setIsAdvancedFilterVisible={setIsAdvancedFilterVisible}
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button type="submit" variant="contained" color="primary">
                  Szukaj
                </Button>
                <DangerButton
                  className={classes.dangerButton}
                  variant="outlined"
                  onClick={() => {
                    formik.handleReset();
                    setFilters({});
                  }}
                >
                  Wyczyść filtry
                </DangerButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EntitiesPersonsFilters;
