import React, { useContext } from 'react';
import PersonsIdentificationSection from 'components/wpio/EntitiesPersonsDetails/PersonsIdentificationSection';
import PersonsRestInformationSection from 'components/wpio/EntitiesPersonsDetails/PersonsRestInformationSection';
import PersonsContext from 'contexts/wpio/PersonsContext';

const PersonsDetailsPanel = () => {
  const {
    details: { result },
  } = useContext(PersonsContext);

  return (
    <>
      <PersonsIdentificationSection data={result} />
      <PersonsRestInformationSection />
    </>
  );
};

export default PersonsDetailsPanel;
