import React from 'react';
import styled from 'styled-components';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import CircularLoader from 'components/shared/CircularLoader';
import { Button, DangerButton } from 'components/shared/Button';
import { skanerTheme } from 'utils/skanerTheme';

const StyledDialogTitle = styled(DialogTitle)`
  background: ${({ error }) => (JSON.parse(error) && skanerTheme.palette.secondaryRed) || skanerTheme.palette.primary};
  color: ${skanerTheme.palette.white};
`;
const StyledDialogText = styled(DialogContentText)`
  color: ${skanerTheme.palette.black};
`;

const removeSentinelTabIndex = () => {
  // Workaround to pass wcag compliance for modals:
  document.querySelectorAll('[data-test="sentinelStart"]').forEach(el => el.removeAttribute('tabIndex'));
  document.querySelectorAll('[data-test="sentinelEnd"]').forEach(el => el.removeAttribute('tabIndex'));
};

export const Modal = ({ isOpen, title, handleClose, error = false, children, maxWidth = 'sm' }) => {
  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{
        role: 'presentation',
        onEntered: () => {
          removeSentinelTabIndex();
        },
      }}
      aria-labelledby="alert-dialog-title"
    >
      <StyledDialogTitle id="alert-dialog-title" error={error.toString()}>
        {title}
      </StyledDialogTitle>
      {children}
    </MuiDialog>
  );
};

export const Dialog = ({
  isOpen,
  title,
  text,
  handleClose,
  customCloseText = 'NIE',
  close = false,
  handleConfirm,
  customConfirmText = 'TAK',
  dialog = false,
  error = false,
  isLoading = false,
  custom = null,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  children,
}) => {
  const onClose = (event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') return false;
    if (disableEscapeKeyDown && reason === 'escapeKeyDown') return false;
    if (typeof handleClose === 'function') handleClose();
  };

  return (
    <MuiDialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      TransitionProps={{
        role: 'presentation',
        onEntered: () => {
          removeSentinelTabIndex();
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title" error={error.toString()}>
        {title}
      </StyledDialogTitle>
      <DialogContent>
        {text && <StyledDialogText id="alert-dialog-description">{text}</StyledDialogText>}
        {children}
      </DialogContent>
      <DialogActions>
        {error && (
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        )}
        {close && (
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        )}
        {dialog && (
          <>
            <Button onClick={handleConfirm} disabled={isLoading} variant="contained" color="primary">
              {isLoading ? <CircularLoader /> : customConfirmText}
            </Button>
            <DangerButton style={{ marginLeft: 16 }} disabled={isLoading} variant="outlined" onClick={handleClose}>
              {customCloseText}
            </DangerButton>
          </>
        )}
        {custom && (
          <>
            <Button onClick={handleConfirm} disabled={isLoading} variant="contained" color="primary">
              {isLoading ? <CircularLoader /> : custom.confirmText}
            </Button>
            <DangerButton style={{ marginLeft: 16 }} disabled={isLoading} variant="outlined" onClick={handleClose}>
              {custom.closeText}
            </DangerButton>
          </>
        )}
      </DialogActions>
    </MuiDialog>
  );
};
