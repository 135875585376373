import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3),
    width: '100%',
    marginTop: 48,
  },
}));

const ModuleContent = ({ children }) => {
  const classes = useStyles();

  return <main className={classes.content}>{children}</main>;
};

export default ModuleContent;
