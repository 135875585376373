import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'components/shared/Button';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  fileName: {
    marginLeft: 16,
  },
}));

const FileUploader = ({ buttonText = 'Wybierz plik', fileType, getFileData }) => {
  const [fileName, setFileName] = useState('');

  const classes = useStyles();

  const handleFileUpload = e => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === fileType) {
      setFileName(selectedFile.name);
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => getFileData({ fileName: selectedFile.name, fileDataUrl: reader.result });
      reader.onerror = error => console.error('Wystąpił błąd podczas odczytania pliku: ', error);
    }
  };

  return (
    <div>
      <input
        id="upload-file"
        type="file"
        accept="application/pdf"
        className={classes.input}
        onChange={handleFileUpload}
      />
      <label htmlFor="upload-file">
        <Button component="span" variant="contained" color="primary">
          {buttonText}
        </Button>
      </label>
      {fileName && (
        <Typography variant="caption" className={classes.fileName}>
          {fileName ? fileName : 'Nie wybrano pliku'}
        </Typography>
      )}
    </div>
  );
};

export default FileUploader;
