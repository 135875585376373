import { http } from '../http';

const getEntities = async (filters, zrodloDanych, rozmiarStrony, numerStrony, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty`, {
    cancelToken: request.token,
    params: {
      ...filters,
      zrodloDanych,
      rozmiarStrony,
      numerStrony,
    },
  });
  return response.data;
};

const getEuFunding = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/dofinansowaniaUe`, {
    cancelToken: request.token,
  });
  return response.data;
};

const getGrantee = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/daneGrantobiorcy`, { cancelToken: request.token });
  return response.data;
};

const getPublicProcurement = async (id, request) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/zamowieniaPubliczne`, { cancelToken: request.token });
  return response.data;
};

const getGraph = async (id, level = 1) => {
  const response = await http.get(`/api/wpio/v1/podmioty/${id}/graf?rzad=${level}`);
  return response.data;
};

export default {
  getEntities,
  getEuFunding,
  getGrantee,
  getPublicProcurement,
  getGraph,
};
