import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Card, CardHeader, CardContent, CardActions, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lock from '@material-ui/icons/Lock';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorHandler from 'components/shared/ErrorHandler';
import { Button } from 'components/shared/Button';
import { Dialog } from 'components/shared/Modal';
import CircularLoader from 'components/shared/CircularLoader';
import SkanerContext from 'contexts/shared/SkanerContext';
import UserService from 'services/shared/UserService';
import { skanerRoutes } from 'routes/skanerRoutes';
import { skanerTheme } from 'utils/skanerTheme';

const activateSchema = Yup.object().shape({
  newAccPassword: Yup.string()
    .min(10, 'Hasło jest za krótkie - musi mieć min. 10 znaków.')
    .required('To pole musi być uzupełnione.'),
  confirmAccPassword: Yup.string()
    .required('To pole musi być uzupełnione.')
    .min(10, 'Hasło jest za krótkie - musi mieć min. 10 znaków.')
    .oneOf([Yup.ref('newAccPassword')], 'Hasła nie pasują do siebie.'),
});

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 370,
    width: '100%',
  },
  container: {
    justifyContent: 'center',
  },
  cardHedaer: {
    background: skanerTheme.palette.primary,
    color: skanerTheme.palette.white,
  },
  cardConfirmation: {
    background: skanerTheme.palette.green,
    color: skanerTheme.palette.white,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  infoText: {
    marginTop: '0px',
  },
  gridItemInputWrapper: {
    width: 'calc(100% - 40px)',
    '& > div': {
      width: '100%',
    },
  },
  passwordInfo: {
    marginTop: '5px',
    padding: '10px',
  },
}));

const ActivateAccountView = () => {
  const [showConfirmModalOptions, setShowConfirmModalOptions] = useState(false);
  const [errorModalOptions, setErrorModalOptions] = useState({
    isOpen: false,
    message: null,
    title: null,
    redirectPath: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { appVersion } = useContext(SkanerContext);

  const history = useHistory();

  const { activationCode } = useParams();

  const classes = useStyles();

  useEffect(() => {
    const checkIfCodeIsValid = async () => {
      try {
        const response = await UserService.checkIfActivationCodeIsValid(activationCode);
        if (response.data.expired && response.data.expired === true) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Link aktywacyjny wygasł. Skontaktuj się z administratorem.',
            redirectPath: skanerRoutes.home,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Link aktywacyjny jest niepoprawny. Skontaktuj się z administratorem.',
            redirectPath: skanerRoutes.home,
          });
        } else {
          return <ErrorHandler error={error} />;
        }
      }
    };
    checkIfCodeIsValid();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: {
      newAccPassword: '',
      confirmAccPassword: '',
    },
    onSubmit: async ({ newAccPassword }, { resetForm }) => {
      try {
        setIsLoading(true);
        await UserService.activateAccount({
          password: newAccPassword,
          activationCode: activationCode,
        });
        setIsLoading(false);
        setShowConfirmModalOptions(true);
        resetForm();
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Przekroczono czas aktywacji lub wymogi wobec hasła nie zostały spełnione.',
          });
        } else if (error.response && error.response.status === 404) {
          setErrorModalOptions({
            title: 'Błąd',
            isOpen: true,
            message: 'Link aktywacyjny jest niepoprawny.',
          });
        } else {
          return <ErrorHandler error={error} />;
        }
      }
    },
    validationSchema: activateSchema,
  });

  return (
    <Grid container spacing={3} className={classes.container}>
      <Card elevation={3} className={classes.card}>
        <CardHeader title="Aktywuj konto" titleTypographyProps={{ variant: 'h6' }} className={classes.cardHedaer} />
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <CardContent>
            <div className={classes.margin}>
              <Grid container spacing={1} alignItems="flex-end" className={classes.gridFormRow}>
                <Grid item>
                  <Lock color="primary" titleAccess="hasło" aria-label="hasło" />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="newAccPassword"
                    label="Utwórz hasło"
                    name="newAccPassword"
                    type="password"
                    value={formik.values.newAccPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.errors.newAccPassword && formik.touched.newAccPassword && formik.errors.newAccPassword
                    }
                    error={formik.touched.newAccPassword && Boolean(formik.errors.newAccPassword)}
                    fullWidth
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.gridFormRow}>
                <Grid item>
                  <Lock color="primary" titleAccess="Potwierdź hasło" aria-label="Potwierdź hasło" />
                </Grid>
                <Grid item className={classes.gridItemInputWrapper}>
                  <TextField
                    id="confirmAccPassword"
                    label="Potwierdź hasło"
                    name="confirmAccPassword"
                    type="password"
                    value={formik.values.confirmAccPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.errors.confirmAccPassword &&
                      formik.touched.confirmAccPassword &&
                      formik.errors.confirmAccPassword
                    }
                    error={formik.touched.confirmAccPassword && Boolean(formik.errors.confirmAccPassword)}
                    fullWidth
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button type="submit" variant="contained" color="primary" style={{ outline: 'none' }}>
              {isLoading ? <CircularLoader /> : 'Aktywuj'}
            </Button>
          </CardActions>
          <div className={classes.passwordInfo}>
            <span>
              <strong>Wymogi wobec haseł: </strong>
              <br />
              - Minimalna liczba znaków: 10 <br />
              - Maksymalna liczba znaków: 32 <br />
              - Musi różnić się od 3 poprzednich <br />
              - Przynajmniej jedna wielka oraz jedna mała litera
              <br />
              - Przynajmniej jedna cyfra <br />
              - Przynajmniej jeden znak specjalny <br /> - Nie może zawierać ciągu min. 3 liter z adresu mailowego lub
              imienia i nazwiska
            </span>
          </div>
        </form>
        <Dialog
          isOpen={showConfirmModalOptions}
          handleClose={() => history.push(skanerRoutes.login)}
          title="Sukces"
          close
          text="Konto zostało aktywowane. Naciśnij OK, aby przejść do ekranu logowania."
        />
        <Dialog
          isOpen={errorModalOptions.isOpen}
          handleClose={() => {
            history.push(errorModalOptions.redirectPath);
            setErrorModalOptions({ isOpen: false, message: null });
          }}
          error
          title={errorModalOptions.title}
          text={errorModalOptions.message}
        />
      </Card>
      <div style={{ position: 'absolute', bottom: 10, right: 20 }}>v.{appVersion}</div>
    </Grid>
  );
};

export default ActivateAccountView;
