import { http } from 'services/http';

const getStatute = async fileUri => {
  const response = await http.get(`/api/regulamin/getAttachment?fileUri=${fileUri}`, {
    responseType: 'arraybuffer',
  });
  return response.data;
};

const addStatute = async data => {
  await http.post('/api/regulamin/add', data);
};

const deleteStatute = async id => {
  await http.delete(`/api/regulamin/?id=${id}`);
};

const verifyStatute = async () => {
  const response = await http.get('/api/regulamin/verify');
  return response.data;
};

const acceptStatute = async () => {
  await http.post('/api/regulamin/accept');
};

export default {
  getStatute,
  addStatute,
  deleteStatute,
  verifyStatute,
  acceptStatute,
};
