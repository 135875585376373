import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import EntitiesPersonsList from 'components/wpio/EntitiesPersonsList/EntitiesPersonsList';
import EntitiesPersonsFilters from 'components/wpio/EntitiesPersonsFilters/EntitiesPersonsFilters';

const EntitiesPersonsView = ({ type }) => {
  const [filters, setFilters] = useState({});

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <EntitiesPersonsFilters type={type} setFilters={setFilters} />
      </Grid>
      <Grid item xs={12}>
        <EntitiesPersonsList type={type} filters={filters} />
      </Grid>
    </Grid>
  );
};

export default EntitiesPersonsView;
