import React, { useContext } from 'react';
import EntitiesIndentificationSection from 'components/wpio/EntitiesPersonsDetails/EntitiesIdentificationSection';
import EntitesRestInformationSection from 'components/wpio/EntitiesPersonsDetails/EntitesRestInformationSection';
import EntitiesContext from 'contexts/wpio/EntitiesContext';

const EntitiesDetailsPanel = () => {
  const {
    details: { result },
  } = useContext(EntitiesContext);

  return (
    <>
      <EntitiesIndentificationSection data={result} />
      <EntitesRestInformationSection basicEntityData={result} />
    </>
  );
};

export default EntitiesDetailsPanel;
