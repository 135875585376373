import React from 'react';
import { LabelValue, LabelValueRow } from 'components/wpio/EntitiesPersonsShared';

const PersonsIdentificationSection = ({ data: { data_urodzenia, adres, nr_pesel } }) => {
  return (
    <LabelValueRow>
      {nr_pesel && <LabelValue label="PESEL" value={nr_pesel} />}
      {data_urodzenia && <LabelValue label="Data urodzenia" value={data_urodzenia} />}
      {adres && <LabelValue label="Adres" value={adres} />}
    </LabelValueRow>
  );
};

export default PersonsIdentificationSection;
