import React from 'react';
import { LabelValue } from 'components/wpio/EntitiesPersonsShared';

const EntitiesStatusVatSection = ({ statusVat }) => {
  return (
    <>
      {statusVat.status_podatnika && <LabelValue label="Status podatnika" value={statusVat.status_podatnika} />}
      {statusVat.adres_siedziby && <LabelValue label="Adres siedziby" value={statusVat.adres_siedziby} />}
      {statusVat.adres_dzialalnosci && <LabelValue label="Adres działalności" value={statusVat.adres_dzialalnosci} />}
      {statusVat.data_rejestracji && <LabelValue label="Data rejestracji" value={statusVat.data_rejestracji} />}
      {statusVat.data_odmowy && <LabelValue label="Data odmowy rejestracji" value={statusVat.data_odmowy} />}
      {statusVat.data_wykreslenia && <LabelValue label="Data wykreślenia" value={statusVat.data_wykreslenia} />}
      {statusVat.data_przywrocenia && <LabelValue label="Data przywrócenia" value={statusVat.data_przywrocenia} />}
      {statusVat.data_wznowienia && <LabelValue label="Data wznowienia" value={statusVat.data_wznowienia} />}
      {statusVat.podstawa_prawna_wykreslenia && (
        <LabelValue label="Podstawa prawna wykreślenia" value={statusVat.podstawa_prawna_wykreslenia} />
      )}
      {statusVat.podstawa_prawna_odmowy && (
        <LabelValue label="Podstawa prawna odmowy" value={statusVat.podstawa_prawna_odmowy} />
      )}
      {statusVat.podstawa_prawna_przywrocenia && (
        <LabelValue label="Podstawa prawna przywrócenia" value={statusVat.podstawa_prawna_przywrocenia} />
      )}
    </>
  );
};

export default EntitiesStatusVatSection;
