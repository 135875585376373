import React, { useState } from 'react';
import ErrorHandler from 'components/shared/ErrorHandler';
import { Dialog } from 'components/shared/Modal';
import AccountService from 'services/shared/AccountService';

const SessionExpWarningModal = ({ isOpen, handleConfirm, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = async () => {
    setIsLoading(true);
    try {
      await AccountService.resetSession();
      setIsLoading(false);
      handleClose();
      handleConfirm();
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={handleOk}
      isLoading={isLoading}
      disableBackdropClick
      disableEscapeKeyDown
      title="Ostrzeżenie"
      text={'Twoja sesja wkrótce wygaśnie. Naciśnij OK, aby przedłużyć sesję.'}
      custom={{ confirmText: 'OK', closeText: 'Anuluj' }}
    />
  );
};

export default SessionExpWarningModal;
