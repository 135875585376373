import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import SkanerContext from 'contexts/shared/SkanerContext';
import UserContext from 'contexts/shared/UserContext';
import StatuteService from 'services/shared/StatuteService';
import { skanerRoutes } from 'routes/skanerRoutes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}));

const WrapperTemplate = ({ children }) => {
  const { setStatuteUri } = useContext(SkanerContext);
  const { user, isStatuteAccepted, setIsStatuteAccepted } = useContext(UserContext);

  const history = useHistory();

  const location = useLocation();

  const classes = useStyles();

  useEffect(() => {
    const checkIsStatuteAccepted = async () => {
      if (isStatuteAccepted) return;
      try {
        const { czy_zaakceptowano_regulamin, uri } = await StatuteService.verifyStatute();
        setIsStatuteAccepted(czy_zaakceptowano_regulamin);
        setStatuteUri(uri);
        if (!czy_zaakceptowano_regulamin && location.pathname !== skanerRoutes.statute.accept) {
          history.push(skanerRoutes.statute.accept);
        }
      } catch (error) {
        setIsStatuteAccepted(false);
        if (
          user &&
          ![skanerRoutes.home, skanerRoutes.login, ...Object.values(skanerRoutes.errors)].includes(location.pathname)
        ) {
          history.push(skanerRoutes.errors[500]);
        }
      }
    };
    if (
      user &&
      !isStatuteAccepted &&
      ![skanerRoutes.login, skanerRoutes.statute.accept, ...Object.values(skanerRoutes.errors)].includes(
        location.pathname
      )
    ) {
      checkIsStatuteAccepted();
    }
  }, [user, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <CssBaseline />
      {children}
    </div>
  );
};

export default WrapperTemplate;
