import React, { useEffect, useState } from 'react';
import { logoutAfterPasswordChangeAllTabsEventListener } from 'views/skaner/User/SetNewPasswordView';
import { logoutAllTabsEventListener } from 'components/skaner/Layout/TopBar';
import AccountService from 'services/shared/AccountService';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isStatuteAccepted, setIsStatuteAccepted] = useState(false);

  const enrichUserInfo = userInfo => {
    return {
      ...userInfo,
      roles: userInfo.role && userInfo.role.split(','),
      isLoggedIn: true,
    };
  };

  useEffect(() => logoutAllTabsEventListener(), []);

  useEffect(() => logoutAfterPasswordChangeAllTabsEventListener(), []);

  useEffect(() => {
    const shouldLogout = localStorage.getItem('resetUser');
    if (shouldLogout) {
      setUser(null);
      localStorage.removeItem('resetUser');
      setIsLoading(false);
    } else {
      const getUserInfo = async () => {
        try {
          const userInfo = await AccountService.getUserInfo();
          if (userInfo !== null) setUser(enrichUserInfo(userInfo));
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          setError(error);
        }
      };
      getUserInfo();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ user, setUser, isLoading, error, enrichUserInfo, isStatuteAccepted, setIsStatuteAccepted }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserProvider };
