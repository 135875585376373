import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from 'components/shared/Modal';
import { TYPE } from 'components/wpio/EntitiesPersonsConsts';
import { skanerTheme } from 'utils/skanerTheme';

const useStyles = makeStyles(() => ({
  accordion: {
    background: skanerTheme.palette.lightgray,
    border: `1px solid ${skanerTheme.palette.tab}`,
  },
  titleHeader: {
    fontSize: 18,
    fontWeight: 500,
  },
  gridContainer: {
    margin: 0,
  },
}));

const EntitiesPersonsAdvancedFiltersInputs = ({
  type,
  formik,
  isAdvancedFilterVisible,
  setIsAdvancedFilterVisible,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const classes = useStyles();

  const inputs = [
    ...(type === TYPE.ENTITY
      ? [
          {
            name: 'wpisy_aktualne',
            label: 'Wpisy aktualne',
            type: 'checkbox',
          },
          {
            name: 'wpisy_historyczne',
            label: 'Wpisy historyczne',
            type: 'checkbox',
          },
        ]
      : []),
    {
      name: 'wojewodztwo',
      label: 'Województwo',
      type: 'text',
      maxLength: 100,
    },
    {
      name: 'powiat',
      label: 'Powiat',
      type: 'text',
      maxLength: 100,
    },
    {
      name: 'gmina',
      label: 'Gmina',
      type: 'text',
      maxLength: 100,
    },
    {
      name: 'miejscowosc',
      label: 'Miejscowość',
      type: 'text',
      maxLength: 100,
    },
    {
      name: 'ulica',
      label: 'Ulica',
      type: 'text',
      maxLength: 100,
    },
    {
      name: 'numer_nieruchomosci',
      label: 'Numer nieruchomości',
      type: 'text',
      maxLength: 100,
    },
    {
      name: 'numer_lokalu',
      label: 'Numer lokalu',
      type: 'text',
      maxLength: 100,
    },
  ];
  const advancedFilterNames = inputs.map(input => input.name);

  const handleAccordionChange = () => {
    const params = Object.entries(formik.values).reduce((total, [key, value]) => {
      if (typeof value === 'string' && value && advancedFilterNames.includes(key)) {
        total[key] = value;
      }
      return total;
    }, {});
    const isFormFilled = Object.values(params).some(param => param);
    if (!isFormFilled && isAdvancedFilterVisible) {
      setIsAdvancedFilterVisible(false);
      inputs.forEach(input => formik.setFieldValue(input.name, formik.initialValues[input.name]));
    } else if (isAdvancedFilterVisible) {
      setIsDialogOpen(true);
    } else {
      setIsAdvancedFilterVisible(true);
    }
  };

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    if (!checked && (!formik.values.wpisy_aktualne || !formik.values.wpisy_historyczne)) return;
    formik.setFieldValue(name, checked);
  };

  const handleDialogConfirm = () => {
    setIsAdvancedFilterVisible(false);
    setIsDialogOpen(false);
    inputs.forEach(input => formik.setFieldValue(input.name, formik.initialValues[input.name]));
  };

  const handleDialogClose = () => setIsDialogOpen(false);

  return (
    <>
      <Accordion expanded={isAdvancedFilterVisible} className={classes.accordion} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.titleHeader}>Filtry zaawansowane</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12} spacing={4} className={classes.gridContainer}>
            {inputs.map(input => (
              <Grid key={input.name} item xs={12} md={input.type === 'text' && 6}>
                {input.type === 'checkbox' ? (
                  <FormControlLabel
                    label={input.label}
                    control={
                      <Checkbox
                        id={input.name}
                        name={input.name}
                        checked={formik.values[input.name]}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                  />
                ) : (
                  <TextField
                    id={input.name}
                    label={input.label}
                    name={input.name}
                    value={formik.values[input.name]}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: input.maxLength }}
                    fullWidth
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        isOpen={isDialogOpen}
        title="Ostrzeżenie"
        text="Po zamknięciu panelu filtry zaawansowane zostaną wyczyszczone. Czy chcesz kontynuować?"
        handleConfirm={handleDialogConfirm}
        handleClose={handleDialogClose}
        dialog
      />
    </>
  );
};

export default EntitiesPersonsAdvancedFiltersInputs;
