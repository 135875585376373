import React from 'react';
import { Divider, LabelValue, LabelValueRow } from 'components/wpio/EntitiesPersonsShared';

const EntitiesIndentificationSection = ({ data: { nr_krs, nr_nip, nr_regon, adres } }) => {
  return (
    <>
      <LabelValueRow>
        {nr_nip && <LabelValue label="Identyfikator" value={nr_nip} />}
        {nr_krs && <LabelValue label="KRS" value={nr_krs} />}
        {nr_regon && <LabelValue label="REGON" value={nr_regon} />}
      </LabelValueRow>
      {adres && (
        <>
          <Divider />
          <LabelValue label="Adres" value={adres} />
        </>
      )}
    </>
  );
};

export default EntitiesIndentificationSection;
