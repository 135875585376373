import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Table, TableHead, TableBody, TableRow, TableCell, Link } from '@material-ui/core';
import Pagination from 'components/shared/Pagination';
import { Button } from 'components/shared/Button';
import ActionTableCell from 'components/shared/ActionTableCell';
import { getEntityPersonValue } from 'components/wpio/EntitiesPersonsUtils';
import { wpioRoutes } from 'routes/wpioRoutes';
import { getRoutePath } from 'utils/utils';

const PersonsTable = ({ data, pageNumber, pageSize, applyPaging, limitedRowsPerPage }) => {
  return (
    <>
      <Table aria-label="Tabela osób">
        <TableHead>
          <TableRow className="table-head-row">
            <TableCell>PESEL</TableCell>
            <TableCell>Nazwisko</TableCell>
            <TableCell>Imiona</TableCell>
            <ActionTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.lista_osob &&
            [...data.lista_osob]
              .sort((a, b) => (!a.nr_pesel ? 1 : !b.nr_pesel ? -1 : a.nr_pesel.localeCompare(b.nr_pesel)))
              .map(d => (
                <TableRow key={d.identyfikator_grupy_podmiotow}>
                  <TableCell component="th" scope="row">
                    {getEntityPersonValue(d.nr_pesel)}
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={getRoutePath(wpioRoutes.entitiesPersons.personDetails, d.identyfikator_grupy_podmiotow)}
                      target="_blank"
                    >
                      {getEntityPersonValue(d.nazwisko)}
                    </Link>
                  </TableCell>
                  <TableCell>{getEntityPersonValue(d.imiona)}</TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    <Button
                      component={RouterLink}
                      target="_blank"
                      variant="contained"
                      to={getRoutePath(wpioRoutes.entitiesPersons.personDetails, d.identyfikator_grupy_podmiotow)}
                      color="primary"
                    >
                      Szczegóły
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <Pagination
        count={data.liczba_osob || 0}
        page={pageNumber}
        pageSize={pageSize}
        rowsPerPageOptions={limitedRowsPerPage ? [10, 25] : [10, 25, 50]}
        handleChangePage={page => {
          applyPaging({ pageSize, pageNumber: page });
        }}
        handleChangeRowsPerPage={event => {
          applyPaging({
            pageNumber: 1,
            pageSize: parseInt(event.target.value, 10),
          });
        }}
        isPreciseCount={Boolean(data.czy_liczba_dokladna)}
      />
    </>
  );
};

export default PersonsTable;
