import React, { useState, useRef } from 'react';
import { Card, Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'components/shared/Button';
import CircularLoader from 'components/shared/CircularLoader';
import SamplerService from 'services/sampler/SamplerService';
import { skanerTheme } from 'utils/skanerTheme';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const Wrapper = styled.div`
  padding: 16px;
`;

const FileUpload = ({ refreshSamplersList }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleUpload = () => {
    if (!file) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    SamplerService.sendFile(formData);
    setTimeout(() => {
      refreshSamplersList();
      setFile(null);
      setFileName('');
      setIsLoading(false);
      fileInputRef.current.value = '';
    }, 5000);
  };

  return (
    <>
      <h1>Przesyłanie pliku</h1>
      <Card elevation={3}>
        <Wrapper>
          <div style={{ marginBottom: 16 }}>
            <b>Wybrany plik:</b> {fileName ? fileName : 'wybierz plik'}
          </div>
          <MuiButton component="label" role={undefined} variant="contained" tabIndex={-1} style={{ marginRight: 16 }}>
            Wybierz plik
            <VisuallyHiddenInput ref={fileInputRef} type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
          </MuiButton>
          <Button color="primary" variant="contained" onClick={handleUpload} disabled={!fileName.length || isLoading}>
            {isLoading ? (
              <>
                <span style={{ marginRight: 10 }}>Trwa przesyłanie pliku...</span>
                <CircularLoader svgcolor={skanerTheme.palette.disabled} />
              </>
            ) : (
              'Prześlij plik'
            )}
          </Button>
        </Wrapper>
      </Card>
    </>
  );
};

export default FileUpload;
