import { http } from 'services/http';

const addUser = async data => {
  await http.post('/api/users', data);
};

const editUser = async data => {
  await http.patch('/api/users', data);
};

const editUserPassword = async data => {
  await http.patch('/api/users/editUserPassword', data);
};

const deleteUser = async id => {
  await http.delete(`/api/users/${id}`);
};

const reactivateUser = async data => {
  await http.patch('/api/users/reactivate', data);
};

export default {
  addUser,
  editUser,
  editUserPassword,
  deleteUser,
  reactivateUser,
};
