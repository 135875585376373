import React from 'react';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
} from 'components/wpio/EntitiesPersonsShared';
import { isDataTruthy } from 'utils/utils';

const EntitiesUnitsSection = ({ units, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  const { dane_krs, dane_ceidg } = units;

  return (
    <>
      {isDataTruthy(dane_krs) && (
        <>
          <SubSectionTitle>Oddziały</SubSectionTitle>
          {dane_krs.map(({ firma_oddzialu, siedziba, adres }, index) => (
            <div key={index}>
              {firma_oddzialu && <LabelValue label="Firma oddziału" value={firma_oddzialu} />}
              {siedziba && <LabelValue label="Siedziba" value={siedziba} />}
              {adres && <LabelValue label="Adres" value={adres} />}
              {index !== dane_krs.length - 1 && <Divider />}
            </div>
          ))}
        </>
      )}
      {isDataTruthy(dane_ceidg?.adresy_dzialalnosci_dodatkowe) && (
        <>
          <SubSectionTitle>Adresy dodatkowe</SubSectionTitle>
          {dane_ceidg.adresy_dzialalnosci_dodatkowe.map(
            ({ kraj, wojewodztwo, powiat, gmina, miasto, kod, ulica, budynek, lokal }, index) => (
              <div key={index}>
                <LabelValueRow>
                  {kraj && <LabelValue label="Kraj" value={kraj} />}
                  {wojewodztwo && <LabelValue label="Województwo" value={wojewodztwo} />}
                  {powiat && <LabelValue label="Powiat" value={powiat} />}
                  {gmina && <LabelValue label="Gmina" value={gmina} />}
                  {miasto && <LabelValue label="Miasto" value={miasto} />}
                  {kod && <LabelValue label="Kod" value={kod} />}
                  {ulica && <LabelValue label="Ulica" value={ulica} />}
                  {budynek && <LabelValue label="Budynek" value={budynek} />}
                  {lokal && <LabelValue label="Lokal" value={lokal} />}
                </LabelValueRow>
                {index !== dane_ceidg.adresy_dzialalnosci_dodatkowe.length - 1 && <Divider />}
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export default EntitiesUnitsSection;
