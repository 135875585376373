import React, { useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Card,
  CardHeader,
  SvgIcon,
} from '@material-ui/core';
import ActionTableCell from 'components/shared/ActionTableCell';
import { Button, DangerButton } from 'components/shared/Button';
import { Dialog } from 'components/shared/Modal';
import { useApi } from 'hooks/useApi';
import StatuteService from 'services/shared/StatuteService';
import { skanerRoutes } from 'routes/skanerRoutes';
import { getRoutePath } from 'utils/utils';

const StatuteVersions = () => {
  const [deleteDialogOptions, setDeleteDialogOptions] = useState({
    isOpen: false,
    isLoading: false,
    statuteId: null,
  });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const history = useHistory();

  const { result, isLoading, error, refresh } = useApi('/api/regulamin/');

  const handleDeleteDialogOptions = options => setDeleteDialogOptions(prev => ({ ...prev, ...options }));

  const handleDeleteDialogConfirm = async () => {
    try {
      handleDeleteDialogOptions({ isLoading: true });
      await StatuteService.deleteStatute(deleteDialogOptions.statuteId);
      handleDeleteDialogOptions({ isOpen: false });
      setIsConfirmDialogOpen(true);
    } catch (error) {
      console.error('Wystąpił błąd podczas usuwania regulaminu: ', error);
    } finally {
      handleDeleteDialogOptions({ isLoading: false, statuteId: null });
    }
  };

  const handleDeleteDialogClose = () => handleDeleteDialogOptions({ isOpen: false, statuteId: null });

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
    refresh();
  };

  if (error) return <ErrorHandler error={error} />;
  if (isLoading || !result) return <Loader />;

  return (
    <>
      <TableContainer component={Card} elevation={3}>
        <CardHeader
          title="Lista wersji regulaminu"
          titleTypographyProps={{ variant: 'h6' }}
          action={
            <Button
              type="button"
              variant="contained"
              color="primary"
              startIcon={
                <SvgIcon>
                  <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                </SvgIcon>
              }
              className="card-header-btn"
              onClick={() => history.push(skanerRoutes.statute.add)}
            >
              Dodaj wersję regulaminu
            </Button>
          }
        />
        <Table aria-label="tabela listy wersji regulaminu">
          <TableHead>
            <TableRow className="table-head-row">
              <TableCell>Lp.</TableCell>
              <TableCell style={{ minWidth: 200 }}>Nazwa wersji</TableCell>
              <TableCell style={{ minWidth: 200 }}>Użytkownik wprowadząjacy</TableCell>
              <TableCell style={{ minWidth: 150 }}>Data dodania</TableCell>
              <TableCell style={{ minWidth: 150 }}>Obowiązuje od</TableCell>
              <ActionTableCell style={{ minWidth: 400 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {result.map((statute, index) => (
              <TableRow key={statute.id_regulamin}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{statute.nazwa_wersji}</TableCell>
                <TableCell>{statute.kto_utworzyl}</TableCell>
                <TableCell>{statute.at_data_utworzenia.split('T')[0]}</TableCell>
                <TableCell>{statute.at_data_obowiazywania.split('T')[0]}</TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                  <Button
                    component={RouterLink}
                    variant="contained"
                    color="primary"
                    to={getRoutePath(
                      skanerRoutes.statute.preview,
                      encodeURIComponent(JSON.stringify({ uri: statute.uri, name: statute.nazwa_wersji }))
                    )}
                    target="_blank"
                  >
                    Podgląd
                  </Button>
                  <DangerButton
                    variant="outlined"
                    style={{ marginLeft: 16 }}
                    disabled={index === 0 ? true : false}
                    onClick={() => handleDeleteDialogOptions({ isOpen: true, statuteId: statute.id_regulamin })}
                  >
                    Usuń
                  </DangerButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        isOpen={deleteDialogOptions.isOpen}
        title="Ostrzeżenie"
        text="Czy na pewno chcesz usunąć wybraną wersję regulaminu?"
        dialog
        handleConfirm={handleDeleteDialogConfirm}
        handleClose={handleDeleteDialogClose}
      />
      <Dialog
        isOpen={isConfirmDialogOpen}
        title="Sukces"
        text="Wybrana wersja regulaminu została usunięta."
        close
        handleClose={handleConfirmDialogClose}
      />
    </>
  );
};

export default StatuteVersions;
