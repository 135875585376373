import React, { useContext } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import PdfViewer from 'components/shared/PdfViewer';
import SkanerContext from 'contexts/shared/SkanerContext';
import useStatuteFile from 'hooks/useStatuteFile';

const Statute = () => {
  const { statuteUri } = useContext(SkanerContext);

  const { pdf, isLoading, error } = useStatuteFile(statuteUri);

  if (error) return <ErrorHandler error={error} />;
  if (isLoading || !pdf) return <Loader />;

  return (
    <Card elevation={3} style={{ paddingBottom: 16 }}>
      <CardHeader title="Regulamin" titleTypographyProps={{ variant: 'h6' }} />
      <PdfViewer pdf={pdf} />
    </Card>
  );
};

export default Statute;
