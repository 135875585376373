import React from 'react';
import {
  TableContainer as MuiTableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  SubSectionTitle,
  TableContainer,
} from 'components/wpio/EntitiesPersonsShared';
import { shouldShowDivider } from 'components/wpio/EntitiesPersonsUtils';
import { formatNumber, isDataTruthy } from 'utils/utils';

const EntitiesFinancesSection = ({ finances, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  const { dane_krs } = finances;

  return (
    <>
      {isDataTruthy(dane_krs?.kapital) && (
        <>
          <SubSectionTitle>Kapitał</SubSectionTitle>
          {dane_krs.kapital.wysokosc_kapitalu_zakladowego && (
            <LabelValue label="Wysokość kapitału zakładowego" value={dane_krs.kapital.wysokosc_kapitalu_zakladowego} />
          )}
          {dane_krs.kapital.wysokosc_kapitalu_docelowego && (
            <LabelValue label="Wysokość kapitału docelowego" value={dane_krs.kapital.wysokosc_kapitalu_docelowego} />
          )}
          {isDataTruthy(dane_krs?.kapital.wartosci_akcji_objetych_za_aport) && (
            <LabelValue
              label="Wartości udziałów objętych za aport"
              multi
              value={dane_krs.kapital.wartosci_akcji_objetych_za_aport}
            />
          )}
          {dane_krs.kapital.kwotowe_okreslenie_czesci_kapitalu_wplaconego && (
            <LabelValue
              label="Kwotowe określenie części kapitału wpłaconego"
              value={dane_krs.kapital.kwotowe_okreslenie_czesci_kapitalu_wplaconego}
            />
          )}
          {dane_krs.kapital.wartosc_nominalna_akcji != null && (
            <LabelValue
              label="Wartość nominalna akcji"
              value={formatNumber(parseFloat(dane_krs.kapital.wartosc_nominalna_akcji))}
            />
          )}
          {dane_krs.kapital.wartosc_nominalna_warunkowego_podwyzszenia_kapitalu_zakladowego != null && (
            <LabelValue
              label="Wartość nominalna warunkowego podwyższenia kapitału zakładowego"
              value={formatNumber(
                parseFloat(dane_krs.kapital.wartosc_nominalna_warunkowego_podwyzszenia_kapitalu_zakladowego)
              )}
            />
          )}
          {dane_krs.kapital.liczba_akcji_wszystkich_emisji && (
            <LabelValue
              label="Liczba akcji wszystkich emisji"
              value={dane_krs.kapital.liczba_akcji_wszystkich_emisji}
            />
          )}
          {shouldShowDivider([
            dane_krs?.kapital?.emisje_akcji,
            dane_krs?.zaleglosci,
            dane_krs?.wierzytelnosci,
            dane_krs?.sprawozdanie_finansowe,
          ]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_krs?.kapital?.emisje_akcji) && (
        <>
          <SubSectionTitle>Emisje akcji</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela emisji akcji">
              <TableHead>
                <TableRow>
                  <TableCell>Nazwa serii akcji</TableCell>
                  <TableCell>Liczba akcji</TableCell>
                  <TableCell>Rodzaj uprzywilejowania</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.kapital.emisje_akcji.map(
                  ({ nazwa_serii_akcji, liczba_akcji_w_danej_serii, rodzaj_uprzywilejowania }, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {nazwa_serii_akcji}
                      </TableCell>
                      <TableCell>{liczba_akcji_w_danej_serii}</TableCell>
                      <TableCell>{rodzaj_uprzywilejowania}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {shouldShowDivider([dane_krs?.zaleglosci, dane_krs?.wierzytelnosci, dane_krs?.sprawozdanie_finansowe]) && (
            <Divider />
          )}
        </>
      )}
      {isDataTruthy(dane_krs?.zaleglosci) && (
        <>
          <SubSectionTitle>Zaległości</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela zaległości">
              <TableHead>
                <TableRow>
                  <TableCell>Charakter zaległości</TableCell>
                  <TableCell>Organ wystawiający</TableCell>
                  <TableCell align="right">Wysokość zaległości</TableCell>
                  <TableCell>Informacja o zakończeniu egzekucji</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.zaleglosci.map(
                  ({ charakter_zaleglosci, organ_wystawiajacy, wysokosc_zaleglosci, zakonczenie_egzekucji }, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {charakter_zaleglosci}
                      </TableCell>
                      <TableCell>{organ_wystawiajacy}</TableCell>
                      <TableCell align="right">{wysokosc_zaleglosci}</TableCell>
                      <TableCell>{zakonczenie_egzekucji}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {shouldShowDivider([dane_krs?.wierzytelnosci, dane_krs?.sprawozdanie_finansowe]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_krs?.wierzytelnosci) && (
        <>
          <SubSectionTitle>Wierzytelności</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela wierzytelności">
              <TableHead>
                <TableRow>
                  <TableCell>Tytuł wykonawczy</TableCell>
                  <TableCell>Kwota lub opis</TableCell>
                  <TableCell>Czy solidarna?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.wierzytelnosci.map((row, index) => (
                  <CreditorsTableRow key={index} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {shouldShowDivider([dane_krs?.sprawozdanie_finansowe]) && <Divider />}
        </>
      )}
      {isDataTruthy(dane_krs?.sprawozdanie_finansowe) && (
        <>
          <SubSectionTitle>Sprawozdania finansowe</SubSectionTitle>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela sprawozdań finansowych">
              <TableHead>
                <TableRow>
                  <TableCell>Data złożenia</TableCell>
                  <TableCell>Za okres</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dane_krs.sprawozdanie_finansowe.map(({ data_zlozenia, za_okres }, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {data_zlozenia}
                    </TableCell>
                    <TableCell>{za_okres}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

const CreditorsTableRow = ({
  row: { tytul_wykonawczy, kwota_wierzytelnosci, czy_wierzytelnosc_jest_solidarna, lista_osob },
}) => {
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row" style={{ borderBottom: 0, width: '33%' }}>
          {tytul_wykonawczy}
        </TableCell>
        <TableCell style={{ borderBottom: 0, width: '33%' }}>{kwota_wierzytelnosci}</TableCell>
        <TableCell style={{ borderBottom: 0, width: '33%' }}>{czy_wierzytelnosc_jest_solidarna}</TableCell>
      </TableRow>
      {isDataTruthy(lista_osob) && (
        <TableRow>
          <TableCell colSpan={3} style={{ padding: 0 }}>
            <MuiTableContainer component="div" style={{ background: 'rgba(0, 0, 0, 0.05)' }}>
              <Table size="small" aria-label="tabela wierzycieli">
                <TableHead>
                  <TableRow>
                    <TableCell>Wierzyciele</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lista_osob.map(({ nazwa, imiona, numer_regon, numer_krs }, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" style={{ borderBottom: 0, width: '33%' }}>
                        {`${imiona} ${nazwa}`}
                      </TableCell>
                      <TableCell style={{ borderBottom: 0, width: '33%' }}>
                        {numer_regon && `REGON: ${numer_regon}`}
                      </TableCell>
                      <TableCell style={{ borderBottom: 0 }}>{numer_krs && `KRS: ${numer_krs}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </MuiTableContainer>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default EntitiesFinancesSection;
