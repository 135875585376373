import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader } from '@material-ui/core';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import PdfViewer from 'components/shared/PdfViewer';
import useStatuteFile from 'hooks/useStatuteFile';

const PreviewStatute = () => {
  const { id: encodedData } = useParams();
  const decodedData = decodeURIComponent(encodedData);
  const { name, uri } = JSON.parse(decodedData);

  const { pdf, isLoading, error } = useStatuteFile(uri);

  if (error) return <ErrorHandler error={error} />;
  if (isLoading || !pdf) return <Loader />;

  return (
    <Card elevation={3} style={{ paddingBottom: 16 }}>
      <CardHeader title={name} titleTypographyProps={{ variant: 'h6' }} />
      <PdfViewer pdf={pdf} />
    </Card>
  );
};

export default PreviewStatute;
