import React, { useState } from 'react';
import { DialogContent, DialogActions } from '@material-ui/core';
import {
  Divider,
  ErrorMessage,
  LabelValue,
  LabelValueRow,
  SubSectionTitle,
} from 'components/wpio/EntitiesPersonsShared';
import { Button, DangerButton } from 'components/shared/Button';
import { Modal } from 'components/shared/Modal';
import { isDataTruthy } from 'utils/utils';

const PersonsProjectPersonnelSection = ({ projectPersonnel, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  const { personel_projektu_sl } = projectPersonnel;

  const moreThan3 = data => data && data.length > 0 && data.length > 3;

  const ShowAll = ({ projectPersonnel, renderComponent, partialTitle }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
      <>
        <Button onClick={() => setModalIsOpen(true)} variant="contained" color="primary">
          Pokaż wszystkie ({projectPersonnel.length})
        </Button>
        <Modal
          isOpen={modalIsOpen}
          title={`Personel projektu – ${partialTitle}`}
          handleClose={() => setModalIsOpen(false)}
          maxWidth="lg"
        >
          <DialogContent>
            {projectPersonnel.map((item, index) => (
              <React.Fragment key={index}>
                {renderComponent({
                  item,
                  index,
                  length: projectPersonnel.length,
                })}
              </React.Fragment>
            ))}
          </DialogContent>
          <DialogActions>
            <DangerButton style={{ marginLeft: 16 }} variant="outlined" onClick={() => setModalIsOpen(false)}>
              Zamknij
            </DangerButton>
          </DialogActions>
        </Modal>
      </>
    );
  };

  const ProjectPersonnelItem = ({ item, index, length }) => {
    const {
      numer_projektu,
      tytul_projektu,
      nazwa_beneficjenta,
      identyfikator_beneficjenta,
      forma_zaangazowania,
      zaangazowanie_od_do,
    } = item;

    return (
      <>
        <LabelValueRow>
          {numer_projektu && <LabelValue label="Numer projektu" value={numer_projektu} />}
          {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        </LabelValueRow>
        <LabelValueRow>
          {nazwa_beneficjenta && <LabelValue label="Nazwa Beneficjenta" value={nazwa_beneficjenta} />}
          {identyfikator_beneficjenta && (
            <LabelValue label="Identyfikator Beneficjenta" value={identyfikator_beneficjenta} />
          )}
        </LabelValueRow>
        <LabelValueRow>
          {forma_zaangazowania && <LabelValue label="Forma zaangażowania" value={forma_zaangazowania} />}
          {zaangazowanie_od_do && <LabelValue label="Okres zaangażowania" value={zaangazowanie_od_do} />}
        </LabelValueRow>
        {index !== length - 1 && <Divider />}
      </>
    );
  };

  const ProjectPersonnelSubsection = ({ data, partialTitle }) => (
    <>
      {isDataTruthy(data) && (
        <div>
          <SubSectionTitle>Personel projektu – {partialTitle}</SubSectionTitle>
          {(moreThan3(data) ? data.slice(0, 3) : data).map((item, index) => (
            <ProjectPersonnelItem key={index} item={item} index={index} length={data.length} />
          ))}
          {moreThan3(data) && (
            <ShowAll
              partialTitle={partialTitle}
              projectPersonnel={data}
              renderComponent={props => <ProjectPersonnelItem {...props} />}
            />
          )}
        </div>
      )}
    </>
  );

  return <ProjectPersonnelSubsection data={personel_projektu_sl} partialTitle="SL2014" />;
};

export default PersonsProjectPersonnelSection;
