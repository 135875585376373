import React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import EntitiesPersonsDetails from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsDetails';
import { getTypeByLocation } from 'components/wpio/EntitiesPersonsUtils';
import { TYPE } from 'components/wpio/EntitiesPersonsConsts';
import { EntitiesContextProvider } from 'contexts/wpio/EntitiesContext';
import { PersonsContextProvider } from 'contexts/wpio/PersonsContext';

const EntitiesPersonsDetailsView = () => {
  const location = useLocation();
  const type = getTypeByLocation(location);
  const ContextProvider = type === TYPE.ENTITY ? EntitiesContextProvider : PersonsContextProvider;

  return (
    <ContextProvider>
      <Grid container spacing={3}>
        <EntitiesPersonsDetails />
      </Grid>
    </ContextProvider>
  );
};

export default EntitiesPersonsDetailsView;
