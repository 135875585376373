import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Card,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  SvgIcon,
} from '@material-ui/core';
import moment from 'moment';
import { Button, DangerButton } from 'components/shared/Button';
import { Dialog } from 'components/shared/Modal';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import Pagination from 'components/shared/Pagination';
import ActionTableCell from 'components/shared/ActionTableCell';
import UsersService from 'services/shared/UsersService';
import { useApi } from 'hooks/useApi';
import { skanerRoutes } from 'routes/skanerRoutes';
import { dateFormatWithTime, getParamsFromURL, getRoutePath } from 'utils/utils';
import { roles } from 'utils/roles';

const BASE_API_URL = '/api/users';
const LAST_LOGIN_ORDER = 'lastLoginOrder';
const STATUS_ORDER = 'rankedStatus';

const UsersList = ({ filters }) => {
  const params = getParamsFromURL();
  const [paging, setPaging] = useState({
    pageNumber: params.pageNumber ? parseInt(params.pageNumber) : 1,
    pageSize: params.pageSize ? parseInt(params.pageSize) : 10,
  });
  const [apiUrl, setApiUrl] = useState(
    BASE_API_URL +
      `?pageNumber=${paging.pageNumber}&pageSize=${paging.pageSize}${params.query ? `&query=${params.query}` : ''}${
        params[LAST_LOGIN_ORDER] ? `&lastLoginOrder=${params[LAST_LOGIN_ORDER]}` : ''
      }${params[STATUS_ORDER] ? `&statusOrder=${params[STATUS_ORDER]}` : ''}`
  );
  const [lastLoginSort, setLastLoginSort] = useState({
    field: params[LAST_LOGIN_ORDER] ? LAST_LOGIN_ORDER : '',
    order: params[LAST_LOGIN_ORDER],
  });
  const [statusSort, setStatusSort] = useState({
    field: params[STATUS_ORDER] ? STATUS_ORDER : '',
    order: params[STATUS_ORDER],
  });
  const [deleteModalOptions, setDeleteModalOptions] = useState({
    isOpen: false,
    id: null,
    email: null,
    isLoading: false,
  });
  const [reactivateModalOptions, setReactivateModalOptions] = useState({
    isOpen: false,
    id: null,
    email: null,
    isLoading: false,
  });
  const [successModalOptions, setSuccessModalOptions] = useState({
    isOpen: false,
    message: null,
  });

  const history = useHistory();

  const location = useLocation();

  const { result: users, isLoading, error, refresh } = useApi(apiUrl);

  useEffect(() => {
    if (filters) {
      const queryParams = new URLSearchParams();
      queryParams.set('pageNumber', 1);
      queryParams.set('pageSize', paging.pageSize);
      for (const filterKey in filters) {
        if (filters[filterKey]) queryParams.set(filterKey, filters[filterKey]);
      }
      if (lastLoginSort.field) queryParams.set(lastLoginSort.field, lastLoginSort.order);
      if (statusSort.field) queryParams.set(statusSort.field, statusSort.order);
      setPaging({ ...paging, pageNumber: 1 });
      history.replace({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
      setApiUrl(BASE_API_URL + `?${queryParams.toString()}`);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyFilters = ({ pageSize, pageNumber, lastLoginSort, statusSort }) => {
    const queryParams = new URLSearchParams(apiUrl.replace(BASE_API_URL, ''));
    queryParams.set('pageNumber', pageNumber);
    queryParams.set('pageSize', pageSize);
    if (lastLoginSort && lastLoginSort.field) queryParams.set(LAST_LOGIN_ORDER, lastLoginSort.order);
    else queryParams.delete(LAST_LOGIN_ORDER);

    if (statusSort && statusSort.field) queryParams.set(STATUS_ORDER, statusSort.order);
    else queryParams.delete(STATUS_ORDER);

    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    setApiUrl(BASE_API_URL + `?${queryParams.toString()}`);
  };

  const changeLastLoginSort = key => {
    const newSort = {
      field: key === LAST_LOGIN_ORDER && lastLoginSort.order === 'asc' ? '' : LAST_LOGIN_ORDER,
      order: lastLoginSort.field === key && lastLoginSort.order === 'desc' ? 'asc' : 'desc',
    };
    setLastLoginSort(newSort);
    applyFilters({ ...paging, lastLoginSort: newSort, statusSort: statusSort });
  };

  const changeStatusSort = key => {
    const newSort = {
      field: key === STATUS_ORDER && statusSort.order === 'asc' ? '' : STATUS_ORDER,
      order: statusSort.field === key && statusSort.order === 'desc' ? 'asc' : 'desc',
    };
    setStatusSort(newSort);
    applyFilters({
      ...paging,
      lastLoginSort: lastLoginSort,
      statusSort: newSort,
    });
  };

  const deleteUser = async () => {
    try {
      setDeleteModalOptions({ isOpen: false });
      await UsersService.deleteUser(deleteModalOptions.id);
      setSuccessModalOptions({
        isOpen: true,
        message: 'Użytkownik poprawnie usunięty',
      });
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  const reactivateUser = async () => {
    try {
      setReactivateModalOptions({ isOpen: false });
      await UsersService.reactivateUser({ id: reactivateModalOptions.id });
      setSuccessModalOptions({
        isOpen: true,
        message: 'Użytkownik poprawnie reaktywowany',
      });
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  const sendActivationLink = async userId => {
    try {
      await UsersService.reactivateUser({ id: userId });
      setSuccessModalOptions({
        isOpen: true,
        message: 'Link aktywacyjny poprawnie wysłany',
      });
    } catch (error) {
      return <ErrorHandler error={error} />;
    }
  };

  if (error) return <ErrorHandler error={error} />;
  if (isLoading || !users) return <Loader />;

  return (
    <>
      <TableContainer component={Card} elevation={3}>
        <CardHeader
          title="Użytkownicy"
          action={
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => history.push(skanerRoutes.users.add)}
              className="card-header-btn"
              startIcon={
                <SvgIcon>
                  <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                </SvgIcon>
              }
            >
              Dodaj użytkownika
            </Button>
          }
          titleTypographyProps={{ variant: 'h6' }}
        />
        <div style={{ overflowY: 'auto' }}>
          <Table aria-label="tabela użytkowników">
            <TableHead>
              <TableRow className="table-head-row">
                <TableCell>Id</TableCell>
                <TableCell style={{ minWidth: 200 }}>Nazwisko i imię</TableCell>
                <TableCell style={{ minWidth: 200 }}>Email</TableCell>
                <TableCell style={{ minWidth: 150 }}>Program/Instytucja</TableCell>
                <TableCell style={{ minWidth: 150 }}>Rola</TableCell>
                <TableCell style={{ minWidth: 100 }}>
                  <TableSortLabel
                    active={statusSort.field === STATUS_ORDER}
                    direction={statusSort.field === STATUS_ORDER ? statusSort.order : 'desc'}
                    onClick={() => changeStatusSort(STATUS_ORDER)}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  <TableSortLabel
                    active={lastLoginSort.field === LAST_LOGIN_ORDER}
                    direction={lastLoginSort.field === LAST_LOGIN_ORDER ? lastLoginSort.order : 'desc'}
                    onClick={() => changeLastLoginSort(LAST_LOGIN_ORDER)}
                  >
                    Ostatnie logowanie
                  </TableSortLabel>
                </TableCell>
                <ActionTableCell style={{ minWidth: 400 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data.map(user => (
                <TableRow key={user.id}>
                  <TableCell component="th" scope="row">
                    {user.id}
                  </TableCell>
                  <TableCell>
                    {user.lastName} {user.firstName}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.organisation}</TableCell>
                  <TableCell>
                    {user.role.split(',').includes(roles.ADMIN) ? (
                      <>
                        Administrator
                        <SvgIcon
                          style={{
                            width: '0.8em',
                            height: '0.8em',
                            marginLeft: '5px',
                          }}
                          aria-hidden="true"
                        >
                          <path
                            fill="currentColor"
                            d="M5.83,10C5.42,8.83 4.31,8 3,8A3,3 0 0,0 0,11A3,3 0 0,0 3,14C4.31,14 5.42,13.17 5.83,12H8V14H10V12H11V10H5.83M3,12A1,1 0 0,1 2,11A1,1 0 0,1 3,10A1,1 0 0,1 4,11A1,1 0 0,1 3,12M16,4A4,4 0 0,0 12,8A4,4 0 0,0 16,12A4,4 0 0,0 20,8A4,4 0 0,0 16,4M16,10.1A2.1,2.1 0 0,1 13.9,8A2.1,2.1 0 0,1 16,5.9C17.16,5.9 18.1,6.84 18.1,8C18.1,9.16 17.16,10.1 16,10.1M16,13C13.33,13 8,14.33 8,17V20H24V17C24,14.33 18.67,13 16,13M22.1,18.1H9.9V17C9.9,16.36 13,14.9 16,14.9C18.97,14.9 22.1,16.36 22.1,17V18.1Z"
                          />
                        </SvgIcon>
                      </>
                    ) : (
                      'Użytkownik'
                    )}
                  </TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>
                    {user.lastLoginDate !== null
                      ? moment.utc(user.lastLoginDate).local().format(dateFormatWithTime)
                      : 'Brak informacji'}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {user.status === 'Usunięty' && (
                      <>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            setReactivateModalOptions({
                              isOpen: true,
                              id: user.id,
                              email: user.email,
                            })
                          }
                        >
                          Reaktywuj
                        </Button>
                      </>
                    )}
                    {user.status === 'Aktywacja wygasła' && (
                      <>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            setReactivateModalOptions({
                              isOpen: true,
                              id: user.id,
                              email: user.email,
                            })
                          }
                        >
                          Reaktywuj
                        </Button>
                        <DangerButton
                          style={{ marginLeft: 16 }}
                          variant="outlined"
                          onClick={() =>
                            setDeleteModalOptions({
                              isOpen: true,
                              id: user.id,
                              email: user.email,
                            })
                          }
                        >
                          Usuń
                        </DangerButton>
                      </>
                    )}
                    {user.status === 'Czeka na aktywację' && (
                      <>
                        <Button
                          style={{ marginRight: 16 }}
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() => sendActivationLink(user.id)}
                        >
                          Wyślij ponownie link aktywacyjny
                        </Button>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() => history.push(getRoutePath(skanerRoutes.users.edit, user.id))}
                        >
                          Edytuj dane
                        </Button>
                        <DangerButton
                          style={{ marginLeft: 16 }}
                          variant="outlined"
                          onClick={() =>
                            setDeleteModalOptions({
                              isOpen: true,
                              id: user.id,
                              email: user.email,
                            })
                          }
                        >
                          Usuń
                        </DangerButton>
                      </>
                    )}
                    {user.status === 'Aktywny' && (
                      <>
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          onClick={() => history.push(getRoutePath(skanerRoutes.users.edit, user.id))}
                        >
                          Edytuj dane
                        </Button>
                        <Button
                          type="button"
                          style={{ marginLeft: 16 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => history.push(getRoutePath(skanerRoutes.users.editPassword, user.id))}
                        >
                          Zmień hasło
                        </Button>
                        <DangerButton
                          style={{ marginLeft: 16 }}
                          variant="outlined"
                          onClick={() =>
                            setDeleteModalOptions({
                              isOpen: true,
                              id: user.id,
                              email: user.email,
                            })
                          }
                        >
                          Usuń
                        </DangerButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Pagination
          count={users.count}
          page={paging.pageNumber}
          pageSize={paging.pageSize}
          handleChangePage={page => {
            const newPaging = {
              ...paging,
              pageNumber: page,
            };
            setPaging(newPaging);
            applyFilters({ ...newPaging, lastLoginSort, statusSort });
          }}
          handleChangeRowsPerPage={event => {
            const newPaging = {
              pageSize: parseInt(event.target.value, 10),
              pageNumber: 1,
            };
            setPaging(newPaging);
            applyFilters({ ...newPaging, lastLoginSort, statusSort });
          }}
        />
      </TableContainer>
      {deleteModalOptions.isOpen && (
        <Dialog
          isOpen={deleteModalOptions.isOpen}
          dialog
          title="Usuwanie użytkownika"
          text={`Czy na pewno chcesz usunąć użytkownika: ${deleteModalOptions.email}?`}
          handleConfirm={deleteUser}
          handleClose={() => setDeleteModalOptions({ isOpen: false })}
          isLoading={deleteModalOptions.isLoading}
        />
      )}
      {reactivateModalOptions.isOpen && (
        <Dialog
          isOpen={reactivateModalOptions.isOpen}
          dialog
          title="Reaktywowanie użytkownika"
          text={`Czy na pewno chcesz reaktywować użytkownika: ${reactivateModalOptions.email}?`}
          handleConfirm={reactivateUser}
          handleClose={() => setReactivateModalOptions({ isOpen: false })}
          isLoading={reactivateModalOptions.isLoading}
        />
      )}
      <Dialog
        isOpen={successModalOptions.isOpen}
        close
        title="Sukces"
        text={`${successModalOptions.message}.`}
        handleClose={() => {
          setSuccessModalOptions({ isOpen: false, message: null });
          refresh();
        }}
      />
    </>
  );
};

export default UsersList;
