import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Divider as MuiDivider, Typography, TableContainer as MuiTableContainer, SvgIcon } from '@material-ui/core';
import { Button } from 'components/shared/Button';
import { skanerTheme } from 'utils/skanerTheme';

export const StyledLabelValue = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const StyledLabel = styled.div`
  font-weight: bold;
`;

export const LabelValueRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  > div {
    flex-basis: calc(33.33% - 6.7px);
  }
`;

export const LabelValue = ({ label, value, style = {}, multi = false }) => {
  return (
    <StyledLabelValue style={style}>
      <StyledLabel>{label}</StyledLabel>
      {!multi && <div>{value}</div>}
      {multi && value.map((el, index) => <div key={index}>{el}</div>)}
    </StyledLabelValue>
  );
};

export const Divider = styled(MuiDivider)`
  margin-bottom: 16px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  background: ${skanerTheme.palette.secondaryRed};
  padding: 20px;
  color: ${skanerTheme.palette.white};
  margin: -16px;
`;

export const SubSectionTitle = styled(Typography)`
  font-weight: 500;
  color: ${skanerTheme.palette.gray};
  margin-bottom: 16px;
  text-decoration: underline;
`;

export const TableContainer = styled(MuiTableContainer)`
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const getScrollableActionsWrapperStyle = (location = 'left', style = {}) => ({
  position: 'absolute',
  top: 5,
  [location]: 5,
  zIndex: 10,
  ...style,
});

const StyledScrollableActionsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  button:not(:first-child) {
    margin-top: 5px;
  }
`;

export const ScrollableActionsWrapper = ({ boundaryElement, location, children }) => {
  const wrapperRef = useRef(null);

  const [style, setStyle] = useState(getScrollableActionsWrapperStyle(location));

  useEffect(() => {
    const srcollableElems = [window, document.querySelector('#wpio-entity-person-details')];

    const handleScroll = () => {
      if (!wrapperRef.current) return;

      if (srcollableElems[0].scrollY > 0 || srcollableElems[1].scrollTop > 0) {
        const boundaryElementRect = document.querySelector(boundaryElement).getBoundingClientRect();

        // Header top + header height + 5 px padding + 24 or 0 depends on inner scroll
        const top =
          boundaryElementRect.top + boundaryElementRect.height + 5 + (srcollableElems[1].scrollTop > 0 ? 24 : 0);

        // For left toggler: header left + 5 px of padding
        // For right toggler: header right - header width + 17 or 0 depens inner scroll visibility + 5 px of padding
        const horizontalOffset =
          ((location === 'left' && boundaryElementRect.left) ||
            boundaryElementRect.right - boundaryElementRect.width + (srcollableElems[1].scrollTop > 0 ? 17 : 0)) + 5;

        setStyle(
          getScrollableActionsWrapperStyle(location, {
            position: 'fixed',
            top: `${top}px`,
            [location]: `${horizontalOffset}px`,
          })
        );
      } else {
        setStyle(getScrollableActionsWrapperStyle(location));
      }
    };

    srcollableElems.map(el => el.addEventListener('scroll', handleScroll));
    handleScroll();

    return () => {
      srcollableElems.map(el => el.removeEventListener('scroll', handleScroll));
    };
  }, [wrapperRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledScrollableActionsWrapper ref={wrapperRef} style={style}>
      {children}
    </StyledScrollableActionsWrapper>
  );
};

export const GraphDetailsTogglerButton = ({ onClick, direction, ariaLabel }) => {
  return (
    <Button
      variant="contained"
      customcolor="white"
      onClick={onClick}
      style={{ padding: 6, minWidth: 'initial' }}
      aria-label={ariaLabel}
    >
      <SvgIcon>
        {(direction === 'left' && (
          <path
            fill="currentColor"
            d="M11.92,19.92L4,12L11.92,4.08L13.33,5.5L7.83,11H22V13H7.83L13.34,18.5L11.92,19.92M4,12V2H2V22H4V12Z"
          />
        )) || (
          <path
            fill="currentColor"
            d="M12.08,4.08L20,12L12.08,19.92L10.67,18.5L16.17,13H2V11H16.17L10.67,5.5L12.08,4.08M20,12V22H22V2H20V12Z"
          />
        )}
      </SvgIcon>
    </Button>
  );
};

export const getNameForPersons = ({ imiona, nazwisko, nazwa }) => {
  if (!imiona && !nazwisko && nazwa) return nazwa;
  if (!imiona && nazwisko) return nazwisko;
  if (!nazwisko && imiona) return imiona;
  if (!imiona && !nazwisko && !nazwa) return '';
  return `${imiona} ${nazwisko}`;
};
