export const dateFormatWithTime = 'YYYY-MM-DD HH:mm:ss';

export const isDataTruthy = data => {
  if (Array.isArray(data)) {
    return data.length > 0 && data.some(value => isDataTruthy(value));
  } else if (data && typeof data === 'object' && !Array.isArray(data)) {
    return Object.values(data).some(value => isDataTruthy(value));
  } else {
    return !!data;
  }
};

export const formatNumber = (value, decimalPlaces = 2) => {
  if (typeof value !== 'number') return;
  return value
    .toFixed(decimalPlaces)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',')
    .replace(/ /g, '\u202F');
};

export const saveFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};

export const getRandomStr = () => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const getComputedStyleByProperty = (element, property) => {
  return window.getComputedStyle(element, null).getPropertyValue(property);
};

export const getParamsFromURL = () => {
  return window.location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent);
      obj[key] = value;
      return obj;
    }, {});
};

export const getRoutePath = (path, paramValue, paramPlaceholder = ':id') => path.replace(paramPlaceholder, paramValue);
