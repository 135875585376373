import React, { useContext } from 'react';
import Loader from 'components/shared/Loader';
import EntitiesPersonsAccordion from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsAccordion';
import EntitiesBasicInfo from 'components/wpio/EntitiesPersonsDetails/EntitiesBasicInfo';
import EntitiesUnitsSection from 'components/wpio/EntitiesPersonsDetails/EntitiesUnitsSection';
import EntitiesObjectSection from 'components/wpio/EntitiesPersonsDetails/EntitiesObjectSection';
import EntitiesTransformationSection from 'components/wpio/EntitiesPersonsDetails/EntitiesTransformationSection';
import EntitiesRelationsSection from 'components/wpio/EntitiesPersonsDetails/EntitiesRelationsSection';
import EntitiesFinancesSection from 'components/wpio/EntitiesPersonsDetails/EntitiesFinancesSection';
import EntitiesPersonsEuFundingSection from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsEuFundingSection';
import EntitiesPersonsPublicProcurementSection from 'components/wpio/EntitiesPersonsDetails/EntitiesPersonsPublicProcurementSection';
import EntitiesStatusVatSection from 'components/wpio/EntitiesPersonsDetails/EntitiesStatusVatSection';
import EntitiesContext from 'contexts/wpio/EntitiesContext';
import { isDataTruthy } from 'utils/utils';

const EntitesRestInformationSection = ({ basicEntityData }) => {
  const {
    sections: { isLoading, relations, finances, units, euFunding, publicProcurement, transformation, object },
  } = useContext(EntitiesContext);

  const { dane_krs, dane_ceidg, dane_regon, dane_vat } = basicEntityData;

  const accordionItems = [
    {
      panelId: 1,
      title: 'Dane podstawowe',
      show: isDataTruthy(dane_krs) || isDataTruthy(dane_ceidg) || isDataTruthy(dane_regon),
      content: <EntitiesBasicInfo data={{ dane_krs, dane_ceidg, dane_regon }} />,
    },
    {
      panelId: 2,
      title: 'Powiązania',
      show: isDataTruthy(relations),
      content: <EntitiesRelationsSection relations={relations} error={relations?.error} />,
    },
    {
      panelId: 3,
      title: 'Dane finansowe',
      show: isDataTruthy(finances),
      content: <EntitiesFinancesSection finances={finances} error={finances?.error} />,
    },
    {
      panelId: 4,
      title: 'Oddziały / Adresy dodatkowe',
      show: isDataTruthy(units),
      content: <EntitiesUnitsSection units={units} error={units?.error} />,
    },
    {
      panelId: 5,
      title: 'Przekształcenia',
      show: isDataTruthy(transformation),
      content: <EntitiesTransformationSection transformation={transformation} error={transformation?.error} />,
    },
    {
      panelId: 6,
      title: 'Przedmiot działalności',
      show: isDataTruthy(object),
      content: <EntitiesObjectSection object={object} error={object?.error} />,
    },
    {
      panelId: 7,
      title: 'Status VAT',
      show: isDataTruthy(dane_vat),
      content: <EntitiesStatusVatSection statusVat={dane_vat} />,
    },
    {
      panelId: 8,
      title: 'Dofinansowania z UE',
      show: isDataTruthy(euFunding),
      content: <EntitiesPersonsEuFundingSection euFunding={euFunding} error={euFunding?.error} />,
    },
    {
      panelId: 9,
      title: 'Zamówienia publiczne',
      show: isDataTruthy(publicProcurement),
      content: (
        <EntitiesPersonsPublicProcurementSection
          publicProcurement={publicProcurement}
          error={publicProcurement?.error}
        />
      ),
    },
  ];

  return (
    <>
      <EntitiesPersonsAccordion defaultExpanded={1} items={accordionItems}></EntitiesPersonsAccordion>
      {isLoading && <Loader />}
    </>
  );
};

export default EntitesRestInformationSection;
