import React, { useEffect, useState } from 'react';
import EntitiesService from 'services/wpio/EntitiesService';
import { Tabs, Tab, Card, CardHeader, TableContainer, Chip, Collapse, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import axios from 'axios';
import CircularLoader from 'components/shared/CircularLoader';
import Loader from 'components/shared/Loader';
import PersonsService from 'services/wpio/PersonsService';
import PersonsTable from 'components/wpio/EntitiesPersonsList/PersonsTable';
import EntitiesTable from 'components/wpio/EntitiesPersonsList/EntitiesTable';
import { TYPE, errorMessage } from 'components/wpio/EntitiesPersonsConsts';
import { skanerTheme } from 'utils/skanerTheme';
import { isDataTruthy, formatNumber } from 'utils/utils';

const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${skanerTheme.palette.tab};
  min-height: 36px;
  .MuiTab-root {
    font-weight: 400;
    letter-spacing: initial;
    min-height: 36px;
    color: ${skanerTheme.palette.black};
    background: ${skanerTheme.palette.tab};
  }
  .MuiTabs-fixed {
    overflow-x: auto !important;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: ${skanerTheme.palette.white};
    background: ${skanerTheme.palette.primary};
    border-radius: 10px 10px 0 0;
  }
  .MuiTab-textColorPrimary:not(.Mui-selected) {
    border: 1px solid ${skanerTheme.palette.tab};
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }
  .MuiTabs-indicator {
    background-color: transparent;
  }
`;
const StyledTabLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledClWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`;
const StyledChip = styled(Chip)`
  color: ${skanerTheme.palette.black};
  background: ${skanerTheme.palette.white};
`;
const StyledTabTitle = styled.div`
  font-weight: 500;
`;
const StyledEntityErrorMsg = styled.div`
  display: flex;
  justify-content: center;
  background: ${skanerTheme.palette.secondaryRed};
  padding: 20px;
  color: ${skanerTheme.palette.white};
`;

const DEFAULT_PAGING = { pageSize: 10, pageNumber: 1 };

const DEFAULT_SOURCE_STATE = {
  type: TYPE.ENTITY,
  data: null,
  isLoading: false,
  error: null,
  pageSize: DEFAULT_PAGING.pageSize,
  pageNumber: DEFAULT_PAGING.pageNumber,
};

const DEFAULT_PERSONS_VAT_SOURCE_STATE = {
  ...DEFAULT_SOURCE_STATE,
  error: { customMessage: 'Brak możliwości wyszukania osób.' },
};

export const SOURCES = {
  KSI: 'KSI',
  SL14: 'SL14',
  CST21: 'CST21',
  BK14: 'BK14',
  BK21: 'BK21',
  KRS: 'KRS',
  CEIDG: 'CEIDG',
  CRBR: 'CRBR',
  VAT: 'VAT',
  REGON: 'REGON',
};

const TAB_LABELS = {
  ...SOURCES,
  KSI: 'KSI 07-13',
  SL14: 'SL2014',
  CST21: 'CST2021',
};

const DEFAULT_REQUEST_CANCELLATION_TOKENS = Object.fromEntries(Object.values(SOURCES).map(key => [key, null]));

const DEFAULT_DATA_SOURCES_STATE = Object.fromEntries(Object.values(SOURCES).map(key => [key, DEFAULT_SOURCE_STATE]));

const INFO_MESSAGES = {
  [TYPE.ENTITY]: {
    [SOURCES.KSI]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz KRS, województwo, powiat i gmina.',
      'Wyszukanie po wpisach aktualnych i historycznych jest ignorowane.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.SL14]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz KRS, województwo, powiat i gmina.',
      'Wyszukanie po wpisach aktualnych i historycznych jest ignorowane.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.CST21]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz KRS, REGON, województwo, powiat i gmina.',
      'Wyszukanie po wpisach aktualnych i historycznych jest ignorowane.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.BK14]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz KRS, REGON i gmina.',
      'Wyszukanie po wpisach aktualnych i historycznych jest ignorowane.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.BK21]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz KRS i REGON.',
      'Wyszukanie po wpisach aktualnych i historycznych jest ignorowane.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.KRS]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz numeru zagranicznego.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.CEIDG]: [
      'Możliwość wyszukania po wszystkich parametrach oprócz KRS i numeru zagranicznego.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.CRBR]: [
      'Możliwość wyszukania tylko po jednym z następujących parametrów: NIP i KRS. W przypadku podania obydwu zostanie użyty NIP i zignorowany KRS.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.VAT]: [
      'Możliwość wyszukania tylko po jednym z następujących parametrów: NIP i REGON. W przypadku podania obydwu zostanie użyty NIP i zignorowany REGON.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
    [SOURCES.REGON]: [
      'Możliwość wyszukiwania tylko po jednym z następujących parametrów: NIP, KRS i REGON. W przypadku podania więcej niż jednego zostanie użyty tylko jeden z nich.',
      'Parametry, po których nie można wyszukiwać są ignorowane. Wymagane jest podanie przynajmniej jednego parametru, po którym można wyszukiwać.',
    ],
  },
  [TYPE.PERSON]: {
    [SOURCES.KSI]: ['Brak możliwości wyszukania osób.'],
    [SOURCES.SL14]: ['Możliwość wyszukania po imieniu, nazwisku i PESEL, pozostałe parametry są ignorowane.'],
    [SOURCES.CST21]: ['Możliwość wyszukania po imieniu, nazwisku i PESEL, pozostałe parametry są ignorowane.'],
    [SOURCES.BK14]: ['Możliwość wyszukania po imieniu, nazwisku i PESEL, pozostałe parametry są ignorowane.'],
    [SOURCES.BK21]: ['Możliwość wyszukania po wszystkich parametrach.'],
    [SOURCES.KRS]: ['Możliwość wyszukania po wszystkich parametrach.'],
    [SOURCES.CEIDG]: ['Możliwość wyszukania po wszystkich parametrach.'],
    [SOURCES.CRBR]: ['Możliwość wyszukania tylko po PESEL, pozostałe parametry są ignorowane.'],
    [SOURCES.VAT]: ['Brak możliwości wyszukania osób.'],
    [SOURCES.REGON]: ['Możliwość wyszukania tylko po PESEL, pozostałe parametry są ignorowane.'],
  },
};

const EntitiesPersonsList = ({ filters, type }) => {
  const [initialized, setInitialized] = useState(false);
  const [activeTab, setActiveTab] = useState(SOURCES.KRS);
  const [dataSources, setDataSources] = useState(DEFAULT_DATA_SOURCES_STATE);
  const [requestCancellationTokens, setRequestCancellationTokens] = useState(DEFAULT_REQUEST_CANCELLATION_TOKENS);

  const getEntitiesOrPersons = async (source, { pageSize, pageNumber }) => {
    const request = axios.CancelToken.source();
    setRequestCancellationTokens(prevState => ({ ...prevState, [source]: request }));
    setDataSources(prevState => {
      return {
        ...prevState,
        [source]: {
          ...prevState[source],
          data: null,
          isLoading: true,
          error: null,
        },
      };
    });
    try {
      const service = type === TYPE.ENTITY ? EntitiesService.getEntities : PersonsService.getPersons;
      const result = await service(filters, source, pageSize, pageNumber, request);
      setDataSources(prevState => {
        return {
          ...prevState,
          [source]: {
            ...prevState[source],
            data: result,
            isLoading: false,
            pageSize,
            pageNumber,
          },
        };
      });
    } catch (error) {
      if (axios.isCancel(error)) return;
      setDataSources(prevState => {
        return {
          ...prevState,
          [source]: {
            ...prevState[source],
            data: null,
            isLoading: false,
            error,
            pageSize: DEFAULT_PAGING.pageSize,
            pageNumber: DEFAULT_PAGING.pageNumber,
          },
        };
      });
    }
  };

  const countLabel = data => {
    const count = type === TYPE.ENTITY ? data.liczba_podmiotow : data.liczba_osob;
    return `${(!Boolean(data.czy_liczba_dokladna) && 'ok. ') || ''}${formatNumber(count, 0)}`;
  };

  useEffect(() => {
    Object.values(requestCancellationTokens).forEach(req => req && req.cancel()); // cancel request tokens if exists;
    if (Object.keys(filters).length > 0) {
      setInitialized(true);
      Object.keys(dataSources).forEach(source => {
        if (source === SOURCES.VAT && type === TYPE.PERSON) return;
        getEntitiesOrPersons(source, DEFAULT_PAGING);
      });
    } else {
      setInitialized(false);
      Object.keys(dataSources).forEach(source => {
        setDataSources(prevState => {
          return {
            ...prevState,
            [source]:
              source === SOURCES.VAT && type === TYPE.PERSON ? DEFAULT_PERSONS_VAT_SOURCE_STATE : DEFAULT_SOURCE_STATE,
          };
        });
      });
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!initialized) return null;

  const List = ({
    source,
    state: { data, isLoading, error, pageSize, pageNumber },
    applyPaging,
    limitedRowsPerPage = false,
  }) => {
    const [open, setOpen] = useState(true);

    const getErrorMsg = error => {
      if (!!error.customMessage) return error.customMessage;
      return !!error?.response?.data?.detail ? error.response.data.detail : errorMessage;
    };

    return (
      <div style={{ marginTop: -2 }}>
        <TableContainer component={Card} elevation={3}>
          {INFO_MESSAGES[type][activeTab].length > 0 && (
            <Collapse in={open}>
              <Alert
                severity="info"
                action={
                  <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpen(false)}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                style={{ alignItems: 'center' }}
              >
                {INFO_MESSAGES[type][activeTab].map((info, index) => (
                  <React.Fragment key={index}>
                    {info}
                    <br />
                  </React.Fragment>
                ))}
              </Alert>
            </Collapse>
          )}
          <CardHeader title={type === TYPE.ENTITY ? 'Podmioty' : 'Osoby'} titleTypographyProps={{ variant: 'h6' }} />
          <div style={{ overflowY: 'auto' }}>
            {isLoading && <Loader />}
            {error && <StyledEntityErrorMsg>{getErrorMsg(error)}</StyledEntityErrorMsg>}
            {data && (
              <>
                {type === TYPE.ENTITY ? (
                  <EntitiesTable
                    source={source}
                    data={data}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    applyPaging={applyPaging}
                    limitedRowsPerPage={limitedRowsPerPage}
                  />
                ) : (
                  <PersonsTable
                    data={data}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    applyPaging={applyPaging}
                    limitedRowsPerPage={limitedRowsPerPage}
                  />
                )}
              </>
            )}
          </div>
        </TableContainer>
      </div>
    );
  };

  return (
    <>
      <StyledTabs value={activeTab} indicatorColor="primary" textColor="primary">
        {Object.keys(dataSources).map(source => (
          <Tab
            key={source}
            label={
              <StyledTabLabel>
                <StyledTabTitle>{TAB_LABELS[source]}</StyledTabTitle>
                {dataSources[source].isLoading && (
                  <StyledClWrapper>
                    <CircularLoader
                      svgcolor={(activeTab === source && skanerTheme.palette.white) || skanerTheme.palette.primary}
                    />
                  </StyledClWrapper>
                )}
                {!dataSources[source].isLoading && isDataTruthy(dataSources[source].data) && (
                  <StyledClWrapper>
                    <StyledChip label={countLabel(dataSources[source].data)} color="primary" size="small" />
                  </StyledClWrapper>
                )}
              </StyledTabLabel>
            }
            value={source}
            onClick={() => setActiveTab(source)}
          />
        ))}
      </StyledTabs>
      {Object.keys(dataSources).map(
        source =>
          activeTab === source && (
            <List
              key={source}
              source={source}
              state={dataSources[source]}
              applyPaging={paging => getEntitiesOrPersons(source, paging)}
              limitedRowsPerPage={source === SOURCES.CEIDG}
            />
          )
      )}
    </>
  );
};

export default EntitiesPersonsList;
