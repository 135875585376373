import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorMessage, TableContainer } from 'components/wpio/EntitiesPersonsShared';
import { Table, TableBody, TableCell, TableHead, TableRow, Link } from '@material-ui/core';
import { wpioRoutes } from 'routes/wpioRoutes';
import { isDataTruthy, getRoutePath } from 'utils/utils';

const PersonsRelationsSection = ({ relations, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <>
      {isDataTruthy(relations) && (
        <div>
          <TableContainer component="div">
            <Table size="small" aria-label="tabela powiązań">
              <TableHead>
                <TableRow>
                  <TableCell>Podmiot</TableCell>
                  <TableCell>Rola</TableCell>
                  <TableCell>Okres</TableCell>
                  <TableCell>Typ rejestru KRS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {relations.map(
                  ({ nazwa, rola, typ, dane_data_od, dane_data_do, identyfikator_grupy_podmiotow, typ_krs }, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Link
                          component={RouterLink}
                          to={getRoutePath(
                            typ === 'Podmiot'
                              ? wpioRoutes.entitiesPersons.entityDetails
                              : wpioRoutes.entitiesPersons.personDetails,
                            identyfikator_grupy_podmiotow
                          )}
                          target="_blank"
                        >
                          {nazwa}
                        </Link>
                      </TableCell>
                      <TableCell>{rola}</TableCell>
                      <TableCell>
                        <PersonRelationsPeriod from={dane_data_od} to={dane_data_do} />
                      </TableCell>
                      <TableCell>{typ_krs}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

const PersonRelationsPeriod = ({ from, to }) => {
  if (!from) return <>obecnie</>;
  return <>{`${from} – ${(to !== '9999-12-31' && to) || 'obecnie'}`}</>;
};

export default PersonsRelationsSection;
