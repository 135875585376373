import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { skanerTheme } from 'utils/skanerTheme';

const StyledBaseButton = styled(MuiButton)`
  ${({ disablefocusoutline }) =>
    disablefocusoutline !== '1' &&
    css`
      :focus {
        outline: 3px solid ${skanerTheme.palette.black};
        outline-offset: 3px;
      }
    `}

  ${({ customcolor }) =>
    customcolor === 'white' &&
    css`
      background-color: ${skanerTheme.palette.white};
      color: ${skanerTheme.palette.primary};
      :hover {
        background-color: #eceff1;
      }
      &.Mui-disabled {
        background-color: rgba(255, 255, 255, 0.8);
        color: ${skanerTheme.palette.primary};
      }
    `}
`;

const StyledButton = styled(StyledBaseButton)``;
const StyledDangerButton = styled(StyledBaseButton)`
  ${({ variant }) =>
    variant === 'outlined' &&
    css`
      border-color: ${skanerTheme.palette.red};
      color: ${skanerTheme.palette.red};
    `}
  ${({ variant }) =>
    variant === 'contained' &&
    css`
      background-color: ${skanerTheme.palette.red};
    `}
`;

export const Button = ({ disableFocusOutline, ...props }) => {
  return <StyledButton disablefocusoutline={disableFocusOutline ? '1' : '0'} {...props} disableFocusRipple />;
};

export const DangerButton = ({ disableFocusOutline, ...props }) => {
  return <StyledDangerButton disablefocusoutline={disableFocusOutline ? '1' : '0'} {...props} disableFocusRipple />;
};
