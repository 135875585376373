import React, { createContext, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { errorMessage } from 'components/wpio/EntitiesPersonsConsts';
import PersonsService from 'services/wpio/PersonsService';
import { useApi } from 'hooks/useApi';

const dataInitialState = {
  id: null,
  details: {},
  sections: {
    relations: null,
    euFunding: null,
    grantee: null,
    publicProcurement: null,
    authorizedPersons: null,
    projectParticipants: null,
    projectPersonnel: null,
  },
  graph: {
    graphId: '',
    graphData: null,
  },
};

const PersonsContext = createContext();

const PersonsContextProvider = ({ children }) => {
  const [data, setData] = useState(dataInitialState);
  const [allSectionsIsLoading, setAllSectionsIsLoading] = useState(false);
  const [graphImage, setGraphImage] = useState(null);

  const requestCancellationToken = useRef(null);

  const {
    params: { id },
  } = useRouteMatch();
  const { result = {}, isLoading, error, loaded } = useApi(`/api/wpio/v1/osoby/${id}/szczegoly`);

  const fetchAllSections = () => {
    setAllSectionsIsLoading(true);
    clearAllSections();

    const request = axios.CancelToken.source();
    requestCancellationToken.current = request;

    if (loaded) {
      [
        'euFunding',
        'grantee',
        'publicProcurement',
        'authorizedPersons',
        'projectParticipants',
        'projectPersonnel',
      ].forEach(req => {
        PersonsService[`get${req.charAt(0).toUpperCase() + req.slice(1)}`](result.identyfikator_osoby, request.token)
          .then(res =>
            setData(prev => ({
              ...prev,
              sections: {
                ...prev.sections,
                [req]: res || {},
              },
            }))
          )
          .catch(error => handleError(error, req));
      });

      setData(prev => ({
        ...prev,
        id: result?.identyfikator_osoby || null,
        details: result?.dane_identyfikacyjne || {},
        sections: {
          ...prev.sections,
          relations: result?.powiazania || {},
        },
        graph: {
          graphId: result?.id_podmiot_graf || '',
          graphData: result?.graf || [],
        },
      }));
    } else if (error) {
      setData(prev => ({
        ...prev,
        sections: {
          relations: { error: true },
        },
      }));
    }
  };

  const handleError = (error, key) => {
    if (axios.isCancel(error)) return;
    setData(prev => ({
      ...prev,
      sections: {
        ...prev.sections,
        [key]: { error: !!error?.response?.data?.detail ? error.response.data.detail : errorMessage },
      },
    }));
  };

  const cancelNotFinishedRequests = () => {
    if (requestCancellationToken.current) requestCancellationToken.current.cancel();
  };

  const clearAllSections = () => {
    cancelNotFinishedRequests();
    setData(dataInitialState);
  };

  useEffect(() => {
    return () => cancelNotFinishedRequests();
  }, []);

  useEffect(() => {
    if (loaded && id) fetchAllSections();
  }, [loaded, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.values(data.sections).every(section => !!section)) setAllSectionsIsLoading(false);
  }, [data.sections]);

  return (
    <PersonsContext.Provider
      value={{
        id: data.id,
        details: { result: data.details, isLoading, error },
        sections: {
          isLoading: allSectionsIsLoading,
          relations: data.sections.relations,
          euFunding: { ...data.sections.euFunding, ...data.sections.grantee },
          publicProcurement: data.sections.publicProcurement,
          authorizedPersons: data.sections.authorizedPersons,
          projectParticipants: data.sections.projectParticipants,
          projectPersonnel: data.sections.projectPersonnel,
        },
        graph: {
          graphId: data.graph.graphId,
          graphData: data.graph.graphData,
          graphImage,
          setGraphImage,
        },
      }}
    >
      {children}
    </PersonsContext.Provider>
  );
};

export default PersonsContext;

export { PersonsContextProvider };
