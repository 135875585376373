import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Card, CardHeader, TextField, Checkbox, FormLabel, FormControlLabel } from '@material-ui/core';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button, DangerButton } from 'components/shared/Button';
import CircularLoader from 'components/shared/CircularLoader';
import Loader from 'components/shared/Loader';
import ErrorHandler from 'components/shared/ErrorHandler';
import { skanerRoutes } from 'routes/skanerRoutes';
import { useApi } from 'hooks/useApi';
import { getRoutePath } from 'utils/utils';
import { roles, roleModulesMap } from 'utils/roles';

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridItem: {
    width: '100%',
  },
}));

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: 'black',
      },
    },
    MuiInputBase: {
      input: { color: 'black' },
    },
  },
});

const UserPanelView = () => {
  const { id } = useParams();
  const { result: user, isLoading: userIsLoading, error } = useApi(`/api/users/${id}`);

  const history = useHistory();

  const classes = useStyles();

  if (error) return <ErrorHandler error={error} />;
  if (!user) return <Loader />;

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardHeader title={`Panel użytkownika #${id}`} titleTypographyProps={{ variant: 'h6' }} />
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Imię"
                  name="firstName"
                  id="firstName"
                  disabled
                  value={(user && user.firstName) || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Nazwisko"
                  name="lastName"
                  id="lastName"
                  disabled
                  value={(user && user.lastName) || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Email"
                  name="email"
                  id="email"
                  disabled
                  value={(user && user.email) || ''}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <TextField
                  label="Program/Instytucja"
                  name="organisation"
                  id="organisation"
                  disabled
                  value={(user && user.organisation) || 'Brak'}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <FormControlLabel
                  style={{ marginTop: 8 }}
                  control={
                    <Checkbox
                      disabled
                      style={{ cursor: 'default' }}
                      checked={user.role.split(',').includes(roles.ADMIN)}
                      name={roles.ADMIN}
                      id={roles.ADMIN}
                      color="primary"
                    />
                  }
                  label="Czy administrator?"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.gridItem}>
                <div>
                  <FormLabel>Uprawnienia do modułów</FormLabel>
                </div>
                {roleModulesMap.map(rmm => (
                  <div key={rmm.name}>
                    <FormControlLabel
                      style={{ marginTop: 8 }}
                      control={
                        <Checkbox
                          style={{ cursor: 'default' }}
                          checked={user.role.split(',').includes(rmm.role)}
                          name={rmm.name}
                          disabled
                          id={rmm.name}
                          color="primary"
                        />
                      }
                      label={rmm.name}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
              <Grid item md={6} className={classes.buttonsWrapper}>
                <Button
                  onClick={() => history.push(getRoutePath(skanerRoutes.user.editPassword, id))}
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 16 }}
                >
                  {userIsLoading ? <CircularLoader /> : 'Zmień hasło'}
                </Button>
                <Button
                  onClick={() => history.push(getRoutePath(skanerRoutes.user.editData, id))}
                  variant="contained"
                  color="primary"
                >
                  {userIsLoading ? <CircularLoader /> : 'Edytuj swoje dane'}
                </Button>
                <DangerButton
                  style={{ marginLeft: 16 }}
                  variant="outlined"
                  onClick={() => {
                    history.push(skanerRoutes.home);
                  }}
                >
                  Powrót
                </DangerButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default UserPanelView;
