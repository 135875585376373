import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SamplersList from 'components/sampler/SamplersList';
import FileUpload from 'components/sampler/FileUpload';

const SamplerListView = () => {
  const [renderKey, setRenderKey] = useState(0);

  const refreshSamplersList = () => setRenderKey(renderKey + 1);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FileUpload refreshSamplersList={refreshSamplersList} />
      </Grid>
      <Grid item xs={12}>
        <SamplersList key={renderKey} />
      </Grid>
    </Grid>
  );
};

export default SamplerListView;
