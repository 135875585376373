import React, { useState, useEffect, useContext } from 'react';
import { useParams, Prompt } from 'react-router-dom';
import { FormGroup, FormControl, Checkbox, Divider } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { Dialog } from 'components/shared/Modal';
import { Button } from 'components/shared/Button';
import CircularLoader from 'components/shared/CircularLoader';
import { TYPE } from 'components/wpio/EntitiesPersonsConsts';
import PersonsContext from 'contexts/wpio/PersonsContext';
import EntitiesContext from 'contexts/wpio/EntitiesContext';
import PdfService from 'services/wpio/PdfService';
import { isDataTruthy, saveFile } from 'utils/utils';
import { skanerTheme } from 'utils/skanerTheme';

const CloseCheckBoxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M19,3H5C3.9,3 3,3.9 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.9 20.1,3 19,3M17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41L8.41,7L12,10.59L15.59,7L17,8.41L13.41,12L17,15.59Z" />
  </svg>
);

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formControlSubtitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 30,
  },
  label: {
    fontSize: 16,
  },
  labelPointer: {
    fontSize: 16,
    cursor: 'pointer',
  },
  icon: {
    cursor: 'pointer',
  },
  divider: {
    margin: '8px 0',
  },
  spacer: {
    flexGrow: 1,
    height: 42,
    cursor: 'pointer',
  },
});

const SECTION_LIST = [
  {
    key: 'sourcesResult',
    apiKey: 'dane_identyfikacyjne',
    title: 'Informacje identyfikacyjne',
  },
  {
    key: 'historicalNames',
    apiKey: 'nazwy_historyczne',
    title: 'Nazwy historyczne',
  },
  {
    key: 'divergenceOfSources',
    apiKey: 'rozbieznosc_danych',
    title: 'Rozbiezność danych',
  },
  {
    key: 'graph',
    apiKey: 'graf',
    title: 'Graf zależności',
  },
  {
    key: 'details',
    apiKey: 'dane_podstawowe',
    title: 'Dane podstawowe',
  },
  {
    key: 'relations',
    apiKey: 'powiazania',
    title: 'Powiązania',
    isExpanded: false,
    subsections: [
      {
        key: 'organ_uprawniony_do_reprezentowania_spolki',
        subtitle: 'Organ reprezentacji',
      },
      {
        key: 'organ_nadzoru',
        subtitle: 'Organ nadzoru',
      },
      {
        key: 'prokurenci',
        subtitle: 'Prokurenci',
      },
      {
        key: 'wspolnicy',
        subtitle: 'Wspólnicy',
      },
      {
        key: 'akcjonariusze',
        subtitle: 'Akcjonariusze',
      },
      {
        key: 'beneficjenci_rzeczywisci',
        subtitle: 'Beneficjenci rzeczywiści',
      },
      {
        key: 'wlasciciel',
        subtitle: 'Właściciel',
      },
      {
        key: 'reprezentanci_zglaszajacy',
        subtitle: 'Reprezentanci/Zgłaszający',
      },
      {
        key: 'pelnomocnicy',
        subtitle: 'Pełnomocnicy',
      },
      {
        key: 'prokurenci_ceidg',
        subtitle: 'Prokurenci',
      },
      {
        key: 'zarzadca_sukcesyjny',
        subtitle: 'Zarządca sukcesyjny',
      },
      {
        key: 'komitet_zalozycielski',
        subtitle: 'Komitet założycielski',
      },
      {
        key: 'podmiot_ktory_utworzyl',
        subtitle: 'Podmiot tworzący',
      },
    ],
  },
  {
    key: 'finances',
    apiKey: 'dane_finansowe',
    title: 'Dane finansowe',
  },
  {
    key: 'units',
    apiKey: 'oddzialy',
    title: 'Oddziały / Adresy dodatkowe',
  },
  {
    key: 'transformation',
    apiKey: 'przeksztalcenia',
    title: 'Przekształcenia',
    isExpanded: false,
  },
  {
    key: 'object',
    apiKey: 'przedmiot_dzialalnosci',
    title: 'Przedmiot działalności',
  },
  {
    key: 'statusVat',
    apiKey: 'status_vat',
    title: 'Status VAT',
  },
  {
    key: 'euFunding',
    apiKey: 'dofinansowania_ue',
    title: 'Dofinansowania z UE',
    isExpanded: false,
    subsections: [
      {
        key: 'projekty_ue_ksi',
        subtitle: 'Projekty współfinansowane ze środków UE – perspektywa finansowa 2007-2013',
      },
      {
        key: 'projekty_ue',
        subtitle: 'Projekty współfinansowane ze środków UE – perspektywa finansowa 2014-2020',
      },
      {
        key: 'odbiorcy',
        subtitle: 'Ostateczny odbiorca – perspektywa finansowa 2014-2020',
      },
      {
        key: 'projekty_ue_cst',
        subtitle: 'Projekty współfinansowane ze środków UE – perspektywa finansowa 2021-2027',
      },
      {
        key: 'odbiorcy_cst',
        subtitle: 'Ostateczny odbiorca – perspektywa finansowa 2021-2027',
      },
      {
        key: 'podmioty_wdrazajace_cst',
        subtitle: 'Podmiot wdrażający IF – perspektywa finansowa 2021-2027',
      },
      {
        key: 'dane_grantobiorcy',
        subtitle: 'Grantobiorca – perspektywa finansowa 2021-2027',
      },
    ],
  },
  {
    key: 'publicProcurement',
    apiKey: 'zamowienia_publiczne',
    title: 'Zamówienia publiczne',
    isExpanded: false,
    subsections: [
      {
        key: 'lista_BK14',
        subtitle: 'Baza Konkurencyjności 2014',
      },
      {
        key: 'lista_BK21',
        subtitle: 'Baza Konkurencyjności 2021',
      },
      {
        key: 'lista_SL',
        subtitle: 'SL2014',
      },
      {
        key: 'lista_CST',
        subtitle: 'CST2021',
      },
    ],
  },
  {
    key: 'authorizedPersons',
    apiKey: 'osoby_uprawnione',
    title: 'Osoby uprawnione',
    isExpanded: false,
    subsections: [
      {
        key: 'osoby_uprawnione_sl',
        subtitle: 'SL2014',
      },
      {
        key: 'osoby_uprawnione_cst',
        subtitle: 'CST2021',
      },
    ],
  },
  {
    key: 'projectParticipants',
    apiKey: 'uczestnicy_projektow',
    title: 'Uczestnicy projektów',
    isExpanded: false,
    subsections: [
      {
        key: 'uczestnik_projektu_sl',
        subtitle: 'SL2014',
      },
    ],
  },
  {
    key: 'projectPersonnel',
    apiKey: 'personel_projektu',
    title: 'Personel projektu',
    isExpanded: false,
    subsections: [
      {
        key: 'personel_projektu_sl',
        subtitle: 'SL2014',
      },
    ],
  },
];

const DEFAULT_SELECT_DIALOG_OPTIONS = {
  isOpen: false,
  isLoading: false,
};

const EntitiesPersonsExportPdf = ({ id, type }) => {
  const [sections, setSections] = useState(SECTION_LIST);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [selectDialogOptions, setSelectDialogOptions] = useState(DEFAULT_SELECT_DIALOG_OPTIONS);
  const [warningDialogOptions, setWarningDialogOptions] = useState({ isOpen: false });

  const { id: urlId } = useParams();

  const {
    details: { result },
    sections: dataSections,
    divergenceOfSources,
    graph: { graphImage },
  } = useContext(type === TYPE.ENTITY ? EntitiesContext : PersonsContext);

  const classes = useStyles();

  const formik = useFormik({
    initialValues: sections.reduce((total, section) => {
      total[section.key] = true;
      if (section.subsections) section.subsections.forEach(subtitle => (total[subtitle.key] = true));
      return total;
    }, {}),
  });

  const getCheckboxIcon = () => {
    const totalValues = Object.values(formik.values);
    const totalSelected = totalValues.filter(Boolean).length;
    if (totalSelected === totalValues.length) return <CloseCheckBoxIcon />;
    if (totalSelected > 0) return <IndeterminateCheckBoxIcon />;
    return <CheckBoxOutlineBlankIcon />;
  };

  const handleSelectAllChange = () => {
    const isAllSelected = Object.values(formik.values).every(Boolean);
    const newValues = Object.keys(formik.values).reduce((acc, key) => {
      acc[key] = key === 'sourcesResult' ? true : !isAllSelected;
      return acc;
    }, {});
    formik.setValues(newValues);
  };

  const toggleExpandSection = key => {
    setSections(prev =>
      prev.map(section => (section.key === key ? { ...section, isExpanded: !section.isExpanded } : section))
    );
  };

  const toggleExpandSections = () => {
    const isAllExpanded = sections.every(({ isExpanded }) => isExpanded);
    setSections(sections.map(section => ({ ...section, isExpanded: !isAllExpanded })));
  };

  const handleFormChange = ({ sectionKey, subsectionKey, value }) => {
    formik.setFieldValue(subsectionKey || sectionKey, value);
    const section = sections.find(section => section.key === sectionKey);
    if (!section) return;
    if (sectionKey && !subsectionKey && section.subsections) {
      section.subsections.forEach(subsection => formik.setFieldValue(subsection.key, value));
    } else if (sectionKey && subsectionKey) {
      const subsectionKeys = section.subsections.map(subsection => subsection.key);
      const isAnySubsectionChecked = subsectionKeys.some(key => (key === subsectionKey ? value : formik.values[key]));
      if (isAnySubsectionChecked) formik.setFieldValue(sectionKey, true);
      else formik.setFieldValue(sectionKey, false);
    }
  };

  const getSelectedSections = () => {
    const selectedSections = {};
    sections.forEach(section => {
      if (section.subsections) {
        section.subsections.forEach(subsection => {
          const key = `${section.apiKey}_${subsection.key}`;
          selectedSections[key] = formik.values[subsection.key];
        });
        selectedSections[section.apiKey] = Object.entries(selectedSections).some(
          ([subKey, value]) => subKey.startsWith(`${section.apiKey}_`) && value === true
        );
      } else {
        selectedSections[section.apiKey] = formik.values[section.key];
      }
    });
    return selectedSections;
  };

  const handleSelectDialogConfirm = () => {
    const isPublicProcurementSelected = formik.values['publicProcurement'];
    if (isPublicProcurementSelected) {
      const publicProcurementCount =
        dataSections.publicProcurement?.lista_BK14.length +
        dataSections.publicProcurement?.lista_BK21.length +
        dataSections.publicProcurement?.lista_SL.length +
        dataSections.publicProcurement?.lista_CST.length;
      if (publicProcurementCount < 200) handleGeneretePDF();
      else setWarningDialogOptions({ isOpen: true });
    } else {
      handleGeneretePDF();
    }
  };

  const handleSelectDialogClose = () => {
    setSelectDialogOptions(DEFAULT_SELECT_DIALOG_OPTIONS);
    formik.resetForm();
  };

  const getFilteredSections = () => {
    const data = {
      sourcesResult: {
        nr_krs: result.nr_krs,
        nr_nip: result.nr_nip,
        nr_regon: result.nr_regon,
        adres: result.adres,
        data_urodzenia: result.data_urodzenia,
        nr_pesel: result.nr_pesel,
      },
      details: { dane_krs: result.dane_krs, dane_ceidg: result.dane_ceidg, dane_regon: result.dane_regon },
      historicalNames: result.nazwy_historyczne,
      divergenceOfSources,
      graph: graphImage,
      statusVat: result.dane_vat,
      ...dataSections,
    };
    delete data.isLoading;

    const sectionsAndSubsectionsKeys = Object.entries(data).reduce((total, [key, value]) => {
      if (isDataTruthy(value) && !value.error) {
        const section = SECTION_LIST.find(section => section.key === key);
        const subsections = section?.subsections
          ? Object.entries(value).reduce((subTotal, [subKey, subValue]) => {
              if (isDataTruthy(subValue)) return [...subTotal, subKey];
              return subTotal;
            }, [])
          : null;
        return [...total, { section: key, subsections: subsections?.length ? subsections : undefined }];
      }
      return total;
    }, []);

    if (sectionsAndSubsectionsKeys.length > 0) {
      const filteredSectionsList = SECTION_LIST.map(section => {
        const filteredSection = sectionsAndSubsectionsKeys.find(key => key.section === section.key);
        if (!filteredSection) return null;
        const filteredSubsections = section.subsections?.filter(subsection =>
          filteredSection.subsections?.includes(subsection.key)
        );
        return {
          ...section,
          subsections: filteredSubsections?.length ? filteredSubsections : undefined,
        };
      }).filter(Boolean);

      setSections(filteredSectionsList);
    }
  };

  const beforeUnloadHandler = e => (e.returnValue = 'Data may not be saved');

  const checkPdfStatus = async pdfId => {
    const intervalId = setInterval(async () => {
      try {
        const { status } = await PdfService.getPdfStatus(pdfId);
        if (status === 'SUCCESS') {
          clearInterval(intervalId);
          try {
            const arrayBuffer = await PdfService.getPdfFile(pdfId);
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            saveFile(blob, urlId);
          } catch (error) {
            console.error('Wystąpił błąd podczas pobierania PDF: ', error);
          } finally {
            setIsPdfGenerating(false);
            window.removeEventListener('beforeunload', beforeUnloadHandler, true);
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        setIsPdfGenerating(false);
        console.error('Wystąpił błąd podczas sprawdzania statusu PDF: ', error);
        window.removeEventListener('beforeunload', beforeUnloadHandler, true);
      }
    }, 5000);
  };

  const handleGeneretePDF = async () => {
    window.addEventListener('beforeunload', beforeUnloadHandler, true);
    setWarningDialogOptions({ isOpen: false });
    setSelectDialogOptions(prev => ({ ...prev, isLoading: true }));
    const selectedSections = getSelectedSections();
    try {
      const pdfId = await PdfService[`${type === TYPE.ENTITY ? 'getEntityPdf' : 'getPersonPdf'}`](urlId, {
        graf_image_base64uri: formik.values['graph'] ? graphImage : '',
        sections: selectedSections,
        laczony_identyfikator: id,
      });
      setIsPdfGenerating(true);
      checkPdfStatus(pdfId);
    } catch (error) {
      console.error('Wystąpił błąd podczas generowania pliku PDF: ', error);
      window.removeEventListener('beforeunload', beforeUnloadHandler, true);
    } finally {
      setSelectDialogOptions(DEFAULT_SELECT_DIALOG_OPTIONS);
      formik.resetForm();
    }
  };

  useEffect(() => {
    if (!dataSections.isLoading) getFilteredSections();
  }, [dataSections.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button
        type="button"
        variant="contained"
        customcolor="white"
        disabled={dataSections.isLoading || isPdfGenerating}
        className="card-header-btn"
        style={{ minWidth: 125 }}
        aria-label="pobierz pdf"
        onClick={() => setSelectDialogOptions(prev => ({ ...prev, isOpen: true }))}
      >
        {dataSections.isLoading || isPdfGenerating ? (
          <>
            {isPdfGenerating && <span style={{ marginRight: 10 }}>Trwa generowanie PDF...</span>}
            <CircularLoader svgcolor={skanerTheme.palette.primary} />
          </>
        ) : (
          'Utwórz PDF'
        )}
      </Button>
      <Dialog
        isOpen={selectDialogOptions.isOpen}
        title="Wybór sekcji danych do pobrania w PDF"
        customConfirmText="POBIERZ PDF"
        customCloseText="ANULUJ"
        isLoading={selectDialogOptions.isLoading}
        disableBackdropClick
        dialog
        handleConfirm={handleSelectDialogConfirm}
        handleClose={handleSelectDialogClose}
      >
        <form>
          <FormGroup>
            <FormControl className={classes.formControl}>
              <Checkbox
                id="selectAll"
                name="selectAll"
                icon={getCheckboxIcon()}
                checkedIcon={getCheckboxIcon()}
                onChange={handleSelectAllChange}
                color="primary"
              />
              <label htmlFor="selectAll" className={classes.label}>
                Zaznacz wszystko
              </label>
              <div style={{ flexGrow: 1 }} />
              <Button type="button" variant="outlined" color="primary" onClick={toggleExpandSections}>
                Rozwiń/zwiń wszystko
              </Button>
            </FormControl>
            <Divider className={classes.divider} />
            {sections.map(({ key: sectionKey, title, isExpanded, subsections }) => (
              <React.Fragment key={sectionKey}>
                <FormControl className={classes.formControl}>
                  <Checkbox
                    id={sectionKey}
                    name={sectionKey}
                    checked={formik.values[sectionKey]}
                    disabled={sectionKey === 'sourcesResult'}
                    onChange={e => handleFormChange({ sectionKey, value: e.target.checked })}
                    color="primary"
                  />
                  <label
                    className={subsections ? classes.labelPointer : classes.label}
                    onClick={() => toggleExpandSection(sectionKey)}
                  >
                    {title}
                  </label>
                  {subsections && (
                    <>
                      <div className={classes.spacer} onClick={() => toggleExpandSection(sectionKey)} />
                      {isExpanded ? (
                        <ArrowDropUpIcon className={classes.icon} onClick={() => toggleExpandSection(sectionKey)} />
                      ) : (
                        <ArrowDropDownIcon className={classes.icon} onClick={() => toggleExpandSection(sectionKey)} />
                      )}
                    </>
                  )}
                </FormControl>
                {isExpanded &&
                  subsections &&
                  subsections.map(({ key: subsectionKey, subtitle }) => (
                    <FormControl key={subsectionKey} className={classes.formControlSubtitle}>
                      <Checkbox
                        id={subsectionKey}
                        name={subsectionKey}
                        checked={formik.values[subsectionKey]}
                        disabled={!formik.values[sectionKey]}
                        onChange={e => handleFormChange({ sectionKey, subsectionKey, value: e.target.checked })}
                        color="primary"
                      />
                      <label className={classes.label}>{subtitle}</label>
                    </FormControl>
                  ))}
              </React.Fragment>
            ))}
          </FormGroup>
        </form>
      </Dialog>
      <Dialog
        isOpen={warningDialogOptions.isOpen}
        title="Ostrzeżenie"
        text="Ze względu na dużą liczbę danych, czas generowania PDF może potrwać nieco dłużej."
        customConfirmText="TAK, GENERUJ PDF"
        customCloseText="ANULUJ"
        dialog
        handleConfirm={handleGeneretePDF}
        handleClose={() => setWarningDialogOptions({ isOpen: false })}
      />
      <Prompt
        when={isPdfGenerating}
        message={JSON.stringify({
          text: 'Jeśli opuścisz tę stronę, generowanie PDF-a zostanie przerwane. Jesteś pewien?',
          confirmText: 'Tak, opuść stronę',
          cancelText: 'Zostań na stronie',
        })}
      />
    </>
  );
};

export default EntitiesPersonsExportPdf;
