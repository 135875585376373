import React from 'react';
import { Typography, Avatar, SvgIcon } from '@material-ui/core';
import styled from 'styled-components';
import { TYPE } from 'components/wpio/EntitiesPersonsConsts';
import { skanerTheme } from 'utils/skanerTheme';

const StyledIconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
  background-color: ${skanerTheme.palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 30px;
    color: ${({ type }) => (type === TYPE.ENTITY && skanerTheme.palette.primary) || skanerTheme.palette.graphPerson};
  }
`;
const StyledTitle = styled(Typography)`
  color: ${skanerTheme.palette.white};
`;
const StyledNameWrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const EntitiesPersonsTitle = ({ type, title, children }) => {
  return (
    <StyledIconTitleWrapper>
      <StyledAvatar variant="rounded" type={type}>
        <SvgIcon>
          {(type === TYPE.ENTITY && (
            <path
              fill="currentColor"
              d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z"
            />
          )) || (
            <path
              fill="currentColor"
              d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
            />
          )}
        </SvgIcon>
      </StyledAvatar>
      <StyledNameWrapper>
        <StyledTitle component="h2" variant="h6">
          {title}
        </StyledTitle>
        {children}
      </StyledNameWrapper>
    </StyledIconTitleWrapper>
  );
};

export default EntitiesPersonsTitle;
