export const skanerRoutes = {
  home: '/',
  login: '/zaloguj',
  resetPassword: '/resetuj-haslo',
  activateAccount: '/aktywuj-konto/:activationCode',
  setNewPassword: '/ustaw-nowe-haslo/:resetCode',
  statute: {
    index: '/regulamin',
    versions: '/regulamin/wersje',
    add: '/regulamin/wersje/dodaj',
    preview: '/regulamin/wersje/podglad/:id',
    accept: '/regulamin/akceptacja',
  },
  accessibilityDeclaration: '/deklaracja-dostepnosci',
  user: {
    userPanel: '/panel-uzytkownika/:id',
    editData: '/panel-uzytkownika/edytuj-dane/:id',
    editPassword: '/panel-uzytkownika/edytuj-haslo/:id',
  },
  users: {
    index: '/uzytkownicy',
    add: '/uzytkownicy/dodaj',
    edit: '/uzytkownicy/edytuj/:id',
    editPassword: '/uzytkownicy/edytuj-haslo/:id',
  },
  errors: {
    500: '/blad-500',
    403: '/blad-403',
    404: '/blad-404',
  },
  modules: {
    wpio: '/wpio',
    sampler: '/sampler',
  },
};
