import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  DialogContent,
  DialogActions,
  Link,
} from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'components/shared/Button';
import { Modal } from 'components/shared/Modal';
import { DangerButton } from 'components/shared/Button';
import {
  TableContainer,
  SubSectionTitle,
  ErrorMessage,
  LabelValueRow,
  LabelValue,
  Divider,
} from 'components/wpio/EntitiesPersonsShared';
import { wpioRoutes } from 'routes/wpioRoutes';
import { isDataTruthy, formatNumber, getRoutePath } from 'utils/utils';

const StyledSection = styled.div`
  &:not(:first-child) {
    padding-top: 16px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 16px;
  }
`;

const EntitiesPersonsEuFundingSection = ({ euFunding, error }) => {
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  const {
    projekty_ue_ksi,
    projekty_ue,
    odbiorcy,
    projekty_ue_cst,
    odbiorcy_cst,
    podmioty_wdrazajace_cst,
    dane_grantobiorcy,
  } = euFunding;

  const moreThan3 = data => data.length > 3;

  return (
    <>
      {isDataTruthy(projekty_ue_ksi) && (
        <StyledSection>
          <SubSectionTitle>Projekty współfinansowane ze środków UE – perspektywa finansowa 2007-2013</SubSectionTitle>
          {(moreThan3(projekty_ue_ksi) ? projekty_ue_ksi.slice(0, 3) : projekty_ue_ksi).map((item, index) => (
            <KsiProjectItem key={index} item={item} index={index} length={projekty_ue_ksi.length} />
          ))}
          {moreThan3(projekty_ue_ksi) && (
            <ShowAll
              dialogTitle="Projekty współfinansowane ze środków UE – perspektywa finansowa 2007-2013"
              length={projekty_ue_ksi.length}
            >
              <div style={{ padding: '8px 24px' }}>
                {projekty_ue_ksi.map((item, index) => (
                  <KsiProjectItem key={index} item={item} index={index} length={projekty_ue_ksi.length} />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {isDataTruthy(projekty_ue) && (
        <StyledSection>
          <SubSectionTitle>Projekty współfinansowane ze środków UE – perspektywa finansowa 2014-2020</SubSectionTitle>
          {(moreThan3(projekty_ue) ? projekty_ue.slice(0, 3) : projekty_ue).map((item, index) => (
            <SlProjectItem key={index} item={item} index={index} length={projekty_ue.length} />
          ))}
          {moreThan3(projekty_ue) && (
            <ShowAll
              dialogTitle="Projekty współfinansowane ze środków UE – perspektywa finansowa 2014-2020"
              length={projekty_ue.length}
            >
              <div style={{ padding: '8px 24px' }}>
                {projekty_ue.map((item, index) => (
                  <SlProjectItem key={index} item={item} index={index} length={projekty_ue.length} />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {isDataTruthy(odbiorcy) && (
        <StyledSection>
          <SubSectionTitle>Ostateczny odbiorca – perspektywa finansowa 2014-2020</SubSectionTitle>
          {(moreThan3(odbiorcy) ? odbiorcy.slice(0, 3) : odbiorcy).map((item, index) => (
            <FinalRecipientItem key={index} item={item} index={index} length={odbiorcy.length} />
          ))}
          {moreThan3(odbiorcy) && (
            <ShowAll dialogTitle="Ostateczny odbiorca – perspektywa finansowa 2014-2020" length={odbiorcy.length}>
              <div style={{ padding: '8px 24px' }}>
                {odbiorcy.map((item, index) => (
                  <FinalRecipientItem key={index} item={item} index={index} length={odbiorcy.length} />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {isDataTruthy(projekty_ue_cst) && (
        <StyledSection>
          <SubSectionTitle>Projekty współfinansowane ze środków UE – perspektywa finansowa 2021-2027</SubSectionTitle>
          {(moreThan3(projekty_ue_cst) ? projekty_ue_cst.slice(0, 3) : projekty_ue_cst).map((item, index) => (
            <CstProjectItem key={index} item={item} index={index} length={projekty_ue_cst.length} />
          ))}
          {moreThan3(projekty_ue_cst) && (
            <ShowAll
              dialogTitle="Projekty współfinansowane ze środków UE – perspektywa finansowa 2021-2027"
              length={projekty_ue_cst.length}
            >
              <div style={{ padding: '8px 24px' }}>
                {projekty_ue_cst.map((item, index) => (
                  <CstProjectItem key={index} item={item} index={index} length={projekty_ue_cst.length} />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {isDataTruthy(odbiorcy_cst) && (
        <StyledSection>
          <SubSectionTitle>Ostateczny odbiorca – perspektywa finansowa 2021-2027</SubSectionTitle>
          {(moreThan3(odbiorcy_cst) ? odbiorcy_cst.slice(0, 3) : odbiorcy_cst).map((item, index) => (
            <FinalRecipientItem key={index} item={item} index={index} length={odbiorcy_cst.length} />
          ))}
          {moreThan3(odbiorcy_cst) && (
            <ShowAll dialogTitle="Ostateczny odbiorca – perspektywa finansowa 2021-2027" length={odbiorcy_cst.length}>
              <div style={{ padding: '8px 24px' }}>
                {odbiorcy_cst.map((item, index) => (
                  <FinalRecipientItem key={index} item={item} index={index} length={odbiorcy_cst.length} />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {isDataTruthy(podmioty_wdrazajace_cst) && (
        <StyledSection>
          <SubSectionTitle>Podmiot wdrażający IF – perspektywa finansowa 2021-2027</SubSectionTitle>
          {(moreThan3(podmioty_wdrazajace_cst) ? podmioty_wdrazajace_cst.slice(0, 3) : podmioty_wdrazajace_cst).map(
            (item, index) => (
              <ImplementingEntityItem key={index} item={item} index={index} length={podmioty_wdrazajace_cst.length} />
            )
          )}
          {moreThan3(podmioty_wdrazajace_cst) && (
            <ShowAll
              dialogTitle="Podmiot wdrażający IF – perspektywa finansowa 2021-2027"
              length={podmioty_wdrazajace_cst.length}
            >
              <div style={{ padding: '8px 24px' }}>
                {podmioty_wdrazajace_cst.map((item, index) => (
                  <ImplementingEntityItem
                    key={index}
                    item={item}
                    index={index}
                    length={podmioty_wdrazajace_cst.length}
                  />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
      {isDataTruthy(dane_grantobiorcy) && (
        <StyledSection>
          <SubSectionTitle>Grantobiorca – perspektywa finansowa 2021-2027</SubSectionTitle>
          {(moreThan3(dane_grantobiorcy) ? dane_grantobiorcy.slice(0, 3) : dane_grantobiorcy).map((item, index) => (
            <GranteeItem key={index} item={item} index={index} length={dane_grantobiorcy.length} />
          ))}
          {moreThan3(dane_grantobiorcy) && (
            <ShowAll dialogTitle="Grantobiorca – perspektywa finansowa 2021-2027" length={dane_grantobiorcy.length}>
              <div style={{ padding: '8px 24px' }}>
                {dane_grantobiorcy.map((item, index) => (
                  <GranteeItem key={index} item={item} index={index} length={dane_grantobiorcy.length} />
                ))}
              </div>
            </ShowAll>
          )}
        </StyledSection>
      )}
    </>
  );
};

const KsiProjectItem = ({ item, index, length }) => {
  const { nr_projektu, tytul_projektu, status_umowy, status_projektu } = item;

  return (
    <>
      <LabelValueRow>
        {nr_projektu && <LabelValue label="Numer projektu" value={nr_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
      </LabelValueRow>
      <LabelValueRow>
        {status_umowy && <LabelValue label="Status umowy" value={status_umowy} />}
        {status_projektu && <LabelValue label="Status projektu" value={status_projektu} />}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const SlProjectItem = ({ item, index, length }) => {
  const {
    nr_projektu,
    tytul_projektu,
    rola,
    status_wniosku,
    status_umowy,
    status_projektu,
    wartosc_ogolem,
    partnerzy,
    beneficjent,
    opis_projektu,
  } = item;

  return (
    <>
      <LabelValueRow>
        {nr_projektu && <LabelValue label="Numer projektu" value={nr_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {rola && <LabelValue label="Rola" value={rola} />}
      </LabelValueRow>
      <LabelValueRow>
        {status_wniosku && <LabelValue label="Status wniosku" value={status_wniosku} />}
        {status_umowy && <LabelValue label="Status umowy" value={status_umowy} />}
        {status_projektu && <LabelValue label="Status projektu" value={status_projektu} />}
      </LabelValueRow>
      {wartosc_ogolem != null && (
        <LabelValue label="Wartość projektu ogółem" value={formatNumber(parseFloat(wartosc_ogolem))} />
      )}
      <LabelValueRow>
        {beneficjent?.nazwa && (
          <LabelValue
            label="Nazwa Beneficjenta"
            value={
              <Link
                component={RouterLink}
                to={getRoutePath(wpioRoutes.entitiesPersons.entityDetails, beneficjent.identyfikator_grupy_podmiotow)}
                target="_blank"
              >
                {beneficjent?.nazwa}
              </Link>
            }
          />
        )}
        {beneficjent?.identyfikator && (
          <LabelValue label="Identyfikator Beneficjenta" value={beneficjent.identyfikator} />
        )}
      </LabelValueRow>
      {isDataTruthy(partnerzy) && (
        <TableContainer component="div">
          <Table size="small" aria-label={`tabela partnerów dla projektu nr ${nr_projektu}`}>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa Partnera</TableCell>
                <TableCell>Identyfikator Partnera</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partnerzy.map(({ identyfikator_grupy_podmiotow, nazwa, nr_nip }, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Link
                      component={RouterLink}
                      to={getRoutePath(wpioRoutes.entitiesPersons.entityDetails, identyfikator_grupy_podmiotow)}
                      target="_blank"
                    >
                      {nazwa}
                    </Link>
                  </TableCell>
                  <TableCell>{nr_nip}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {opis_projektu && <LabelValue label="Krótki opis projektu" value={opis_projektu} />}
      {index !== length - 1 && <Divider />}
    </>
  );
};

const CstProjectItem = ({ item, index, length }) => {
  const {
    numer: nr_projektu,
    tytul: tytul_projektu,
    rola,
    status: status_projektu,
    wartosc_ogolem,
    nazwa_beneficjenta,
    identyfikator_grupy_podmiotow,
    identyfikator_beneficjenta,
    realizatorzy,
    opis: opis_projektu,
  } = item;

  return (
    <>
      <LabelValueRow>
        {nr_projektu && <LabelValue label="Numer projektu" value={nr_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {rola && <LabelValue label="Rola" value={rola} />}
        {status_projektu && <LabelValue label="Status projektu" value={status_projektu} />}
        {wartosc_ogolem != null && (
          <LabelValue label="Wartość projektu ogółem" value={formatNumber(parseFloat(wartosc_ogolem))} />
        )}
      </LabelValueRow>
      <LabelValueRow>
        {nazwa_beneficjenta && (
          <LabelValue
            label="Nazwa Beneficjenta"
            value={
              <Link
                component={RouterLink}
                to={getRoutePath(wpioRoutes.entitiesPersons.entityDetails, identyfikator_grupy_podmiotow)}
                target="_blank"
              >
                {nazwa_beneficjenta}
              </Link>
            }
          />
        )}
        {identyfikator_beneficjenta && (
          <LabelValue label="Identyfikator Beneficjenta" value={identyfikator_beneficjenta} />
        )}
      </LabelValueRow>
      {isDataTruthy(realizatorzy) && (
        <TableContainer component="div">
          <Table size="small" aria-label={`tabela realizatorów dla projektu nr ${nr_projektu}`}>
            <TableHead>
              <TableRow>
                <TableCell>Nazwa Realizatora</TableCell>
                <TableCell>Identyfikator Realizatora</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {realizatorzy.map(({ identyfikator_grupy_podmiotow, nazwa, nr_nip }, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Link
                      component={RouterLink}
                      to={getRoutePath(wpioRoutes.entitiesPersons.entityDetails, identyfikator_grupy_podmiotow)}
                      target="_blank"
                    >
                      {nazwa}
                    </Link>
                  </TableCell>
                  <TableCell>{nr_nip}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {opis_projektu && <LabelValue label="Krótki opis projektu" value={opis_projektu} />}
      {index !== length - 1 && <Divider />}
    </>
  );
};

const FinalRecipientItem = ({ item, index, length }) => {
  const {
    nr_projektu,
    tytul_projektu,
    nazwa_podmiotu_wdrazajacego,
    nip_podmiotu_wdrazajacego,
    nr_umowy,
    rodzaj_wsparcia,
    wartosc_umowy,
    data_podpisania_umowy,
    data_rozwiazania_umowy,
  } = item;

  return (
    <>
      <LabelValueRow>
        {nr_projektu && <LabelValue label="Numer projektu" value={nr_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {nazwa_podmiotu_wdrazajacego && (
          <LabelValue label="Nazwa podmiotu wdrażającego" value={nazwa_podmiotu_wdrazajacego} />
        )}
        {nip_podmiotu_wdrazajacego && (
          <LabelValue label="Identyfikator podmiotu wdrażającego" value={nip_podmiotu_wdrazajacego} />
        )}
        {nr_umowy && <LabelValue label="Numer umowy z odbiorcą" value={nr_umowy} />}
        {rodzaj_wsparcia && <LabelValue label="Rodzaj wsparcia" value={rodzaj_wsparcia} />}
        {wartosc_umowy != null && <LabelValue label="Wartość umowy" value={formatNumber(parseFloat(wartosc_umowy))} />}
        {data_podpisania_umowy && (
          <LabelValue label="Data podpisania umowy" value={data_podpisania_umowy.split('T')[0]} />
        )}
        {data_rozwiazania_umowy && (
          <LabelValue label="Data rozwiązania umowy" value={data_rozwiazania_umowy.split('T')[0]} />
        )}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const ImplementingEntityItem = ({ item, index, length }) => {
  const {
    nr_projektu,
    tytul_projektu,
    nazwa_beneficjenta,
    identyfikator_beneficjenta,
    nr_umowy,
    wartosc_umowy,
    data_podpisania_umowy,
    data_rozwiazania_umowy,
  } = item;

  return (
    <>
      <LabelValueRow>
        {nr_projektu && <LabelValue label="Numer projektu" value={nr_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {nazwa_beneficjenta && <LabelValue label="Nazwa Beneficjenta" value={nazwa_beneficjenta} />}
        {identyfikator_beneficjenta && (
          <LabelValue label="Identyfikator Beneficjenta" value={identyfikator_beneficjenta} />
        )}
        {nr_umowy && <LabelValue label="Numer umowy" value={nr_umowy} />}
        {wartosc_umowy != null && <LabelValue label="Wartość umowy" value={formatNumber(parseFloat(wartosc_umowy))} />}
        {data_podpisania_umowy && (
          <LabelValue label="Data podpisania umowy" value={data_podpisania_umowy.split('T')[0]} />
        )}
        {data_rozwiazania_umowy && (
          <LabelValue label="Data rozwiązania umowy" value={data_rozwiazania_umowy.split('T')[0]} />
        )}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const GranteeItem = ({ item, index, length }) => {
  const {
    numer_projektu,
    tytul_projektu,
    nazwa_beneficjenta,
    identyfikator_beneficjenta,
    nazwa_odbiorcy,
    nip_odbiorcy,
    numer_umowy,
    data_podpisania_umowy,
    nazwa_przedsiewziecia,
    wartosc_przedsiewziecia,
    wartosc_grantu,
    czy_caly_kraj,
    miejsce_realizacji_wojewodztwo,
  } = item;

  return (
    <>
      <LabelValueRow>
        {numer_projektu && <LabelValue label="Numer projektu" value={numer_projektu} />}
        {tytul_projektu && <LabelValue label="Tytuł projektu" value={tytul_projektu} />}
        {nazwa_beneficjenta && <LabelValue label="Nazwa Beneficjenta" value={nazwa_beneficjenta} />}
        {identyfikator_beneficjenta && (
          <LabelValue label="Identyfikator Beneficjenta" value={identyfikator_beneficjenta} />
        )}
        {numer_umowy && <LabelValue label="Numer umowy" value={numer_umowy} />}
        {nazwa_odbiorcy && <LabelValue label="Nazwa odbiorcy" value={nazwa_odbiorcy} />}
        {nip_odbiorcy && <LabelValue label="Identyfikator odbiorcy" value={nip_odbiorcy} />}
        {data_podpisania_umowy && (
          <LabelValue label="Data podpisania umowy" value={data_podpisania_umowy.split(' ')[0]} />
        )}
        {nazwa_przedsiewziecia && <LabelValue label="Nazwa przedsięwzięcia" value={nazwa_przedsiewziecia} />}
        {wartosc_przedsiewziecia != null && (
          <LabelValue label="Wartość przedsięwzięcia" value={formatNumber(parseFloat(wartosc_przedsiewziecia))} />
        )}
        {wartosc_grantu != null && (
          <LabelValue label="Wartość grantu" value={formatNumber(parseFloat(wartosc_grantu))} />
        )}
        {czy_caly_kraj != null && <LabelValue label="Cały kraj – Polska" value={czy_caly_kraj ? 'TAK' : 'NIE'} />}
        {miejsce_realizacji_wojewodztwo && (
          <LabelValue label="Miejsce realizacji" value={miejsce_realizacji_wojewodztwo} />
        )}
      </LabelValueRow>
      {index !== length - 1 && <Divider />}
    </>
  );
};

const ShowAll = ({ dialogTitle, children, length }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalIsOpen(true)} variant="contained" color="primary">
        Pokaż wszystkie ({length})
      </Button>
      <Modal isOpen={modalIsOpen} title={dialogTitle} handleClose={() => setModalIsOpen(false)} maxWidth="lg">
        <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
        <DialogActions>
          <DangerButton style={{ marginLeft: 16 }} variant="outlined" onClick={() => setModalIsOpen(false)}>
            Zamknij
          </DangerButton>
        </DialogActions>
      </Modal>
    </>
  );
};

export default EntitiesPersonsEuFundingSection;
